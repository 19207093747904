import React from 'react'

const UGAT = () => {
    const sectionInfo = [
        {
          
          sectionName: "English Language",
          questions: 40,
          remarks: "For IMBA, BBA, BCA, BHM, etc."
        },
        {
          
          sectionName: "Numerical & Data Analysis",
          questions: 30,
          remarks: ""
        },
        {
          
          sectionName: "Reasoning and General Intelligence",
          questions: 30,
          remarks: ""
        },
        {
          
          sectionName: "General Knowledge",
          questions: 30,
          remarks: ""
        },
        {
          
          sectionName: "Service Aptitude",
          questions: 25,
          remarks: "For BHM"
        },
        {
          
          sectionName: "Scientific Aptitude",
          questions: 25,
          remarks: ""
        }
      ];
      
  return (
    <div>
         <div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <p>Under Graduate Aptitude Test (UGAT) is a standardized test administered by AIMA annually</p>
                    <p>Candidates can apply for various undergraduate programs such as Integrated MBA (IMBA), BBA, BCA, BHM, B.Com, etc.</p>
                    <p>The candidates are given an option to indicate any five of the participating institutes/colleges/universities while filling the form.</p>
                    <p>Institutes/colleges/ universities can decide on the cut-off score for admission or for further screening based on the number of seats available.</p>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Eligibility</a></h2>
                </div>
                <div className="dlab-post-text">
                  <p>The minimum qualification is 10+2 or equivalent from a recognized board. Students appearing / appeared in 10+2 can also apply.</p>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Test Pattern</a></h2>
                </div>
                <div className="dlab-post-text">
                  <p>The mode of exam is both paper & pencil based and online.</p>
                </div>
            </div>

            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Structure of the Exam</a></h2>
                    <p>The exam is conducted in a paper & pencil format. The total duration of the entrance test is two hours. Each correct answer earns one mark, while each incorrect answer results in a penalty of 0.25 marks.</p>
                </div>
                
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <p>Following is the section wise split of the paper:</p>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Section	No.</th>
                                <th>Section Name</th>
                                <th>Number of Questions</th>
                                <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sectionInfo.map(function (obj,index) {
                                        return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{obj.sectionName}</td>
                                                <td>{obj.questions}</td>
                                                <td>{obj.remarks}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
  )
}

export default UGAT