import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const navigate=useNavigate()
  function LogOut(){
    localStorage.clear()
    window.history.replaceState(null,null,"/Login")
    navigate("/",{replace:true})
  }
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/Admin" style={{ textDecoration: "none" }}>
          <span className="logo">Genius IAS</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/Admin" style={{ textDecoration: "none" }}>
          <li>
            <DashboardIcon className="icon" />
            <span>Dashboard</span>
          </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/Admin/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Contact Info</span>
            </li>
          </Link>
          <Link to="/Admin/counselling" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Counselling</span>
            </li>
          </Link>
          <Link to="/Admin/Blogs" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Blogs</span>
            </li>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/Admin/CurrentAffair">
          <li>
            {/* <CreditCardIcon className="icon" /> */}
            <InsertChartIcon className="icon" />
            <span>CurrentAffairs</span>
          </li>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/Admin/Notifications">
          <li>
            {/* <LocalShippingIcon className="icon" /> */}
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/Admin/Reviews">
          <li>
            <CreditCardIcon className="icon" />
            {/* <InsertChartIcon className="icon" /> */}
            <span>Student's Reviews</span>
          </li>
          </Link>
          {/* <p className="title">USEFUL</p> */}
          {/* <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li> */}
          {/* <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li> */}
          {/* <p className="title">SERVICE</p>
          <li>
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <span>Settings</span>
          </li> */}
          <p className="title">USER</p>
          <Link to="/Admin/Telegram" style={{textDecoration:"none"}}>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          </Link>
          <li onClick={LogOut}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;