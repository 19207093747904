import React from 'react'
import QuestionAnswer from './QuestionAnswer'

const IIMIndore = () => {
  return (
    <div>
     <div className="class-info">
        <div className="dlab-post-title ">
            <h2 className="post-title m-t0"><a>What is IPM?</a></h2>
        </div>
        <div className="dlab-post-text">
            <span>IPM stands for the Integrated Programme in Management (IPM). It is a five-year programme in management offered by the Indian Institute of Management, Indore (IIM Indore). The programme is aimed at students who have passed out of class 12th / Higher Secondary or equivalent from various schools in India.</span>
            <br /><br />
            <span>It is definitely worth a try even for those who have just joined their Bachelor's course elsewhere.</span>
        </div>
    </div>
    <div className="class-info">
        <div className="dlab-post-title ">
            <h2 className="post-title m-t0"><a>Why IPM?</a></h2>
        </div>
        <div className="dlab-post-text">
            <span>It is for the first time that a management institute of the repute of an IIM is offering a course admitting students who completed their higher secondary examinations. IIM Indore aims to develop competent management professionals, change agents and outstanding leaders through this program. It provides an excellent opportunity for young aspirants, who want to pursue a career in management. The programme will help the students getting a head start in the field of management much earlier than they could have through the more traditional approach of post graduation or a masters program. The program will also aid students in overall personality development and bring about societal awareness in them.</span><br />
            <br />
            <span>There are several other reasons why this program would excite a student fresh out of school:</span>
            <ul>
                <li>Better training in management - thanks to a wider training horizon and a world-class IIM faculty.</li>
                <li>The brand value of being an IIM graduate alongside your graduation.</li>
                <li>Much improved employability as compared to traditional graduation options.</li>
                <li>Relatively easier entrance test as compared to the other management entrance tests post graduation where the number of applicants is much higher.</li>
            </ul>
        </div>
    </div>
    <QuestionAnswer/>
    </div>
  )
}

export default IIMIndore
