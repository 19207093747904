import React, { useState } from 'react'
import NLATComponent from './NLATComponent'

const ComponentCLAT = (props) => {
    const [fourthbtn, setfourthbtn] = useState(false)
    const [data, setdata] = useState({
            id: "1",
            Heading: "CLAT PATTERN & SYLLABUS",
            Headinginfo: "The Common Law Admission Test (CLAT) is a national level entrance exam for admissions to undergraduate (UG) and postgraduate (PG) law programmes offered by 22 National Law Universities around the country. National Law Universities belongs to the kitty of the best Law Schools in country for 5 year integrated Law course (BA-LLB, BBA-LLB, Bcom-LLB etc.) just after class 12. CLAT is held by the Consortium of National Law Universities which is a body that constitutes all the NLUs.CLAT would focus mainly on evaluating the comprehension and reasoning abilities of law aspirants. All in all, the exam will judge the students on their aptitude and skills that are necessary for any lawyer. Further, the exam is not based on prior knowledge; though prior knowledge may find usefulness in the Current Affairs section",
            PattrenExam: {
                First: "120 MCQ (Multiple Choice Questions)",
                Second: "Time: 2 hours",
                Third: "One mark for each correct answer",
                Fourth: "There is negative marking of 0.25 for each wrong answer.",
                Fifth: "Offline Exam (Paper Based Exam)",
                Sixth: "Medium of Exam—English",
                Seventh: "5 Sections (English Language, Current Affairs including General Knowledge, Legal Reasoning, Logical Reasoning, Quantitative Techniques)",
            },
            Table: {
                first: {
                    key: "English Language",
                    value: "22-26 questions, or roughly 20% of the paper"
                },
                Second: {
                    key: "Current Affairs & General Knowledge",
                    value: "28-32 questions, or roughly 25% of the paper"
                },
                Third: {
                    key: "Legal Reasoning",
                    value: "22-26 questions, or roughly 20% of the paper"
                },
                Fourth: {
                    key: "Quantitative Techniques",
                    value: "10-14 questions, or roughly 10% of the paper"
                }
            },
            syllabus: {
                first: {
                    heading: "English Language",
                    ans: "In this section, the students will encounter passages of about 450 words each. These passages will cover a multitude of topics varying from historical fiction and non-fiction to science, business, social sciences etc. The difficulty level will be such that a 12th standard student may easily read in 5-7 minutes. Each passage will carry with it a number of questions that will be based on understanding of the text and language skills. Apart from comprehension, these questions will demand thorough reading and understanding of the main ideas discussed in the passage and any claim and/or opinion discussed in the passage. The questions will demand you to draw inferences and conclusions from the passage, summaries the text, compare and contrast the different arguments or ideas presented in the passage; identify the meaning of various words and phrases used in the passage."
                },
                Second: {
                    heading: "Current Affairs & General Knowledge",
                    ans: "This section will offer passages of about 450 words each. The passages will be extracted from news, journalistic sources and other non-fiction writing. The questions may test you on knowledge presented in or related to the passage, but would not require any prior knowledge of the law beyond the passage. Each passage will be followed by a series of questions that will be based on your awareness of various aspects of current affairs and general knowledge, including contemporary events of significance from India and the world, arts and culture, international affairs, and historical events of continuing significance."
                },
                Third: {
                    heading: "Legal Reasoning",
                    ans: "This section will contain passages of around 450 words each. These passages may relate to factual situations or scenarios involving legal matters, public policy questions or moral philosophical enquiries. The important point here is, you will not require any prior knowledge of law. General awareness of contemporary legal and moral issues will prove to be an advantage to better apply general principles or propositions to the given factual scenarios. You are expected to answer the questions that will be based on interferences drawn from the rules and principles given in the passage, apply such rules and principles to various fact situations and understand how changes to the rules or principles may alter their application to various fact situations"
                },
                Fourth: {
                    heading: "Logical Reasoning:",
                    ans: "This section will include a series of short passages of about 300 words each. Each passage will be followed by one or more questions that will be based on concepts like identifying an argument, its premises and conclusions; critically analyzing the reasoning, and figuring out how conclusions may be derived from particular premises or evidence; drawing inferences from the passage; identifying relationships and analogies, contradictions and equivalence, and assessing the effectiveness/validity of arguments."
                },
                Fifth: {
                    heading: "Quantitative Techniques:",
                    ans: "This section will include short sets of facts or propositions, graphs, or other textual, pictorial or diagrammatic representations of numerical information, followed by a series of questions. You will be required to derive information from these graphs, or other representations, and apply mathematical operations on such information. The questions will be based on understanding numerical information given in such passages, graphs, or other representations; and applying various 10th standard mathematical operations (ratios and proportions, basic algebra, mensuration etc.) You should work on developing your reading abilities and comprehending text from diverse sources. Make sure that you stay updated with the news and current affairs, read quality newspapers and periodicals, and improve your speed and accuracy while answering questions on quantitative techniques. You can refer to the study material of 10th standard mathematics textbooks."
                },
                Sixth: {
                    heading: "CLAT Eligibility:",
                    ans: "There is no upper age limit for UG Programme in CLAT.As regards minimum percentage of marks in the qualifying examination (i.e., 10+2 or equivalent examination) , the candidates must have secured Scored Forty five percent (45%) marks in case of candidates belonging to Unreserved/OBC/Specially Abled Persons (SAP) and other categories, and Scored Forty Percent (40%) marks in case of candidates belonging to Scheduled Caste/Scheduled Tribe (SC/ST) categories."
                }
            }
})
    var btncontent = [
        {
            id: "1",
            Heading: "CLAT PATTERN & SYLLABUS",
            Headinginfo: "The Common Law Admission Test (CLAT) is a national level entrance exam for admissions to undergraduate (UG) and postgraduate (PG) law programmes offered by 22 National Law Universities around the country. National Law Universities belongs to the kitty of the best Law Schools in country for 5 year integrated Law course (BA-LLB, BBA-LLB, Bcom-LLB etc.) just after class 12. CLAT is held by the Consortium of National Law Universities which is a body that constitutes all the NLUs.CLAT would focus mainly on evaluating the comprehension and reasoning abilities of law aspirants. All in all, the exam will judge the students on their aptitude and skills that are necessary for any lawyer. Further, the exam is not based on prior knowledge; though prior knowledge may find usefulness in the Current Affairs section",
            PattrenExam: {
                First: "120 MCQ (Multiple Choice Questions)",
                Second: "Time: 2 hours",
                Third: "One mark for each correct answer",
                Fourth: "There is negative marking of 0.25 for each wrong answer.",
                Fifth: "Offline Exam (Paper Based Exam)",
                Sixth: "Medium of Exam—English",
                Seventh: "5 Sections (English Language, Current Affairs including General Knowledge, Legal Reasoning, Logical Reasoning, Quantitative Techniques)",
            },
            Table: {
                first: {
                    key: "English Language",
                    value: "22-26 questions, or roughly 20% of the paper"
                },
                Second: {
                    key: "Current Affairs & General Knowledge",
                    value: "28-32 questions, or roughly 25% of the paper"
                },
                Third: {
                    key: "Legal Reasoning",
                    value: "22-26 questions, or roughly 20% of the paper"
                },
                Fourth: {
                    key: "Quantitative Techniques",
                    value: "10-14 questions, or roughly 10% of the paper"
                }
            },
            syllabus: {
                first: {
                    heading: "English Language",
                    ans: "In this section, the students will encounter passages of about 450 words each. These passages will cover a multitude of topics varying from historical fiction and non-fiction to science, business, social sciences etc. The difficulty level will be such that a 12th standard student may easily read in 5-7 minutes. Each passage will carry with it a number of questions that will be based on understanding of the text and language skills. Apart from comprehension, these questions will demand thorough reading and understanding of the main ideas discussed in the passage and any claim and/or opinion discussed in the passage. The questions will demand you to draw inferences and conclusions from the passage, summaries the text, compare and contrast the different arguments or ideas presented in the passage; identify the meaning of various words and phrases used in the passage."
                },
                Second: {
                    heading: "Current Affairs & General Knowledge",
                    ans: "This section will offer passages of about 450 words each. The passages will be extracted from news, journalistic sources and other non-fiction writing. The questions may test you on knowledge presented in or related to the passage, but would not require any prior knowledge of the law beyond the passage. Each passage will be followed by a series of questions that will be based on your awareness of various aspects of current affairs and general knowledge, including contemporary events of significance from India and the world, arts and culture, international affairs, and historical events of continuing significance."
                },
                Third: {
                    heading: "Legal Reasoning",
                    ans: "This section will contain passages of around 450 words each. These passages may relate to factual situations or scenarios involving legal matters, public policy questions or moral philosophical enquiries. The important point here is, you will not require any prior knowledge of law. General awareness of contemporary legal and moral issues will prove to be an advantage to better apply general principles or propositions to the given factual scenarios. You are expected to answer the questions that will be based on interferences drawn from the rules and principles given in the passage, apply such rules and principles to various fact situations and understand how changes to the rules or principles may alter their application to various fact situations"
                },
                Fourth: {
                    heading: "Logical Reasoning:",
                    ans: "This section will include a series of short passages of about 300 words each. Each passage will be followed by one or more questions that will be based on concepts like identifying an argument, its premises and conclusions; critically analyzing the reasoning, and figuring out how conclusions may be derived from particular premises or evidence; drawing inferences from the passage; identifying relationships and analogies, contradictions and equivalence, and assessing the effectiveness/validity of arguments."
                },
                Fifth: {
                    heading: "Quantitative Techniques:",
                    ans: "This section will include short sets of facts or propositions, graphs, or other textual, pictorial or diagrammatic representations of numerical information, followed by a series of questions. You will be required to derive information from these graphs, or other representations, and apply mathematical operations on such information. The questions will be based on understanding numerical information given in such passages, graphs, or other representations; and applying various 10th standard mathematical operations (ratios and proportions, basic algebra, mensuration etc.) You should work on developing your reading abilities and comprehending text from diverse sources. Make sure that you stay updated with the news and current affairs, read quality newspapers and periodicals, and improve your speed and accuracy while answering questions on quantitative techniques. You can refer to the study material of 10th standard mathematics textbooks."
                },
                Sixth: {
                    heading: "Quantitative Techniques:",
                    ans: "There is no upper age limit for UG Programme in CLAT.As regards minimum percentage of marks in the qualifying examination (i.e., 10+2 or equivalent examination) , the candidates must have secured Scored Forty five percent (45%) marks in case of candidates belonging to Unreserved/OBC/Specially Abled Persons (SAP) and other categories, and Scored Forty Percent (40%) marks in case of candidates belonging to Scheduled Caste/Scheduled Tribe (SC/ST) categories."
                }
            }

        }
        , {
            id: "2",
            Heading: "AILET PATTERN & SYLLABUS",
            Headinginfo: "All India Law Entrance Test (AILET) is the most important law exam after CLAT. It is the entrance exam for National Law University, Delhi. It was established in 2008 and is a premier law university in the country. Law aspirants have to work really hard and have to put in extra efforts to crack this exam since this exam is a bit more difficult than CLAT. ",
            PattrenExam: {
                First: "150 MCQ (Multiple Choice Questions)",
                Second: "Time: 120 minutes",
                Third: "One mark for each correct answer",
                Fourth: "There is negative marking of 0.25 for each wrong answer.",
                Fifth: "Offline Exam (Paper Based Exam)",
                Sixth: "Medium of Exam—English",
                Seventh: "3 Sections (English Language, Current Affairs & General Knowledge, Logical Reasoning)",
            },
            Table: {
                first: {
                    key: "English Language",
                    value: "50"
                },
                Second: {
                    key: "Current Affairs & General Knowledge",
                    value: "30"
                },
                Third: {
                    key: "Logical Reasoning",
                    value: "70"
                }
            },
            syllabus: {
                first: {
                    heading: "English Language",
                    ans: "Reading Comprehension, Grammar, Foreign Words/Phrase, Para-jumbles, Vocabulary, Spellings, Inference-based questions, Synonyms, Antonyms, Word Usage, and Fill in the blanks. The level of difficulty is medium and it should be a high scoring section."
                },
                Second: {
                    heading: "Current Affairs & General Knowledge",
                    ans: "The questions asked in the GK section are from two domains i.e. Static GK and current GK. Static GK questions are questions from History, Geography, Civics, etc. while Current GK covers major events in news for the past 1 year. Major topics are International events, UN bodies’ Major developments in the areas of sports, geopolitics and important environmental agreements, eminent personalities, sports, awards and honours."
                },
                Third: {
                    heading: "Logical Reasoning",
                    ans: "The Logical reasoning section has a fair mix of Puzzles, Directions, Seating Arrangements, Blood Relations, Syllogisms, Series, Analogy etc. The Paper also has a few questions of Critical Reasoning. This section is fairly simple and should boost the marks of reasonably well prepared students. Legal principles may be used in the logical reasoning section to test logical aptitude but the examination will not require any kind of legal knowledge or technical understanding."
                },
                Fourth: {
                    heading: "AILET Eligibility",
                    ans: "All candidates, other than those applying under the Foreign Nationals category, should appear for the All India Law Entrance Test (AILET) and selection shall be solely on that basis.In order to appear for the AILET, candidates should have passed the 10+2 examination and scored a minimum of 50%. However, candidates appearing for 10+2 examinations in the year of the AILET can also apply provided they meet the above eligibility at the time of admission."
                }
            }

        }
        , {
            id: "3",
            Heading: "SLAT PATTERN & SYLLABUS",
            Headinginfo: "SLAT (Symbiosis Law Admission Test) is the entrance exam for UG Programs of Symbiosis. SLAT is there for getting entry in the 5-year integrated law course at Symbiosis Law School at Pune, Noida and Hyderabad Campuses. SLAT is relatively easy compared to CLAT and AILET. If a student has prepared well for CLAT, SLAT should be piece of cake for him/her.",
            PattrenExam: {
                First: "60 MCQ (Multiple Choice Questions)",
                Second: "TIME: 60 minutes",
                Third: "One mark for each correct answer",
                Fourth: "There is no negative marking.",
                Fifth: "Online Exam (Computer Based Exam)",
                Sixth: "Medium of Exam: English",
                Seventh: "5 Sections (Reading, General Knowledge, Legal Reasoning, Logical Reasoning, and Analytical Reasoning)",
            },
            Table: {
                first: {
                    key: "Reading",
                    value: "12"
                },
                Second: {
                    key: "General Knowledge",
                    value: "12"
                },
                Third: {
                    key: "Legal Reasoning",
                    value: "12"
                },
                Fourth: {
                    key: "Logical Reasoning",
                    value: "12"
                },
                Fifth: {
                    key: "Analytical Reasoning/Quantitative Aptitude",
                    value: "12"
                }
            },
            syllabus: {
                first: {
                    heading: "SLAT Eligibility",
                    ans: "The candidate should have passed XII (10+2) examination from any recognized Board with a minimum of 45% marks (40% for S.C. /S.T. candidates).Students who have appeared for their Standard XII final examination and are awaiting results are also eligible to apply, subject to submission of passing certificate at the time of the final admission."
                }
            }

        }
        , {
            id: "4",
            Heading: "LSAT PATTERN & SYLLABUS",
            Headinginfo: "The LSAT—India is a test of high-level reading, informal reasoning, and deductive reasoning skills. These acquired critical thinking skills are essential to succeed in law college and in the practise of the law. The LSAT—India was developed specifically to be used by law colleges in India. It will be administered online across India.",
            PattrenExam: {
                First: "Analytical Reasoning",
                Second: "Logical Reasoning",
                Third: "Reading Comprehension",
            },
            Table: {
                first: {
                    key: "Analytical Reasoning",
                    value: "12"
                },
                Second: {
                    key: "Logical Reasoning (1)",
                    value: "12"
                },
                Third: {
                    key: "Logical Reasoning (2)",
                    value: "12"
                },
                Fourth: {
                    key: "Reading Comprehension",
                    value: "12"
                }
            },
            syllabus: {
                first: {
                    heading: "LSAT—India Scores",
                    ans: "While you will receive credit for the correct answers, there is no negative marking or penalty for incorrect answers. Accordingly, be sure to save some time at the end of each section to fill answers to any unanswered questions.You will receive a scaled score between 420 and 480, a score band, as well as a percentile rank."
                }
            }

        }
    ]
    function btnfiter(idbtn) {
        setfourthbtn(false)
        var filterdata = btncontent.filter(function (obj) {
            return (obj.id == idbtn)
        })
        // console.log(filterdata=="")
        if (filterdata == "") {
            setdata({})
        } else {
            setdata(filterdata[0])
        }
    }

    return (
        <div>
            <div className="page-content">
                <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: 'url(newsliderimages/website-slider-clat.jpg)' }}>
                    <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">CLAT</h1>
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li>(Common Law Admission Test)</li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            <div className="content-block">
                <div className="section-full bg-white content-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                                <div className="classes-details">
                                    <div className="class-btns">

                                        <div className="dlab-post-text">
                                            <div className='cusbtns' >
                                                <button onClick={() => btnfiter("1")} className=' m-t10' >CLAT </button>
                                                <button onClick={() => btnfiter("2")} className=' m-t10' >AILET</button>
                                                <button onClick={() => btnfiter("3")} className=' m-t10' >SLAT</button>
                                                <button onClick={() => btnfiter("4")} className=' m-t10' >LSAT</button>
                                                <button onClick={() => setfourthbtn(!fourthbtn)} className=' m-t10' >NLAT</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                                <div className="classes-details">
                                    {
                                        fourthbtn ? <NLATComponent /> : <div>
                                            <div className="class-info">
                                                <div className="dlab-post-title ">
                                                    <h2 className="post-title m-t0"><a >{data.Heading}</a></h2>
                                                </div>
                                                <div className="dlab-post-text">
                                                    <p>{data.Headinginfo}</p>
                                                </div>
                                            </div>
                                            <div className="class-info">
                                                <div className="dlab-post-title ">
                                                    <h2 className="post-title m-t0"><a >Pattren of Exam</a></h2>
                                                </div>
                                                <div className="dlab-post-text">
                                                    <ul className='tick-list' >
                                                        {
                                                            Object.keys(data.PattrenExam).map(function (key,index) {   
                                                                return (
                                                                    <li key={index}>{data.PattrenExam[key]}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="class-info">
                                                <div className="dlab-post-text">
                                                    <div className='table-responsive' >
                                                        <table className='table table-hover table-bordered  custom-colortable' >
                                                                <thead style={{ backgroundColor: "#3e4095" }} >
                                                                    <tr>
                                                                    <th>Subjects / Sections</th>
                                                                    <th>Weightage</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        Object.keys(data.Table).map(function (key,index) {    
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{data.Table[key].key}</td>
                                                                                    <td>{data.Table[key].value}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                         /* <tr>
                                                                             <td>English Language</td>
                                                                             <td>22-26 questions, or roughly 20% of the paper</td>
                                                                         </tr>
                                                                         <tr>
                                                                             <td>Current Affairs including General Knowledge</td>
                                                                             <td>28-32 questions, or roughly 25% of the paper</td>
                                                                         </tr>
                                                                         <tr>
                                                                             <td>Legal Reasoning</td>
                                                                             <td>28-32 questions, or roughly 25% of the paper</td>
                                                                         </tr>
                                                                         <tr>
                                                                             <td>Logical Reasoning</td>
                                                                             <td>22-26 questions, or roughly 20% of the paper</td>
                                                                         </tr>
                                                                         <tr>
                                                                             <td>Quantitative Techniques</td>
                                                                             <td>10-14 questions, or roughly 10% of the paper</td>
                                                                         </tr> */}
                                                                </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="class-info">
                                                <div className="dlab-post-title ">
                                                    <h2 style={{ fontSize: "45px", fontWeight: "600" }} className="post-title m-b5"><a >SYLLABUS:-</a></h2>
                                                </div>
                                                <div className="dlab-post-text">
                                                    {
                                                        Object.keys(data.syllabus).map(function (key,index) {    
                                                            return (
                                                                <div key={index} className="class-info">
                                                                    <div className="dlab-post-title ">
                                                                        <h2 className="post-title m-t0"><a >{data.syllabus[key].heading}</a></h2>
                                                                    </div>
                                                                    <div className="dlab-post-text">
                                                                        <p>{data.syllabus[key].ans}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    }


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div >
    )
}

export default ComponentCLAT