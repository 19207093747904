import "./reviews.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Firebase, { database } from "../../../Firebase";
const Review = () => {
const[data,setdata]=useState(null)
const navigate=useNavigate()
useEffect(()=>{
  database.child("Reviews").on("value",function(snap){
    if(snap.val()) return setdata(snap.val())
    else return setdata(null)
  })
},[])
async function Delete(key){
  try {
    await Firebase.child(data[key].Path).delete()
  database.child("Reviews").child(key).remove(err=>{
    if(err) return alert("Something went wrong.Try again later.")
    else return alert("Review Deleted Successfully")
  })
  } catch (error) {
   return alert("Something went wrong.Try again later") 
  }
}
function Add(){
  if(!data || Object.keys(data).length<7) return navigate("new")
  return alert("Only 7 Students reviews are supported")
}
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton" onClick={Add}>Add Reviews</div>
            <h1 className="title">Student's Reviews</h1>
            {
              data?Object.keys(data).map((key,index)=>{
                return(
                  <div key={index} className="item">
              <img
                src={data[key]?.Image?data[key]?.Image:"https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"}
                alt=""
                loading="lazy"
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle">{data[key]?.Name}</h1>
                <div className="detailItem">
                  <span className="itemKey">Date:</span>
                  <span className="itemValue">{data[key]?.Date}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Review:-</span>
                  <span className="itemValue">{data[key]?.Review}</span>
                </div>
                <div className="mydetailItem">
                  <button onClick={()=>Delete(key)}>Delete</button> 
                </div>
              </div>
            </div>
                )
              }):<p style={{textAlign:"center"}}>No Review Found</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;