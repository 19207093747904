import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { database } from "../../../Firebase";

const CurrentAffair = () => {
  const userColumns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "Title",
      headerName: "Title",
      width: 450,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
            {params.row.Title}
          </div>
        );
      },
    },
    {
      field: "Url",
      headerName: "Url",
      width: 450,
    }
  ];
  const [data, setData] = useState([]);
  useEffect(function(){
    database.child("CurrentAffairs").on("value",function(snap){
      if(snap.val()){
        const result= Object.keys(snap.val()).map((key,index)=>{return {...snap.val()[key],id:index+1,key:key}})
        return setData(result)
      }
      else return setData([])
    })
  },[])

  const handleDelete = (id) => {
    database.child(`CurrentAffairs/${id}`).remove()
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      display:"flex",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <a style={{ textDecoration: "none" }}>
              <div className="viewButton">Edit</div>
            </a> */}
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.key)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      {data.length!==0 &&<DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        />}
    </div>
  );
};

export default CurrentAffair;