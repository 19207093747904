import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header'
import Componentcuet from '../Components/Componentcuet'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));
const CUET = () => {
  useEffect(() => {
    const link = document.createElement('link');
    const link2 = document.createElement('link');
    const link3 = document.createElement('link');
    const link4 = document.createElement('link');
    const link5 = document.createElement('link');
    const link6 = document.createElement('link');
    const link7 = document.createElement('link');
    const link8 = document.createElement('link');
    link.rel = 'stylesheet';
    link2.rel = 'stylesheet';
    link3.rel = 'stylesheet';
    link4.rel = 'stylesheet';
    link5.rel = 'stylesheet';
    link6.rel = 'stylesheet';
    link7.rel = 'stylesheet';
    link8.rel = 'stylesheet';
    link.href = "css/plugins.min.css"; // Path to your CSS file
    link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
    link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
    link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
    link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
    link6.href = "css/style.min.css"; // Path to your CSS file
    link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
    link8.href = "css/templete.min.css"; // Path to your CSS file
    link.id = 'home-css'; // Set an ID for easy removal
    link2.id = 'home-css'; // Set an ID for easy removal
    link3.id = 'home-css'; // Set an ID for easy removal
    link4.id = 'home-css'; // Set an ID for easy removal
    link5.id = 'home-css'; // Set an ID for easy removal
    link6.id = 'home-css'; // Set an ID for easy removal
    link7.id = 'home-css'; // Set an ID for easy removal
    link8.id = 'home-css'; // Set an ID for easy removal

    document.head.append(link);
    document.head.append(link2);
    document.head.append(link3);
    document.head.append(link4);
    document.head.append(link5);
    document.head.append(link6);
    document.head.append(link7);
    document.head.append(link8);

    return () => {
        document.getElementById('home-css')?.remove(); // Remove when unmounting
    };
}, []);
  return (
    <div>
      <div className="page-wraper">
      <Header cuet="active"/>
      <div className="page-content">
      <Componentcuet Headingbanner1={"CUET"} Headingbanner2={"(COMMON UNIVERSITY ENTRANCE TEST)"} HeadingImage={"images/MAIN-SLIDER-4.jpg"} QnA={""} Heading={"CUET (Common University Entrance Test)"} Details={"The CUET (Common University Entrance Test) Course at Genius IAS is designed to provide students with the necessary skills and knowledge to excel in the CUET examination. This course covers a wide range of subjects and topics required for admission to various undergraduate programs across central universities. With a focus on both conceptual clarity and exam strategies, our curriculum prepares students to tackle the challenges of the CUET with confidence."} blockquote={"he CUET Course at Genius IAS is meticulously structured to enhance students' understanding of the exam syllabus. With interactive learning sessions, personalized mentorship, and extensive practice materials, this course empowers students to develop critical thinking and analytical skills, ensuring they are well-prepared for their university admissions."} Course={"CUET (Common University Entrance Test) "} CourseDetails={"Our aim is to provide students with complete preparation, personalized guidance, and important skills. We help them develop critical thinking and stay updated on current events, so they can succeed in the UPSC exam and become effective leaders in the future."}  CourseImg={"images/Geniusdetail.jpg"} />
      </div>
      </div>
      <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
        <Footer/>
      </Suspense>
      <ScrollToTop/>
      <Popup/>
    </div>
  )
}
export default CUET