import "./BlogDetail.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Context from "../../context/BlogsContext";
const BlogDetail = () => {
const navigate = useNavigate()
const {productId}=useParams()
const {fetchdata}=useContext(Context)
const [data,setdata]=useState(null)
useEffect(()=>{
  if(fetchdata) setdata(fetchdata[productId])
},[productId,fetchdata])
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="leftmain">
            <div className="editButton" onClick={() => navigate("/Admin/Blogs/new")}>Add New Blog</div>
            <h1 className="title">Blog Details</h1>
            <div className="details">
              <h1 className="itemTitle">{data?.Title && data?.Title}</h1>
            </div>
            <div className="itemdetails">
              <div className="mainimage" >
              <img
                src={data?.Heading_Image?.url && data?.Heading_Image?.url}
                alt=""
                loading="lazy"
                className="itemImge"
              />
              </div>
              <div className="details">
                <div className="detailItem">
                  <h1>{data?.Heading && data?.Heading}</h1>
                  <p>{data?.Description && data?.Description}</p>
                </div>
                <div className="detailImage" >
                  {
                    data?.Images?.map(function(obj,index){
                      return(
                      <div key={index} className="itemimage" >
                        <img loading="lazy" className="img" src={obj?.url && obj?.url} alt="" />
                      </div>
                      )
                      
                    })
                  }
                  {/* <div className="itemimage" >
                  <img className="img" src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="" />
                  </div>
                  <div className="itemimage" >
                  <img className="img" src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="" />
                  </div> */}
                </div>
                {
                  data?.Data?.map(function(obj,index){
                    return(
                      <div key={index} className="detailItem">
                        <h2>{obj?.Sub_Heading && obj?.Sub_Heading}</h2>
                        <p>{obj?.Sub_Heading_Description && obj?.Sub_Heading_Description}</p>
                      </div>
                    )
                  })
                }
              </div>
            </div>

          </div>
          <div className="left">
            {
              fetchdata && productId && Object.keys(fetchdata).map((key,index)=>{
                if(productId!==key){
                  return(
                    <div key={index} className="smallblog">
                    <div className="blogleftmain">
                      <div onClick={()=>navigate(`/Admin/Blogs/${key}`)} className="imgmain"> 
                  <img src={fetchdata[key]?.Heading_Image?.url && fetchdata[key]?.Heading_Image?.url} loading="lazy" alt="" />
                      </div>
                    </div>
                    <div className="blogrightmain">
                      <h3>{fetchdata[key]?.Date && fetchdata[key]?.Date}</h3>
                      <h1>{fetchdata[key]?.Title && fetchdata[key]?.Title}</h1>
                      <span onClick={()=>navigate(`/Admin/Blogs/${key}`)}>Read more  </span>
                    </div>
                  </div>
                        )
                }
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;