import React from 'react'

const IIFTkakinada = () => {
  return (
    <div>
        <div className="class-info">
            <div className="dlab-post-title">
            <h2 className="post-title m-t0"><a>About IPM IIFT Kakinada</a></h2>
            </div>
            <div className="dlab-post-text">
            <span>The Five Year Integrated Program in Management (IPM) at IIFT-Kakinada aims at imparting managerial and decision making capabilities to young professionals along with integrating management education with Science, Technology, Engineering, and Mathematics (STEM).</span><br /><br />
            <span>Candidates successfully completing the course requirement of integrated programme in management would be awarded Bachelor of Business Administration (Business Analytics) at the end of 3 years and Master of Business Administration (International Business) at the end of 5 years by Indian Institute of Foreign Trade. Candidates opting for exit after completing the requirement of first three years, would be awarded Bachelor of Business Administration (Business Analytics). The first three years of the IPM program would be based on semester system and the last two years would be based on the trimester system (as per the prevailing MBA course structure).</span><br /><br />
            </div>
            <div className="dlab-post-title">
            <h2 className="post-title m-t0"><a>ADMISSION PROCEDURE</a></h2>
            <h4 className="post-title m-t0"><a>Eligibility for Admission:</a></h4>
            </div>
            <div className="dlab-post-text">
                <ul>
                    <li>Candidates should have passed 10+2/XII/HSC examination in arts/commerce/science stream or equivalent with 60% (55% for candidates from SC/ST/PwD/Transgender) or more in the year 2021, 2022 or appearing in 2023.                    </li>
                    <li>The candidate must have passed class 10th examination with 60% (55% for candidates from SC/ST/PwD/Transgender) or more in the year not before 2019.                    </li>
                    <li style={{fontWeight:500}}>Mathematics/ Business Mathematics as a subject in +2 level is mandatory.                    </li>
                </ul>
            </div>
            <div className="dlab-post-title">
            <h4 className="post-title m-t0"><a>Age:</a></h4>
            <ul>
            <li>A candidate should have been born on or after August 01, 2002 (5 years of relaxation to the candidates from SC/ST/PwD category will be given i.e. born on or after August 01, 1997).</li>
            </ul>
            </div>
            <div className="dlab-post-title">
            <h4 className="post-title m-t0"><a>Mode of Admission</a></h4> 
            <span>For short-listing/selecting the candidates, IIFT will use score of IPMAT examination conducted by IIM-Indore</span><br />
            </div>
            <div className="dlab-post-title">
            <h4 className="post-title m-t0"><a>Selection Process</a></h4>  
            <span>Merit list for selection of the candidate for the admission will be prepared on the basis of his/her IPMAT Score of IIM-Indore, Academic Profile of class 10th and Gender Diversity.</span><br />
            </div>
            <div className="dlab-post-title">
            <h4 className="post-title m-t0"><a>Criteria for Selection</a></h4> 
            </div>
            <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr className='text-center'>
                                <th>Parameters</th>
                                <th>Scores</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Entrance test score	</td>
                                    <td>78</td>
                                </tr>
                                <tr>
                                    <td>Gender Diversity (M=0, F=2)	</td>
                                    <td>2</td>
                                </tr>
                                <tr>
                                    <td>Academic Consistency* (10th)	</td>
                                    <td>20</td>
                                </tr>
                                <tr style={{fontWeight:500}}>
                                    <td>Total</td>
                                    <td>100</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h5 className="post-title m-t0"><a>*Marks for Academic Consistency in class 10th will be calculated as per rating scores tables provided below</a></h5><br />
                    <h5 className="post-title m-t0"><a>Rating Scores for the 10th Std. Examination</a></h5>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive'>
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr className='text-center'>
                                <th>Percent score in 10th Std. Exam	</th>
                                <th>Rating Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> &gt; 55 and &lt;= 60</td>
                                    <td>4</td>
                                </tr>
                                <tr>
                                <td>&gt; 60 and &lt;= 70</td>
                                <td>6</td>
                                </tr>
                                <tr>
                                <td>&gt; 70 and &lt;= 80</td>
                                <td>10</td>
                                </tr>
                                <tr>
                                <td>&gt; 80 and &lt;= 90</td>
                                <td>16</td>
                                </tr>
                                <tr>
                                <td>&gt; 90</td>
                                <td>20</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title">
                    <h2 className="post-title m-t0"><a>Fees and Accommodation:</a></h2>
                    <span>The course fee for the first three year of the programme is Rs.4 lakh per annum excluding hostel and mess fees. Course fee for the fourth and fifth years would be as per MBA(IB) programme fees set for that year.</span><br />
                    <span>Integrated Programme in Management 2022-27 is a fully residential programme.</span><br /><br />
                    <h2 className="post-title m-t0"><a>How to Apply:</a></h2>
                    <span>Those candidates who wish to apply for IIFT’s IntegratedProgramme in Management (BBABusiness Analytics and MBA-International Business) 2022-27 have to mandatorily apply for IIM- Indore entrance exam (IPMAT) Candidates are advised to check the IIFT’s eligibility criteria carefully before applying for IPMAT entrance exam.</span><br /><br />
                    <span><span style={{fontWeight:500}}>Step 1:</span>Please visit IIM- Indore website (visit www.iimidr.ac.in) and fill their Online Application Form. After submitting the application successfully there, note the registration number of your application.</span><br /><br />
                    <span><span style={{fontWeight:500}}>Step 2:</span>Visit IIFT website (www.iift.ac.in) and click on the ADMISSIONS 2022 icon. Submit IIFT’s online application form as per details given on the website. Please note that it is mandatory to fill IPMAT registration number in IIFT application form.</span><br />
                    <br />
                    <h2 className="post-title m-t0"><a>Application Fee:</a></h2>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive'>
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr className='text-center'>
                                <th>CATEGORY</th>
                                <th>IPMAT (IIM-Indore)</th>
                                <th>IIFT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>For General/OBC	</td>
                                    <td>4,130</td>
                                    <td>2,000</td>
                                </tr>
                                <tr>
                                <td>For SC/ST/PWD Candidates	</td>
                                    <td>2,065</td>
                                    <td>1,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> 
    </div>
  )
}

export default IIFTkakinada
