import React from 'react'

const IIMSIRMARUR = () => {
    const managementProgram = [
        {
          year: "1st Year",
          program: "Certificate in management studies"
        },
        {
          year: "2nd Year",
          program: "Advanced certificate in management studies"
        },
        {
          year: "3rd Year",
          program: "Bachelors in management studies"
        },
        {
          year: "4th Year",
          program: "Bachelor (Hons.) in management studies"
        }
      ];
      
  return (
    <div>
          <div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <p>IIM Sirmaur has launched a full time Bachelor in Management Studies (BMS) program.</p>
                    <p>Duration of the program-4 Years.</p>
                    <p>There will be multiple exit options (in case a student decides to leave the program midway) during the program</p>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                               <tr>
                               <th>Sr No.</th>
                                <th>Year</th>
                                <th>Exit Option</th>
                               </tr>
                            </thead>
                            <tbody>
                                {
                                    managementProgram.map(function (obj,index) {
                                        return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{obj.year}</td>
                                                <td>{obj.program}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <p>The best part about the program is that IIM Sirmaur shall offer the possibility of getting admitted to the MBA program of IIM Sirmaur, with CAT exemption, based on GPA of the 4-year program.</p>
                    <p>IIM Sirmaur has released the application form for this program.                    </p>
                </div>
            </div>
           
        </div>
    </div>
  )
}

export default IIMSIRMARUR