import React from 'react'

const AboutGallery = () => {
  return (
    <div>
      <div className="section-full content-inner">
        <div className="container">
          <div className="section-head text-center">
            <h2 className="title text-secondry">Gallery of our classes</h2>
            <p className="ext">We provide three classes with nine to twenty children each aged twelve months to six years of age.</p>
          </div>
          <div className="clearfix" id="lightgallery">
            
            <ul style={{display:"flex",flexWrap:"wrap"}} id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery">
              <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a>
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery1.jpg"  />
                    </a>
                  </div>
                </div>
              </li>
              <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a >
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery2.jpg"  />
                    </a>
                    
                  </div>
                </div>
              </li>
              <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn" data-wow-delay="0.6s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a >
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery3.jpg"  />
                    </a>
                   
                  </div>
                </div>
              </li>
              <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a >
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery4.jpg"  />
                    </a>
                   
                  </div>
                </div>
              </li>
              <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a >
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery5.jpg"  />
                    </a>
                   
                  </div>
                </div>
              </li>
              <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn " data-wow-delay="0.6s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a >
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery6.jpg"  />
                    </a>
                    
                  </div>
                </div>
              </li>
              {/* <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a  >
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery7.jpg" alt />
                    </a>
                   
                  </div>
                </div>
              </li>
              <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn " data-wow-delay="0.6s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a  >
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery9.jpg" alt />
                    </a>
                    
                  </div>
                </div>
              </li>
              <li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn " data-wow-delay="0.6s">
                <div className="dlab-box frame-box m-b30">
                  <div className="dlab-thum dlab-img-overlay1">
                    <a >
                      <img style={{height:"250px"}} src="images/GeniusiasGallery/gallery10.jpg" alt />
                    </a>
                    
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AboutGallery