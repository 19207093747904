import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Courses = (props) => {
  const [openIndex, setOpenIndex] = useState(null); // Track the currently open answer

  const toggleCollapse = (index) => {
    // Set the open index to the clicked index or null if already open
    setOpenIndex(openIndex === index ? null : index);
  };

    
  return (
    <div>

      <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: `url(${props.path})` }}>
                    <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{props.Headingbanner1}</h1>
                        {/* <h1 className="text-white">(Common Law Admission Test)</h1> */}
                        {/* Breadcrumb row */}
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li>{props.Headingbanner2}</li>
                            </ul>
                        </div>
                        {/* Breadcrumb row END */}
                    </div>
                    </div>
                </div>
      <div className="content-block">
        <div className="section-full bg-white content-inner" style={{ backgroundImage: 'url(error-404.html)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
          <div className="container">
            <div className="row">
              
                 
                    <div className="col-lg-6 col-md-12 col-sm-12 m-b15">
                      <div className="section-head">
                        <h2  className="title text-secondry">{props.Heading}</h2>
                        <p className="ext">{props.MainContent}</p>
                      </div>
                      <div className="accordion faq-box" id="accordionExample">
                        {
                          props.QNAQuestions.map((obj, index) => {
                            return (
                              <div className="card" key={index}>
                                <div className="card-header" id={`heading${index}`}>
                                  <a onClick={(e) => { e.preventDefault(); toggleCollapse(index); }} className={openIndex === index ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded={openIndex === index} aria-controls={`collapse${index}`}>
                                    {obj.question}
                                  </a>
                                </div>
                                <div id={`collapse${index}`} className={`collapse ${openIndex === index ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#accordionExample">
                                  <div className="card-body">
                                    {obj.answer}
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="cusborderddiv m-b10">
                          <div className='cusdiv'>
                            <Link to={props.path1}><img src={props.foundationimg}  /></Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="cusborderddiv m-b30">
                          <div className='cusdiv'>
                            <Link to={props.path2}><img src={props.Regularimg}  /></Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="cusborderddiv m-b30">
                          <div className='cusdiv'>
                            <Link to={props.path3} ><img src={props.testSeriesimg}  /></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  
              
            </div>
          </div>
        </div>
        <div className="section-full content-inner" />
      </div>
    </div>
  )
}

export default Courses