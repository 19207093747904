import React from 'react'
import QuestionAnswer2 from './QuestionAnswer2'

const IIMRanchi = () => {
  return (
    <div>
        <div className="class-info">
            <div className="dlab-post-text">
            <span>The Integrated Programme in Management (IPM) of IIM Ranchi is a full-time 5-year blended course of business studies from undergraduate to postgraduate level (Integrated BBA-MBA). Students will be awarded a Master of Business Administration (MBA) degree at the end of the programme.</span>
            </div>
        </div>
        <QuestionAnswer2/>
    </div>
  )
}

export default IIMRanchi
