import React from 'react'
import { DarkModeContextProvider } from './context/darkModeContext'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return(
    <DarkModeContextProvider>
    <Outlet/>
    </DarkModeContextProvider>
  )
}

export default Layout