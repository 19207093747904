import "./counselling.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import ContactCounselling from "../../components/datatable/ContactCounselling"

const Counselling = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <ContactCounselling/>
      </div>
    </div>
  )
}

export default Counselling