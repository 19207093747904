import React, { useState } from 'react'

const QuestionAnswer5 = () => {
const [openIndex, setOpenIndex] = useState(null)
const toggleCollapse = (index) => {
setOpenIndex(openIndex === index ? null : index);
};
  return (
    <div className="content-block">
        <div className="section-full bg-white content-inner" style={{ backgroundImage: 'url(error-404.html)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <div className="row">   
                <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="accordion faq-box" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id={`heading${1}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(1); }} className={openIndex === 1 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${1}`} aria-expanded={openIndex === 1} aria-controls={`collapse${1}`}>Fee payable for JIPMAT-2023</a>
                            </div>
                                    <div id={`collapse${1}`} className={`collapse ${openIndex === 1 ? 'show' : ''}`} aria-labelledby={`heading${1}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                    <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Category</th>
                <th>Male</th>
                <th>Female</th>
                <th>Transgender</th>
                </tr>
                </thead>
               <tbody>
                <tr>
                    <td>General (UR)/ OBC-(NCL)/ EWS as per Central List	</td>
                    <td>INR 2000	</td>
                    <td>INR 2000	</td>
                    <td>INR 1000</td>
                </tr>
                <tr>
                    <td>SC/ST/PwD	</td>
                    <td>INR 1000</td>
                    <td>INR 1000</td>
                    <td>INR 1000</td>
                </tr>
               </tbody>
                </table>
                                      </div>        
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${2}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(2); }} className={openIndex === 2 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${2}`} aria-expanded={openIndex === 2} aria-controls={`collapse${2}`}>JIPMAT -2023 Eligibility</a>
                            </div>
                                    <div id={`collapse${2}`} className={`collapse ${openIndex === 2 ? 'show' : ''}`} aria-labelledby={`heading${2}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                       <span>Candidate should have passed Standard XII/HSC or equivalent examination in 2022, 2023 or appearing in 2024. The candidate must have passed Standard X/ SSC or equivalent examination in the year 2020 or later.</span><br />
                                       <span>Candidate should have a Minimum 60% in standard X/ SSC and standard XII/ HSC or equivalent examinations (55 % for the candidates from SC/ST/ PwD category). Candidates who are likely to complete standard XII/ HSC or equivalent by the end of July 31st, 2024 may also apply.</span><br />
                                       <span>Applicants pursuing their XII standard from international schools affiliated to foreign boards like IGCSE (International General Certificate for Secondary Education) and IB (International Baccalaureate) may also apply.</span>
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${3}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(3); }} className={openIndex === 3 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${3}`} aria-expanded={openIndex === 3} aria-controls={`collapse${3}`}>Test Practice Centers (TPCs)</a>
                            </div>
                                    <div id={`collapse${3}`} className={`collapse ${openIndex === 3 ? 'show' : ''}`} aria-labelledby={`heading${3}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <span>The Ministry of Human Resource Development mandated the NTA to set up, establish and create a network of Test Practice Centres for candidates, especially in remote and rural areas to enable them to practice and be comfortable in taking a Computer Based Test (CBT). This facility is completely free of cost. Candidates can register online (on NTA website) where they are provided a convenient TPC near to their location to practice on a given computer node. The entire experience of using a computer is close to the actual experience of taking a CBT. All efforts are made to provide practice tests and questions so that candidates can familiarize themselves with logging into the system, go through the detailed instructions regarding the test, use the mouse or numeric keyboard on screen (virtual) for attempting each question, scroll down to the next question, navigate between questions, review and edit their options and submit questions.</span><br />
                                      <span>The objective of TPCs is primarily to organize test practice for the upcoming NTA examinations.</span>                                   
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${4}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(4); }} className={openIndex === 4 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${4}`} aria-expanded={openIndex === 4} aria-controls={`collapse${4}`}>Important Instructions</a>
                            </div>
                                    <div id={`collapse${4}`} className={`collapse ${openIndex === 4 ? 'show' : ''}`} aria-labelledby={`heading${4}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                        <span>(i) Candidates can apply for JIPMAT-2021 through "Online" mode only. The Application Form in any other mode will not be accepted.</span><br />
                                        <span>(ii) Only one application is to be submitted by a candidate. Multiple Application Forms submitted by a candidate will not be accepted under any circumstances.</span><br />
                                        <span>Candidates, who desire to appear in JIPMAT - 2023 may see the detailed Information Bulletin available on the NTA websites</span><br />
                                        <span><a href="jipmat.nta.ac.in">jipmat.nta.ac.in </a>/<a href="www.nta.ac.in">www.nta.ac.in</a></span><br />
                                        <span>For any queries/clarifications, candidates may call <a href="tel:011-40759000">011-40759000</a> or write to NTA at: <a href="mailto:jipmat@nta.ac.in">jipmat@nta.ac.in</a></span>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}
export default QuestionAnswer5