import "./telegram.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { database } from "../../../Firebase";

const Telegrams = () => {
const[data,setdata]=useState({})
const[input,setinput]=useState(false)
const[fireobj,setfireobj]=useState(null)
function set(event){
  setdata({...data,[event.target.name]:event.target.value})
}
function submit(e){
  e.preventDefault()
  if(!data.Url) return alert("Field is Empty")
  database.child("Details/Telegram").set(data,err=>{
  if(err) return alert("Something went wrong. Try again later.")
  else return alert("Telegram Link Updated")
  })
  setdata({})
  setinput(true)
}
useEffect(()=>{
database.child("Details/Telegram").on("value",function(snap){
  if(snap.val()){
    setfireobj(snap.val())
    setinput(true)
  }
  else{
    setfireobj(null)
    setinput(false)
  }
})
},[])
function Edit(){
  setinput(false)
  setdata(fireobj)
}
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            {input && <div onClick={Edit} className="editButton">Edit</div>}
            <h1 className="title">Your Profile</h1>
            {
              input?<div className="item">
              <img
                src="/images/logo wesite.jpg"
                alt=""
                className="itemImges"
              />  <br />
              <div className="details">
                <h1 className="itemTitle">Genius IAS</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">geniusiasofficial@gmail.com</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">+91 70272 22124</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">
                  3rd floor, Near, Sheila Bypass Rd, above Bank of Baroda Buidling, Model Town, Rohtak
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">India</span>
                </div>
                {fireobj.Url && <div className="detailItem">
                  <span className="itemKey">Telegram:</span>
                  <span className="itemValue"><a target="_blank" href={fireobj.Url}>Click Here</a></span>
                </div>}
              </div>
            </div>:<form>
              <div className="formInput">
                <label>Telegram Url:</label>
                <input type="url" onChange={set} value={data.Url?data.Url:""} name="Url" placeholder="Enter the Telegram url" />
              </div>
              <button onClick={submit}>Submit</button>
            </form>
            }            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Telegrams;