import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header'
import Courses from '../Components/Courses'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));
const HCS = () => {
useEffect(() =>{
    const link = document.createElement('link');
    const link2 = document.createElement('link');
    const link3 = document.createElement('link');
    const link4 = document.createElement('link');
    const link5 = document.createElement('link');
    const link6 = document.createElement('link');
    const link7 = document.createElement('link');
    const link8 = document.createElement('link');
    link.rel = 'stylesheet';
    link2.rel = 'stylesheet';
    link3.rel = 'stylesheet';
    link4.rel = 'stylesheet';
    link5.rel = 'stylesheet';
    link6.rel = 'stylesheet';
    link7.rel = 'stylesheet';
    link8.rel = 'stylesheet';
    link.href = "css/plugins.min.css"; // Path to your CSS file
    link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
    link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
    link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
    link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
    link6.href = "css/style.min.css"; // Path to your CSS file
    link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
    link8.href = "css/templete.min.css"; // Path to your CSS file
    link.id = 'home-css'; // Set an ID for easy removal
    link2.id = 'home-css'; // Set an ID for easy removal
    link3.id = 'home-css'; // Set an ID for easy removal
    link4.id = 'home-css'; // Set an ID for easy removal
    link5.id = 'home-css'; // Set an ID for easy removal
    link6.id = 'home-css'; // Set an ID for easy removal
    link7.id = 'home-css'; // Set an ID for easy removal
    link8.id = 'home-css'; // Set an ID for easy removal

    document.head.append(link);
    document.head.append(link2);
    document.head.append(link3);
    document.head.append(link4);
    document.head.append(link5);
    document.head.append(link6);
    document.head.append(link7);
    document.head.append(link8);

    return () => {
        document.getElementById('home-css')?.remove(); // Remove when unmounting
    };
},[]);
const QnA=[
    {
      "question": "What is the HCS exam?",
      "answer": "The Haryana Civil Services (HCS) exam is a competitive examination conducted by the Haryana Public Service Commission (HPSC) to recruit officers for various administrative positions in the state of Haryana."
    },
    {
      "question": "Who is eligible to apply for the HCS course?",
      "answer": "Any graduate from a recognized university who meets the age and qualification criteria set by HPSC can apply for the HCS exam. Our course is designed to prepare such candidates."
    },
    {
      "question": "What is the duration of the HCS course at Genius IAS?",
      "answer": "The HCS course typically lasts between 8 to 12 months, covering all major subjects and stages of the exam, including Prelims, Mains, and Interview preparation."
    },
    {
      "question": "What subjects are covered in the HCS course?",
      "answer": "We cover General Studies, History, Geography, Polity, Economics, Science & Technology, Environment, and other optional subjects, as per the HCS syllabus."
    },
    {
      "question": "Do you offer mock tests as part of the HCS course?",
      "answer": "Yes, we conduct regular mock tests for both Prelims and Mains to help students assess their preparation and improve their exam strategy."
    }
]
return(
    <div>
      <div className="page-wraper">
        <Header hcs="active"/>
        <div className="page-content">
        <Courses Headingbanner1={"HCS"} Headingbanner2={"(Haryana Civil Services)"} foundationimg={"images/HCS-FOUNDATION-PROGRAM.jpg"} namefoundaion={"HCS Foundation"} nameregular={"HCS regular"} nametestSeries={"HCS Test Series"}    Regularimg={"images/HCS-REGULAR-PROGRAM.jpg"} testSeriesimg={"images/HCS-TEST-SERIES.jpg"} path1={'/HCSFoundation'} path2={'/HCSRegular'} path3={'/HCSTestSeries'} Heading={"HCS (Haryana Civil Services)"} QNAQuestions={QnA} MainContent={"The HCS Prelims Exam is an offline examination that consists of Objective Multiple Choice Questions. The test consists of two papers: General Studies and the Civil Services Aptitude Test (CSAT). The General Studies paper is two hours long and has one hundred questions, each worth one mark."} path={'newsliderimages/website-slider-new-3.jpg'} />
        </div>
      </div>
      <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
        <Footer/>
      </Suspense>
      <ScrollToTop/>
      <Popup/>
    </div>
  )
}

export default HCS