import React, { useContext, useRef } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BlogContext from '../Pages/BlogContext';
const HomeTestimonials = () => {
const sliderRef = useRef(null); // Create a ref to hold the slider instance
const nextSlide = () => {
    sliderRef.current.slickNext(); // Call the slickNext method on the slider
};
const prevSlide = () => {
    sliderRef.current.slickPrev(); // Call the slickPrev method on the slider
};
const settings = {
    dots: false,        // Hide dots
    infinite: true,     // Loop through slides
    speed: 500,         // Transition speed
    slidesToShow: 2,    // Number of slides to show at a time
    slidesToScroll: 1,  // Number of slides to scroll at a time
    arrows: false, 
    responsive: [
      {
          breakpoint: 1000, // For devices smaller than 768px
          settings: {
              slidesToShow: 1, // Show 1 slide
              slidesToScroll: 1,
          },
      },
      {
          breakpoint: 480, // For devices smaller than 480px
          settings: {
              slidesToShow: 1, // Show 1 slide
              slidesToScroll: 1,
          },
      },
  ],
};
const{FetchReviews}=useContext(BlogContext)  
return(
    <div className="section-full bg-white content-inner-2">
        <div className="container">
            <div className="section-head">
                <h2 className="title">Our Testimonials</h2>
                <div className="dlab-separator bg-primary" />
            </div>
            <div className="client-box2">
                <div className="client-carousel-3 owl-carousel owl-theme sprite-nav owl-loaded owl-drag">
                    <Slider ref={sliderRef}{...settings}>
                    {
                        FetchReviews?Object.keys(FetchReviews).map((key,index)=>{
                            return(
                                <div key={index} className="item m-b40">
                                    <div className="client-box style-2">
                                    <div className="testimonial-pic">
                                    <img loading='lazy' src={FetchReviews[key]?.Image?FetchReviews[key]?.Image:"images/review3.png"}  />
                                    </div>
                                    <div className="testimonial-text">
                                    <p>{FetchReviews[key]?.Review}</p>
                                    <div className="testimonial-detail clearfix">
                                    <h5 className="testimonial-name m-t0 m-b5">{FetchReviews[key]?.Name}</h5> 
                                    <span>Student</span> 
                                    </div>
                                    </div>
                                    </div>
                                </div>)
                        }):<div className='preloader'>
                            <div className="loader"></div>
                        </div>
                    } 
                    {/* <div className="item">
                        <div className="client-box style-2">
                        <div className="testimonial-pic">
                        <img src="images/review1.png"  />
                        </div>
                        <div className="testimonial-text">
                        <p>One of the best choice for aspirants. GENIUS IAS is known for its in-depth coverage of both prelims and main exam syllabi. They provide video lectures, printed study materials, and a dedicated current affairs magazine. Their detailed analysis of previous years' question papers is a valuable resource for aspirants.</p>
                        <div className="testimonial-detail clearfix">
                            <h5 className="testimonial-name m-t0 m-b5">Mandeep Lamba</h5> 
                            <span>Student</span> 
                        </div>
                        </div>
                        </div>
                    </div> 
                    <div className="item">
                        <div className="client-box style-2">
                        <div className="testimonial-pic">
                        <img src="images/review2.png"  />
                        </div>
                        <div className="testimonial-text">
                        <p>Genius IAS has been a game-changer for my UPSC preparation. The faculty is incredibly knowledgeable and supportive, providing personalized guidance that helped me build my confidence.The study material and weekly tests are very helpful for students. Highly recommend!</p>
                        <div className="testimonial-detail clearfix">
                            <h5 className="testimonial-name m-t0 m-b5">Rahul Taneja</h5> 
                            <span>Student</span> 
                        </div>
                        </div>
                        </div>
                    </div>    */}
                   </Slider>
                    <div className="owl-nav">
                        <div onClick={prevSlide} className="owl-prev"><i className="la la-arrow-left" /></div>
                        <div onClick={nextSlide} className="owl-next"><i className="la la-arrow-right" /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeTestimonials