import React, { Suspense, useEffect } from 'react'
import CoursesDetails from '../Components/CoursesDetails'
import Header from '../Components/Header'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));

const UPSCRegular = () => {
  useEffect(() => {
    const link = document.createElement('link');
    const link2 = document.createElement('link');
    const link3 = document.createElement('link');
    const link4 = document.createElement('link');
    const link5 = document.createElement('link');
    const link6 = document.createElement('link');
    const link7 = document.createElement('link');
    const link8 = document.createElement('link');
    link.rel = 'stylesheet';
    link2.rel = 'stylesheet';
    link3.rel = 'stylesheet';
    link4.rel = 'stylesheet';
    link5.rel = 'stylesheet';
    link6.rel = 'stylesheet';
    link7.rel = 'stylesheet';
    link8.rel = 'stylesheet';
    link.href = "css/plugins.min.css"; // Path to your CSS file
    link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
    link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
    link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
    link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
    link6.href = "css/style.min.css"; // Path to your CSS file
    link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
    link8.href = "css/templete.min.css"; // Path to your CSS file
    link.id = 'home-css'; // Set an ID for easy removal
    link2.id = 'home-css'; // Set an ID for easy removal
    link3.id = 'home-css'; // Set an ID for easy removal
    link4.id = 'home-css'; // Set an ID for easy removal
    link5.id = 'home-css'; // Set an ID for easy removal
    link6.id = 'home-css'; // Set an ID for easy removal
    link7.id = 'home-css'; // Set an ID for easy removal
    link8.id = 'home-css'; // Set an ID for easy removal

    document.head.append(link);
    document.head.append(link2);
    document.head.append(link3);
    document.head.append(link4);
    document.head.append(link5);
    document.head.append(link6);
    document.head.append(link7);
    document.head.append(link8);

    return () => {
        document.getElementById('home-css')?.remove(); // Remove when unmounting
    };
}, []);
const oneYearRegularProgram={
  program:"UPSC Regular Program",
  years:"(1 year)",
  overview:"The UPSC Civil Services Examination (CSE) is one of the most challenging competitive exams in India, requiring a well-structured preparation strategy. This one-year regular program is designed to provide a comprehensive, guided learning experience, combining subject knowledge, current affairs, and exam-specific skills. It ensures holistic preparation for all stages of the exam—Preliminary, Mains, and Interview.",
  heading:"Ideal Features of the Program:",
  features:{
    First:{
      heading: "Comprehensive Coverage",
      details: [
      "Syllabus-Centric Classes: The entire UPSC syllabus for both the Preliminary and Mains examinations is covered systematically, with subject experts delivering lectures on all major topics (General Studies, Optional Subjects, CSAT).",
      "Foundation Focus: A focus on building a strong foundation for beginners while enhancing the knowledge of those already familiar with the syllabus."
      ]
    },
    Second:{
      heading: "Experienced Faculty",
      details: ["Faculty members are seasoned educators, subject matter experts, and professionals with deep insights into the demands of the UPSC exam."]
    },
    Third:{
      heading: "Test Series and Mock Exams",
      details: [
        "Regular Prelims and Mains test series with detailed feedback and analysis to help candidates understand their strengths and weaknesses.",
        "Mock Interviews to simulate the actual interview process and prepare aspirants to handle the UPSC Personality Test confidently."
      ]
    },
    Fourth:{
      heading: "Current Affairs and Editorial Discussions",
      details: ["Daily coverage of current events, news analysis, and in-depth discussions on important topics from national and international newspapers and magazines relevant to the UPSC exam."]
    },
    Fifth:{
      heading:"Answer Writing Practice",
      details:["Emphasis on improving answer writing skills for the Mains exam with daily practice, model answers, and expert evaluation."]
    },
    Sixth:{
      heading:"Study Materials",
      details:["Comprehensive, well-researched study materials covering all relevant topics, including NCERT books, reference materials, and handouts."]
    },
    Seventh:{
      heading:"Doubt Clearing Sessions",
      details:["Dedicated sessions to clarify doubts, promote active learning, and ensure no gaps in understanding."]
    },
    Eighth:{
      heading:"Optional Subject Coaching",
      details:["In-depth classes for optional subjects, with customized resources and mentorship to ensure candidates choose the right subject and excel in it."]
    },
    Ninth:{
    heading:"Mentorship and Guidance",
    details:["Personalized mentorship and counseling to guide aspirants through time management, stress management, and exam strategies tailored to individual learning styles."]
    },
    Tenth:{
      heading:"Peer Learning and Group Discussions",
      details:["Opportunities for peer interaction and group discussions to share insights and diverse perspectives, which are critical for essay writing and interview stages."]
    }
  },
  heading2:"Who Can Enroll?",
  whoCanEnroll:{
    First:{
      heading:"Eligibility:",
      details:[
      "Graduates from any recognized university (final-year students can also apply).",
      "Aspirants with a clear goal of appearing for the UPSC Civil Services Examination in the upcoming year."
      ]
    },
    Second:{
      heading:"Aspirants in Any Stage of Preparation:",
      details:[
      "Beginners who need a structured roadmap.",
      "Intermediate learners who need to strengthen their preparation or focus on specific areas.",
      "Advanced candidates looking for test series, mock exams, and interview practice."
      ]
    },
    Third:{
      heading:"Working Professionals:",
      details:["Flexible weekend or evening batch options may be available for professionals who wish to prepare alongside their job."]
    },
    Fourth:{
      heading:"Repeat Aspirants:",
      details:["Those who have already appeared for the exam but wish to refine their strategy and focus on weak areas."]
    }
  }
}
  return (
    <div>
      <div className="page-wraper">
      <Header upsc="active"/>
      <CoursesDetails foundationimg={"images/upsc-foundation-program.jpg"} Regularimg={"images/upsc-test-series.jpg"}  path1={'/UPSCFoundation'} path2={'/UPSCTestSeries'}  Headingbanner1={"UPSC"} Headingbanner2={"UPSC Regular Program"} foundation={oneYearRegularProgram} HeadingImage={"images/MAIN-SLIDER-4.jpg"}/>
      <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
        <Footer/>
      </Suspense>
      <ScrollToTop/>
      </div>
      <Popup/>
    </div>
  )
}
export default UPSCRegular