import React from 'react'

const DUBBA = () => {
    const collegeDetails = [
        {
          college: "Shaheed Rajguru College of Applied Sciences (W)",
          ur: 23,
          obc: 16,
          sc: 9,
          st: 4,
          minority:"-",
          ews: 6
        },
        {
          college: "Shaheed Sukhdev College Business Studies",
          ur: 45,
          obc: 31,
          sc: 17,
          st: 8,
          minority:"-",
          ews: 12
        }
      ];
      
  return (
    <div>
          <div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <p>NTA will conduct Common University Entrance Test (CUET UG) and (CUET PG) for admission to undergraduate and postgraduate courses offered by all the 45 Central Universities and their affiliated colleges including Delhi University.</p>
                    <p>Bachelor of Business Administration (Financial Investment Analysis) BBA(FIA) is offered by Delhi University for students to develop skills and capabilities to understand the current business challenges and issues by providing an in-depth theoretical understanding. The program is focused on creating financial expertise to contribute to emerging India and provide skills to analyze any financial statement, value any organization or develop financial and risk models for their application.</p>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Eligibility for Admission in BBA(FIA)</a></h2>
                </div>
                <div className="dlab-post-text">
                    <ul>
                        <li>Program Specific Eligibility</li>
                        <li>Candidates must appear in CUET in the following subject combination:</li>
                        <li>Any one Language from List A + Mathematics + SECTION III of CUET (General Test)</li>
                        <li>Merit will be based on the CUET scores obtained from the above-mentioned combination of subjects.</li>
                    </ul>
                </div>
            </div>

            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Colleges Offering BBA(FIA)</a></h2>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>S.No.	</th>
                                <th>College Seats</th>
                                <th>UR</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Minority</th>
                                <th>EWS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    collegeDetails.map(function (obj,index) {
                                        return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{obj.college}</td>
                                                <td>{obj.totalSeats}</td>
                                                <td>{obj.sc}</td>
                                                <td>{obj.st}</td>
                                                <td>{obj.obc}</td>
                                                <td>{obj.minority}</td>
                                                <td>{obj.ews}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <p>The number of seats may vary. Supernumerary seats will be as per University of Delhi norms. Candidates must visit respective college websites for the updated fees structure.</p>
                </div>
               
            </div>
           
        </div>
    </div>
  )
}

export default DUBBA