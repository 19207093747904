import React from 'react'

const Nalsar = () => {
  return (
    <div>
        <div className="class-info">
            <div className="dlab-post-title">
            <h2 className="post-title m-t0"><a>About DoMS IPM at NALSAR</a></h2>
            </div>
            <div className="dlab-post-text">
            <span>The five-year Integrated Program in Management (IPM) at NALSAR is a unique program for young ignited minds aspiring to pursue a career path in Business Management. It is designed as a comprehensive management program for students after class XII. The students would be awarded Bachelor of Business Administration (BBA) and Master of Business Administration (MBA) by NALSAR after successful completion of five years of program Located in a conducive learning environment, NALSAR promises to offer the best of management education embedded with law to produce legally aware managers with critical problem solving ability and skills to manage extremely dynamic business scenariosWith the best-in-class faculty, the IPM program promises a constant dialogue with business leaders, thought leaders, civil-society actors and academicians for providing strong bedrock for the future managers.</span><br /><br />
            <span>Consisting of fifteen terms spread across five years, the first 3 years of the program are aimed at providing strong theoretical underpinnings, conceptual and practical insights in the various areas of philosophy, psychology, fine-arts, mathematics, economics, law and management. The last 2 years are aimed at nurturing holistic transformation from students into future business leaders and managers.</span><br /><br />
            <span>DoMS offers a voluntary exit to the students after successfully completing the programme requirement at the end of 3 years and such students shall be awarded a degree of Bachelor of Business Administration (BBA).</span>
            </div>
            <div className="dlab-post-title">
            <h2 className="post-title m-t0"><a>Eligibility Criteria:</a></h2>
            </div>
            <div className="dlab-post-text">
            <span>The applicants for the Five-year Integrated BBA-MBA program should meet the following eligibility criteria:</span><br />
            <ul>
            <li>Must have completed their Class 10+2 or equivalent examination from a recognized board with a minimum of 60% aggregate marks (50% for SC/ ST candidates);</li>
            <li>Should have valid CLAT (UG) /IPMAT (IIM Indore)/IPMAT (IIM Rohtak)/JIPMAT (IIM Jammu & IIM Bodhgaya)/JEE (Mains) score card.</li>
            </ul>
            <span>Candidates appearing for their 10+2 or equivalent examination can also apply. Candidates who have applied for CLAT (UG) /IPMAT – (IIM Indore)/IPMAT (IIM Rohtak)/JIPMAT (IIM Jammu & IIM Bodhgaya)/JEE (Mains) can apply with the proof of the registration for the said tests. They need to submit the score cards immediately after declaration of the merit list of the said examinations but before the last date to submit the application form.</span>
            </div>
        </div>
        <div className="class-info">
            <div className="dlab-post-title">
            <h2 className="post-title m-t0"><a>Admission Procedure:</a></h2>
            </div>
            <div className="dlab-post-text">
            <span>The admissions to the Integrated BBA-MBA Degree Program will be made considering the CLAT (UG) /IPMAT – (IIM Indore)/IPMAT (IIM Rohtak)/JIPMAT (IIM Jammu & IIM Bodhgaya)/JEE (Mains), Academic credentials, and Personal Interview. Admissions will be offered as per the above-mentioned criteria.</span><br />
            <br />
            <span>Based on merit at the admission test and performance in 10th and 10+2 or equivalent examination, the candidates will be short listed for personal interview. If a candidate appears for more than one test, the candidate may choose to submit the best one as per his/her choice. Alternatively the candidate may upload all the scorecards of which the best will be considered for the preparation of merit list through the process of standardization/normalization.</span><br />
            <br />
            <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Component</th>
                <th>Weightage (%)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Performance in the aforementioned entrance examination	</td>
                    <td>50</td>
                </tr>
                <tr>
                    <td>Performance in 10th	</td>
                    <td>12.5</td>
                </tr>
                <tr>
                    <td>Performance in 10+2	</td>
                    <td>12.5</td>
                </tr>
                <tr>
                    <td>Personal Interview	</td>
                    <td>25</td>
                </tr>
                <tr style={{fontWeight:600}}>
                    <td>Total</td>
                    <td>100</td>
                </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
  )
}
export default Nalsar