import React from 'react'

const IPUCET = () => {
    const sectionDetails = [
        {
          section: "English language and comprehension",
          questions: 25,
          marks: 100,
          weightage: "25.00%"
        },
        {
          section: "General Awareness",
          questions: 25,
          marks: 100,
          weightage: "25.00%"
        },
        {
          section: "Logical and Analytical Ability",
          questions: 25,
          marks: 100,
          weightage: "25.00%"
        },
        {
          section: "Aptitude related to the field of management and communication/ Business Studies",
          questions: 25,
          marks: 100,
          weightage: "25.00%"
        },
        {
          section: "Total",
          questions: 100,
          marks: 400,
          weightage: "-"
        }
      ];
      
  return (
    <div>
         <div>
          <div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <p>Guru Gobind Singh Indraprastha University established in 1998 has around 34 colleges affiliated to it which offer BBA (Bachelor of Business Administration) with specializations like banking and insurance, computer aided management, tour and travel management etc.</p>
                    <p>Admission to these BBA programs is done through a common entrance test popularly known as IPU CET conducted by the university.</p>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Eligibility</a></h2>
                </div>
                <div className="dlab-post-text">
                    <ul>
                        <li>The candidate should have passed class XII of CBSE or its equivalent with a minimum of 50% marks in aggregate</li>
                        <li>Candidate must also have passed English (core or elective or functional) as a subject.</li>
                        <li>Minimum marks for SC /ST/Widows or Wards of Defense Personnel/ Persons with Disability is 45%</li>
                        <li>The candidate should be below 21 Years of age as on 1st August of the admission year.</li>
                        <li>Students awaiting their class XII result can also appear for IPUCET.</li>
                    </ul>
                </div>
            </div>

            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Test Pattern</a></h2>
                    <p>The entrance test is conducted as a computer-based exam with a total duration of 150 minutes. There are 100 questions in total, with each correct answer earning four marks and each incorrect answer resulting in a penalty of one mark.</p>
                    <p>The overall selection criteria is the test score only.</p>
                </div>
                
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <p>Following is the section wise split of the paper:</p>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Section	</th>
                                <th>Number of Questions</th>
                                <th>Maximum Marks</th>
                                <th>Percentage Weightage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sectionDetails.map(function (obj,index) {
                                        return (
                                            <tr key={index}>
                                                <td>{obj.section}</td>
                                                <td>{obj.questions}</td>
                                                <td>{obj.marks}</td>
                                                <td>{obj.weightage}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
    </div>
  )
}

export default IPUCET