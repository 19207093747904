import React from 'react'

const NLATComponent = () => {
    return (
        <div>
            <div>
                <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 className="post-title m-t0"><a >Exam Eligibility, Test Pattern, Syllabus, and more</a></h2>
                    </div>
                    <div className="dlab-post-text">
                        <p>NMIMS (deemed to be a University) conducts university-level entrances every year for different categories of courses ranging from engineering to law. NMIMS LAT or NLAT stands for national law aptitude test, is conducted by NMIMS for its law courses at the undergraduate level 5-year integrated programs, namely</p>
                        <p>The five-year programs were launched by the Kirti Mehta School of Law at NMIMS, and have the same structure as the programs at NLUs. This article will cover details about NLAT 2024's eligibility criteria, exam pattern, syllabus, seat intake, reservation criteria, and more. So, let's jump right in.</p>
                    </div>
                </div>

                <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 className="post-title m-t0"><a >NLAT 2024: Important Dates</a></h2>
                    </div>
                    <div className="dlab-post-text">
                        <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                                    <thead style={{ backgroundColor: "#3e4095" }} >
                                      <tr>
                                      <th>Dates</th>
                                      <th>Upcoming Exam Dates</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td style={{ fontWeight: "600" }} colSpan={2}>PHASE 1</td></tr>
                                        <tr>
                                            <td>06 Dec 23 - 10 Mar 24</td>
                                            <td>NLAT 2024 Application Process</td>
                                        </tr>
                                        <tr>
                                            <td>01 Jan 24 - 15 Mar 24</td>
                                            <td>NLAT 2024 Exam </td>
                                        </tr>
                                        <tr>
                                            <td>04 Apr 24</td>
                                            <td>NLAT 2024 Merit List </td>
                                        </tr>
                                        <tr>
                                            <td>06 Apr 24 - 16 Apr 24</td>
                                            <td>NLAT 2024 Counselling</td>
                                        </tr>
                                        <tr><td style={{ fontWeight: "600" }} colSpan={2}>PHASE 2</td></tr>
                                        <tr>
                                            <td>11 Jan 24 - 20 Mar 24</td>
                                            <td>N2024 Application Process</td>
                                        </tr>
                                        <tr>
                                            <td>13 Jun 24</td>
                                            <td>NLAT 2024 Exam </td>
                                        </tr>
                                        <tr>
                                            <td>04 Apr 24</td>
                                            <td>NLAT 2024 Merit List </td>
                                        </tr>
                                        <tr>
                                            <td>15 Jun 24 - 29 Jun 24</td>
                                            <td>NLAT 2024 Counselling</td>
                                        </tr>
                                        {
                                                                         /* <tr>
                                                                             <td>English Language</td>
                                                                             <td>22-26 questions, or roughly 20% of the paper</td>
                                                                         </tr>
                                                                         <tr>
                                                                             <td>Current Affairs including General Knowledge</td>
                                                                             <td>28-32 questions, or roughly 25% of the paper</td>
                                                                         </tr>
                                                                         <tr>
                                                                             <td>Legal Reasoning</td>
                                                                             <td>28-32 questions, or roughly 25% of the paper</td>
                                                                         </tr>
                                                                         <tr>
                                                                             <td>Logical Reasoning</td>
                                                                             <td>22-26 questions, or roughly 20% of the paper</td>
                                                                         </tr>
                                                                         <tr>
                                                                             <td>Quantitative Techniques</td>
                                                                             <td>10-14 questions, or roughly 10% of the paper</td>
                                                                         </tr> */}
                                    </tbody>          
                            </table>
                        </div>
                    </div>
                </div>
                <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 className="post-title m-t0"><a >NLAT 2024: Exam Pattern</a></h2>
                    </div>
                    <div className="dlab-post-text">
                        <div className='table-responsive' >
                            <table className='table table-hover table-bordered  custom-colortable' >
                                    <thead style={{ backgroundColor: "#3e4095" }} >
                                       <tr>
                                       <th>Particulars</th>
                                       <th>Details</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Sections</td>
                                            <td>
                                                1. Verbal Reasoning<br />
                                                2. GK<br />
                                                3. Quantitative Reasoning<br />
                                                4. Logical Reasoning<br />
                                                5. Legal Reasoning<br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>No of questions</td>
                                            <td>150 (30 questions each)</td>
                                        </tr>
                                        <tr>
                                            <td>Total marks</td>
                                            <td>150 (30 marks each)</td>
                                        </tr>
                                        <tr>
                                            <td>Negative marking</td>
                                            <td>No negative marketing</td>
                                        </tr>
                                        <tr>
                                            <td>Total time</td>
                                            <td>120 minutes</td>
                                        </tr>
                                        <tr>
                                            <td>Question pattern</td>
                                            <td>Computer-based objective-type MCQ questions</td>
                                        </tr>
                                        <tr>
                                            <td>Admission procedure</td>
                                            <td>Students are given admission based on their NLAT and/or CLAT scores</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </div>
                <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 style={{ fontSize: "25px", fontWeight: "600" }} className="post-title m-b5"><a >NLAT 2024: Exam Eligibility</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <p>To participate in the NLAT 2024, candidates must meet the qualifying conditions listed below.</p>
                    <ul className='tick-list' >
                        <li>Candidates must pass Class 12 or equivalent from a recognized board in any stream with a minimum of 50% aggregate.</li>
                        <li>Candidates who have finished Class 12 from the International Baccalaureate (IB) board shall be either IB certificate/DP issued with a minimum of 24 total credits having passed 3 HL and 3 SL mandatorily from any stream.</li>
                        <li>If the marksheet provided by the appropriate board is in percentile, the real aggregate percentage of all the subjects in which the candidate has appeared will be taken into account.</li>
                        <li>Those candidates who have not received a grade or have failed any of the subjects listed on their marksheet are unable to apply for the test.</li>
                        <li>Candidates taking the final Class 12 examinations are also eligible to apply. However, such individuals must meet the conditions listed above within the time frame specified.</li>
                    </ul>
                    </div>
                </div>
                <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 style={{ fontSize: "25px", fontWeight: "600" }} className="post-title m-b5"><a >NLAT 2024: Registration Fee</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <p>The NLAT registration fee depends on the number of retakes and the schools the candidate is applying for. The application fee is mentioned below.</p>
                    <ul className='tick-list' >
                        <li>1 attempt:₹ 2000 (total amount)</li>
                        <li>2nd attempt: + ₹2000 → ₹4000 (total amount)</li>
                        <li>3rd attempt: + ₹2000 → ₹6000 (total amount)</li>
                    </ul>
                    </div>
                </div>
                <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 style={{ fontSize: "25px", fontWeight: "600" }} className="post-title m-b5"><a >NLAT 2024: Application Process</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <p>To register for NLAT 2024, follow the steps given below:</p>
                    <ul className='tick-list' >
                        <li>Visit the official website of NMIMS.</li>
                        <li>Click on the “Apply Now” button and create an account.  </li>
                        <li>Candidates can complete registration by picking the appropriate test category from NMIMS-NPAT / NMIMSCET / NMIMS-LAT; and campus preferences.</li>
                        <li>One can select multiple test categories / multiple schools and multiple program preferences. They can also select multiple attempts for each category.</li>
                        <li>The registration fees depend on the number of schools selected for each test category and the total retakes.</li>
                        <li>Once the fee is paid, candidates can schedule their test dates and retakes. 1 main and 2 retakes for each of the categories are permitted.</li>
                        <li>Candidates should not register multiple times or in the same exam category else it will lead to blocking the candidate from admission.</li>
                        <li>After successful registration and payment, the applicant will be able to schedule the examinations by selecting the test date/s and test center.</li>
                        <li>Test dates/center availability will be subject to its capacity and will be done on a first come first serve basis.</li>
                        <li>Finish your registration by scheduling all tests.</li>
                    </ul>
                    </div>
                </div>
                <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 style={{ fontSize: "25px", fontWeight: "600" }} className="post-title m-b5"><a >Required Documents for Application</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <p>The candidates must upload the following documents in the application form:</p>
                    <ul className='tick-list' >
                        <li>Two Passport-size photographs of the candidate.</li>
                        <li>Scanned image of the signature (on white paper with a black ink pen).</li>
                    </ul>
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Scanned Images</th>
                                <th>Size</th>
                                <th>Dimension</th>
                                <th>Format</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Photograph</td>
                                    <td>20KB - 50KB</td>
                                    <td>200 x 230 Pixels</td>
                                    <td>JPG or JPEG</td>
                                    </tr>
                                    <tr>
                                    <td>Signature</td>
                                    <td>10KB - 20KB</td>
                                    <td>140 x 60 Pixels</td>
                                    <td>JPG or JPEG</td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 style={{ fontSize: "45px", fontWeight: "600" }} className="post-title m-b5"><a >NLAT 2024: Syllabus</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <p>As mentioned in the exam pattern before, the NLAT exam consists of 5 sections in total. The important topics for each section are highlighted below.</p>
                    <div className="class-info">
                    <div  className="dlab-post-title ">
                        <h2 style={{ fontSize: "25px", fontWeight: "600" }} className="post-title m-b5"><a >NLAT Syllabus: Verbal Reasoning</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table style={{ backgroundColor: "#3e4095" }} className='table table-hover table-bordered  custom-colortable' >
                            <thead>
                               <tr>
                               <th>One Word Substitution</th>
                                <th>Jumbled Sentence</th>
                                <th>Synonyms and Antonyms</th>
                               </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>A sentence or Phrase Improvement</td>
                                    <td>Paragraph Completion</td>
                                    <td>Idioms and Phrases</td>
                                    </tr>
                                    <tr>
                                    <td>Sentence Correction</td>
                                    <td>Active and Passive Voice</td>
                                    <td>Direct & Indirect Speech</td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                    <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 style={{ fontSize: "25px", fontWeight: "600" }} className="post-title m-b5"><a >NLAT Syllabus: General Knowledge</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                            <tr>
                                <th>Politics</th>
                                <th>Event Dates</th>
                                <th>India: An Overview</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>History</td>
                                    <td>Current Affairs</td>
                                    <td>Books and Authors</td>
                                    </tr>
                                    <tr>
                                    <td>Geography</td>
                                    <td>Sports & Games</td>
                                    <td>Famous Personalities</td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                    <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 style={{ fontSize: "25px", fontWeight: "600" }} className="post-title m-b5"><a >NLAT Syllabus: Quantitative Reasoning</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Graphs</th>
                                <th>Mensuration</th>
                                <th>Statistical Estimation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Basic Algebra</td>
                                    <td>Numerical Information</td>
                                    <td>Ratio and Proportion</td>
                                    </tr>
                                    <tr>
                                    <td>Geography</td>
                                    <td>Sports & Games</td>
                                    <td>Famous Personalities</td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                    <div className="class-info">
                    <div className="dlab-post-title ">
                        <h2 style={{ fontSize: "25px", fontWeight: "600" }} className="post-title m-b5"><a >NLAT Syllabus: Legal Reasoning</a></h2>
                    </div>
                    <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                               <tr>
                               <th>Torts</th>
                                <th>Criminal Law</th>
                                <th>General Legal Principles</th>
                               </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Contracts</td>
                                    <td>International Law</td>
                                    <td>Intellectual Property Law</td>
                                    </tr>
                                    <tr>
                                    <td>Family Law </td>
                                    <td>Constitutional Law</td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NLATComponent