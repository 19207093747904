import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/auth';
const firebaseConfig = {
    apiKey: "AIzaSyBOqkhRh1MRjLN-oHnMZQ1TD7K_ImM_f-8",
    authDomain: "geniusias.firebaseapp.com",
    projectId: "geniusias",
    storageBucket: "geniusias.appspot.com",
    messagingSenderId: "302132554954",
    appId: "1:302132554954:web:60a6ec2157a62123cc1833"
}
const fire=firebase.initializeApp(firebaseConfig);
export default fire.storage().ref()
export const database= fire.database().ref()
export const Auth=fire.auth()