import React from 'react'
import QuestionAnswer5 from './QuestionAnswer5'

const Jipmat = () => {
  return (
    <div>
        <div className="class-info">
            <div className="dlab-post-text">
            <span>The Joint Integrated Programme in Management Admission Test (JIPMAT) is a National Level Entrance Examination for admission to the 5-Year Integrated Program in Management at IIM Bodh Gaya and IIM Jammu for the Academic year. The National Testing Agency (NTA) will be conducting JIPMAT as per the following details:</span>
            </div>
        </div>
        <QuestionAnswer5/> 
    </div>
  )
}

export default Jipmat
