import React,{ useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Geniusias from './Geniusias'
import SOH from './SOH'
import UPSC from './UPSC'
import HCS from './HCS'
import CLAT from './CLAT'
import CUET from './CUET'
import ClassEleventh from './ClassEleventh'
import UPSCFoundation from './UPSCFoundation'
import UPSCRegular from './UPSCRegular'
import HCSFoundation from './HCSFoundation'
import HCSRegular from './HCSRegular'
import UPSCTestSeries from './UPSCTestSeries'
import HCSTestSeries from './HCSTestSeries'
import IPM from './IPM'
import Contact from './Contact'
import UserBlog from './UserBlog'
import UserBlogDetails from './UserBlogDetails'
import BlogContext from './BlogContext'
import { database } from '../Firebase'
const UserRoute = () => {
const[loader,setloader]=useState(false)
const[data,setdata]=useState(null)
const[data1,setdata1]=useState(null)
useEffect(()=>{
  setloader(true)
  database.child("Blogs").orderByChild("Status").equalTo("Active").on("value",function(snap){
    if(snap.val()) return setdata(snap.val())
    else return setdata(null)
  })
  database.child("Reviews").on("value",function(snap){
    if(snap.val()) return setdata1(snap.val())
    else return setdata1(null)
  })
  setloader(false)
},[])
  return (
    <div>
        <BlogContext.Provider value={{"Fetch":data,"load":loader,"FetchReviews":data1}}>
        <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/GeniusIAS' element={<Geniusias/>} />
        <Route path='/SchoolofHumanities' element={<SOH/>} />
        <Route path='/UPSC' element={<UPSC/>} />
        <Route path='/UPSCFoundation' element={<UPSCFoundation/>} />
        <Route path='/UPSCRegular' element={<UPSCRegular/>} />
        <Route path='/UPSCTestSeries' element={<UPSCTestSeries/>} />
        <Route path='/HCS' element={<HCS/>} />
        <Route path='/HCSFoundation' element={<HCSFoundation/>} />
        <Route path='/HCSRegular' element={<HCSRegular/>} />
        <Route path='/HCSTestSeries' element={<HCSTestSeries/>} />
        <Route path='/CLAT' element={<CLAT/>} />
        <Route path='/CUET' element={<CUET/>} />
        <Route path='/Class11th' element={<ClassEleventh/>} />
        <Route path='/IPM' element={<IPM/>} />
        <Route path='/Blog' element={<UserBlog/>} />
        <Route path='/BlogDetails' element={<UserBlogDetails/>} />
        <Route path='/Login' element={<Contact/>} />
        </Routes>
        </BlogContext.Provider>
    </div>
  )
}

export default UserRoute
