import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { database } from '../Firebase';

const Contactus = () => {
const[obj,setobj]=useState({Course:"None"})
const[haserror,sethaserror]=useState(false)
const d=new Date()
const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
function set(event){
    sethaserror(false)
    setobj({...obj,[event.target.name]:event.target.value,"Date":date})
}
function NameChange(event){
    const name = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    setobj({...obj,"Name":name,"Date":date});
    sethaserror(false)
}
function PhoneNumberChange(event){
    const phone = event.target.value.trim().replace(/[^\d-]/g, '');
    setobj({...obj,"Phone":phone,"Date":date});
    sethaserror(false)
}
function Courses(event){
setobj({...obj,"Course":event.target.value})
sethaserror(false)
}
function Submit(e){
    try {
        e.preventDefault()
        if(!obj.Name || !obj.Phone || !obj.Message) return toast.warn("Field is Empty")

        if(obj.Course==="None") return toast.warn("Please select the course")
        
        database.child("Contacts").push(obj,err=>{
            if(err) throw err;
            else{
                setobj({"Course":"None"})
                toast.success("Thank you for Contacting us. We will contact you soon.")
                return setTimeout(()=>window.scrollTo(0,0),2000)
            } 
        })  
        setobj({"Course":"None"})      
    } catch (error) {
        sethaserror(true)
        return toast.error("Something went wrong!!!")
    }
}
  return (
    <div>
        <ToastContainer position='top-center'/>
        <div id="contact-us" className="section-full bg-white content-inner-2 enquiry-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27942.91992390776!2d76.57173653920695!3d28.902359783149862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d858f9f405e3b%3A0xe7da85a3a54e45ea!2sGenius%20IAS%20-%20Best%20UPSC%20coaching%20in%20Rohtak%20%2F%20Best%20HCS%20coaching%20in%20Rohtak!5e0!3m2!1sen!2sin!4v1727034556664!5m2!1sen!2sin" style={{ height: '100%', width: '100%', border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                                </div>
                                <div className="col-md-6">
                                    <div className="enquiry-bx">
                                        <div className="head">
                                            <h2 className="title">Contact Us</h2>
                                            <p>Need Help? We're Here for You. Please fill the below form.</p>
                                        </div>
                                        <div className="dzFormMsg" />
                                        <form method="post" className="dzForm" action="https://smartclass.dexignzone.com/xhtml/script/contact.php">
                                            <input type="hidden" defaultValue="Contact" name="dzToDo" />
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="Name" value={obj.Name?obj.Name:""} onChange={NameChange} type="text" required className="form-control" placeholder="Enter your Name" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="Phone" value={obj.Phone?obj.Phone:""} onChange={PhoneNumberChange} type="tel" className="form-control" required placeholder="Enter your Mobile Number" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <select value={obj.Course?obj.Course:"None"} onChange={Courses} className='form-control' style={{padding:"2px 10px",height:"50px"}}>
                                                                <option value="None">Select your Course</option>
                                                                <option value="UPSC">UPSC</option>
                                                                <option value="HCS">HCS</option>
                                                                <option value="CLAT">CLAT</option>
                                                                <option value="CUET">CUET</option>
                                                                <option value="IPM">IPM</option>
                                                                <option value="Class 11th">Class 11th</option>
                                                                <option value="Class 12th">Class 12th</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <textarea name="Message" value={obj.Message?obj.Message:""} onChange={set} rows={4} className="form-control" required placeholder="Your Message..." />
                                                        </div>
                                                    </div>
                                                    <p style={{color:"red"}} className='text-center'>{haserror && "Something went wrong"}</p>
                                                </div>
                                                <div className="col-lg-12 text-center">
                                                    <button name="submit" type="submit" onClick={Submit} value="Submit" className="btn">
                                                        <span>Submit</span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
        </div>
    </div>
  )
}

export default Contactus