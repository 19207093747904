import React, { useEffect, useState } from 'react'
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { Routes, Route } from "react-router-dom";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Blogs from './pages/blog/Blogs';
import Addnewblog from './pages/addnewblog/Addnewblog';
import Context from './context/BlogsContext';
import { database } from '../Firebase';
import BlogDetail from './pages/blog-detail/BlogDetail';
import Addcurrentaffair from './pages/addcurrentaffair/Addcurrentaffair';
import Notifications from './pages/notification/Notifications';
import Telegrams from './pages/telegram/Telegrams';
import Counselling from './pages/counselling/Counselling';
import Review from './pages/reviews/Review';
import AddReviews from './pages/addReviews/AddReviews';
const Main = () => {
  const { darkMode } = useContext(DarkModeContext)
  const[data,setdata]=useState(null)
  const userInputs = [
    {
      id: 1,
      label: "Username",
      type: "text",
      placeholder: "john_doe",
    },
    {
      id: 2,
      label: "Name and surname",
      type: "text",
      placeholder: "John Doe",
    },
    {
      id: 3,
      label: "Email",
      type: "mail",
      placeholder: "john_doe@gmail.com",
    },
    {
      id: 4,
      label: "Phone",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: 5,
      label: "Password",
      type: "password",
    },
    {
      id: 6,
      label: "Address",
      type: "text",
      placeholder: "Elton St. 216 NewYork",
    },
    {
      id: 7,
      label: "Country",
      type: "text",
      placeholder: "USA",
    },
  ];
  // const productInputs = [
  //   {
  //     id: 1,
  //     label: "Title",
  //     type: "text",
  //     placeholder: "Apple Macbook Pro",
  //   },
  //   {
  //     id: 2,
  //     label: "Description",
  //     type: "text",
  //     placeholder: "Description",
  //   },
  //   {
  //     id: 3,
  //     label: "Category",
  //     type: "text",
  //     placeholder: "Computers",
  //   },
  //   {
  //     id: 4,
  //     label: "Price",
  //     type: "text",
  //     placeholder: "100",
  //   },
  //   {
  //     id: 5,
  //     label: "Stock",
  //     type: "text",
  //     placeholder: "in stock",
  //   },
  // ];
  function FetchData(){
    database.child("Blogs").on("value",(snap)=>{
      if(snap.val()) return setdata(snap.val())
      else return setdata(null)
    })
  }
  useEffect(()=>{
    FetchData()
  },[])
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <Context.Provider value={{"fetchdata":data}}>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="users">
              <Route index element={<List />} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="counselling">
              <Route index element={<Counselling />} />
            </Route>
            <Route path="Blogs">
              <Route index element={<Blogs/>} />
              <Route path=":productId" element={<BlogDetail />} />
              <Route
                path="new"
                element={<Addnewblog title="Add New Blogs" />}
              />
            </Route>
            <Route path="CurrentAffair">
              <Route index element={<Addcurrentaffair title="Add Current Affairs"/>} />
            </Route>
            <Route path="Reviews">
              <Route index element={<Review/>} />
              <Route path='new' element={<AddReviews title="Add Student Reviews"/>} />
            </Route>
            <Route path="Notifications">
              <Route index element={<Notifications title="Create Notifications"/>} />
            </Route>
            <Route path="Telegram">
              <Route index element={<Telegrams/>} />
            </Route>
          </Route>
        </Routes>
      </Context.Provider>
    </div>
  )
}

export default Main