import React from 'react'
import { Link } from 'react-router-dom'

const Ventures = () => {
    return (
        <div  >
            <div  style={{ backgroundImage: 'url(images/background/bg1.jpg)' }} className="content-block">
                <div style={{ paddingTop: 0 }} className="section-full content-inner-2 bg-gray" />
                <div  className="container">
                    <div className="row " >

                        <div className="col-lg-6 col-md-12 col-sm-12 col-12  ">
                            <div className='borderddiv' >
                                <div className="action-box2">
                                    <Link to={'/GeniusIAS'}><img className="imges" src="./images/logo-wesite-banner.png"  /></Link>
                                </div>
                                <div className="action-upper cuupper">
                                    <button><Link style={{ color: 'white' }} to={'/UPSC'}> UPSC</Link></button>
                                    <button><Link style={{ color: 'white' }} to={'/HCS'}>HCS</Link></button>
                                </div>
                                <div style={{ marginTop: "40px" }} className="action-lower">
                                    <button><Link to={"/UPSCTestSeries"} style={{ color: 'white' }} > Test Series</Link></button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
                            <div className='borderddiv' >
                                <div className="action-box2">
                                    <Link to={'/SchoolofHumanities'} ><img className="imges" src="./images/soh-logo-wesite.png"  /></Link>
                                </div>
                                <div className="action-upper">
                                    <button><Link style={{ color: 'white' }} to={'/Class11th'}>Class 11</Link></button>
                                    <button><Link style={{ color: 'white' }} to={'/Class11th'}>Class 12</Link></button>
                                </div>
                                <div className="action-upper" style={{ marginBottom: "30px" }}>
                                    <button><Link style={{ color: 'white' }} to={'/CLAT'}>CLAT</Link></button>
                                    <button><Link style={{ color: 'white' }} to={'/CUET'}>CUET</Link></button>
                                </div>

                            </div>
                        </div>
                        
                        

                    </div>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            
        </div>
    )
}

export default Ventures