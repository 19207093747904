import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header'
import Courses from '../Components/Courses'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));

const UPSC = () => {
  useEffect(() => {
    const link = document.createElement('link');
    const link2 = document.createElement('link');
    const link3 = document.createElement('link');
    const link4 = document.createElement('link');
    const link5 = document.createElement('link');
    const link6 = document.createElement('link');
    const link7 = document.createElement('link');
    const link8 = document.createElement('link');
    link.rel = 'stylesheet';
    link2.rel = 'stylesheet';
    link3.rel = 'stylesheet';
    link4.rel = 'stylesheet';
    link5.rel = 'stylesheet';
    link6.rel = 'stylesheet';
    link7.rel = 'stylesheet';
    link8.rel = 'stylesheet';
    link.href = "css/plugins.min.css"; // Path to your CSS file
    link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
    link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
    link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
    link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
    link6.href = "css/style.min.css"; // Path to your CSS file
    link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
    link8.href = "css/templete.min.css"; // Path to your CSS file
    link.id = 'home-css'; // Set an ID for easy removal
    link2.id = 'home-css'; // Set an ID for easy removal
    link3.id = 'home-css'; // Set an ID for easy removal
    link4.id = 'home-css'; // Set an ID for easy removal
    link5.id = 'home-css'; // Set an ID for easy removal
    link6.id = 'home-css'; // Set an ID for easy removal
    link7.id = 'home-css'; // Set an ID for easy removal
    link8.id = 'home-css'; // Set an ID for easy removal

    document.head.append(link);
    document.head.append(link2);
    document.head.append(link3);
    document.head.append(link4);
    document.head.append(link5);
    document.head.append(link6);
    document.head.append(link7);
    document.head.append(link8);

    return () => {
        document.getElementById('home-css')?.remove(); // Remove when unmounting
    };
}, []);
  const Qna = [
    {
      "question": "What is UPSC CSE?",
      "answer": "The UPSC Civil Services Examination (CSE) is a national-level competitive exam conducted by the Union Public Service Commission to recruit candidates for civil services like the Indian Administrative Service (IAS), Indian Police Service (IPS), Indian Foreign Service (IFS), and others."
    },
    {
      "question": "What are the three stages of the UPSC Civil Services Exam?",
      "answer": "The exam is conducted in three stages:- \n1). Preliminary Examination (Prelims) – Objective-type exam.\n2). Main Examination (Mains) – Descriptive-type exam.\n3). Personality Test/Interview."
    },
    {
      "question": "How many attempts are allowed for UPSC CSE?",
      "answer": "1). General Category: 6 attempts.\n2). OBC Category: 9 attempts.\n3). SC/ST Category: Unlimited attempts until reaching the age limit.\n4). PwD Category: 9 attempts (General and OBC); unlimited for SC/ST."
    },
    {
      "question": "What is the minimum educational qualification required to apply for UPSC CSE?",
      "answer": "A candidate must hold a bachelor’s degree from a recognized university or have an equivalent qualification."
    },
    {
      "question": "Is the Preliminary exam's score counted in the final merit list?",
      "answer": "No. Prelims is only a qualifying stage. Only the Main Exam and Interview marks are considered for final ranking."
    }
  ]
  return(
    <div>
      <div className="page-wraper">
      <Header upsc="active"/>
      <div className="page-content">
      <Courses Headingbanner1={"UPSC"} Headingbanner2={"(Union Public Service Commission)"} foundationimg={"images/upsc-foundation-program.jpg"} Regularimg={"images/upsc-regular-program.jpg"} testSeriesimg={"images/upsc-test-series.jpg"} path1={'/UPSCFoundation'} path2={'/UPSCRegular'} path3={'/UPSCTestSeries'} QNAQuestions={Qna} MainContent={"The UPSC Prelims Exam is an offline examination that consists of Objective Multiple Choice Questions. The test is divided into two papers: General Studies (Paper I) and the Civil Services Aptitude Test (CSAT) (Paper II). The General Studies paper is two hours long, consisting of 100 questions, with each question carrying two marks. The CSAT paper is also two hours long and includes 80 questions, with each question worth 2.5 marks."} Heading={"UPSC (Union Public Service Commission)"} path={'images/MAIN-SLIDER-6.jpg'}/>
      </div>
      </div>
      <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
        <Footer/>
      </Suspense>
      <ScrollToTop/>
      <Popup/>
    </div>
  )
}
export default UPSC