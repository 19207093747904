import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header'
import TestSeriesDetails from '../Components/TestSeriesDetails'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));

const UPSCTestSeries = () => {
    useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        const link3 = document.createElement('link');
        const link4 = document.createElement('link');
        const link5 = document.createElement('link');
        const link6 = document.createElement('link');
        const link7 = document.createElement('link');
        const link8 = document.createElement('link');
        link.rel = 'stylesheet';
        link2.rel = 'stylesheet';
        link3.rel = 'stylesheet';
        link4.rel = 'stylesheet';
        link5.rel = 'stylesheet';
        link6.rel = 'stylesheet';
        link7.rel = 'stylesheet';
        link8.rel = 'stylesheet';
        link.href = "css/plugins.min.css"; // Path to your CSS file
        link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
        link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
        link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
        link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
        link6.href = "css/style.min.css"; // Path to your CSS file
        link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
        link8.href = "css/templete.min.css"; // Path to your CSS file
        link.id = 'home-css'; // Set an ID for easy removal
        link2.id = 'home-css'; // Set an ID for easy removal
        link3.id = 'home-css'; // Set an ID for easy removal
        link4.id = 'home-css'; // Set an ID for easy removal
        link5.id = 'home-css'; // Set an ID for easy removal
        link6.id = 'home-css'; // Set an ID for easy removal
        link7.id = 'home-css'; // Set an ID for easy removal
        link8.id = 'home-css'; // Set an ID for easy removal

        document.head.append(link);
        document.head.append(link2);
        document.head.append(link3);
        document.head.append(link4);
        document.head.append(link5);
        document.head.append(link6);
        document.head.append(link7);
        document.head.append(link8);

        return () => {
            document.getElementById('home-css')?.remove(); // Remove when unmounting
        };
    }, []);
    const upscTestSeries = {
        program: "UPSC CSE 2025 Prelims Test Series Schedule",
        fees: "Test series Fee: - 2500 only (including GST)",
        heading: "24 PYQ Mock + Analysis Schedule",
        heading2: "20 GS + CSAT Mock & Analysis Schedule",
        Table1: [
            { date: "2024-12-01", day: "Sunday", test: "UPSC CSE PYQ 2001" },
            { date: "2024-12-03", day: "Tuesday", test: "UPSC CSE PYQ 2002" },
            { date: "2024-12-05", day: "Thursday", test: "UPSC CSE PYQ 2003" },
            { date: "2024-12-07", day: "Saturday", test: "UPSC CSE PYQ 2004" },
            { date: "2024-12-09", day: "Monday", test: "UPSC CSE PYQ 2005" },
            { date: "2024-12-11", day: "Wednesday", test: "UPSC CSE PYQ 2006" },
            { date: "2024-12-13", day: "Friday", test: "UPSC CSE PYQ 2007" },
            { date: "2024-12-15", day: "Sunday", test: "UPSC CSE PYQ 2008" },
            { date: "2024-12-17", day: "Tuesday", test: "UPSC CSE PYQ 2009" },
            { date: "2024-12-19", day: "Thursday", test: "UPSC CSE PYQ 2010" },
            { date: "2024-12-21", day: "Saturday", test: "UPSC CSE PYQ 2011" },
            { date: "2024-12-23", day: "Monday", test: "UPSC CSE PYQ 2012" },
            { date: "2024-12-25", day: "Wednesday", test: "UPSC CSE PYQ 2013" },
            { date: "2024-12-27", day: "Friday", test: "UPSC CSE PYQ 2014" },
            { date: "2024-12-29", day: "Sunday", test: "UPSC CSE PYQ 2015" },
            { date: "2024-12-31", day: "Tuesday", test: "UPSC CSE PYQ 2016" },
            { date: "2025-01-02", day: "Thursday", test: "UPSC CSE PYQ 2017" },
            { date: "2025-01-04", day: "Saturday", test: "UPSC CSE PYQ 2018" },
            { date: "2025-01-06", day: "Monday", test: "UPSC CSE PYQ 2019" },
            { date: "2025-01-08", day: "Wednesday", test: "UPSC CSE PYQ 2020" },
            { date: "2025-01-10", day: "Friday", test: "UPSC CSE PYQ 2021" },
            { date: "2025-01-12", day: "Sunday", test: "UPSC CSE PYQ 2022" },
            { date: "2025-01-14", day: "Tuesday", test: "UPSC CSE PYQ 2023" },
            { date: "2025-01-16", day: "Thursday", test: "UPSC CSE PYQ 2024" },
        ],
        Table2: [
            { date: "2025-01-25", day: "Saturday", test: "Mock 1 (GS + CSAT)" },
            { date: "2025-01-26", day: "Sunday", test: "Mock 1 Analysis" },
            { date: "2025-02-01", day: "Saturday", test: "Mock 2 (GS + CSAT)" },
            { date: "2025-02-02", day: "Sunday", test: "Mock 2 Analysis" },
            { date: "2025-02-08", day: "Saturday", test: "Mock 3 (GS + CSAT)" },
            { date: "2025-02-09", day: "Sunday", test: "Mock 3 Analysis" },
            { date: "2025-02-15", day: "Saturday", test: "Mock 4 (GS + CSAT)" },
            { date: "2025-02-16", day: "Sunday", test: "Mock 4 Analysis" },
            { date: "2025-02-22", day: "Saturday", test: "Mock 5 (GS + CSAT)" },
            { date: "2025-02-23", day: "Sunday", test: "Mock 5 Analysis" },
            { date: "2025-03-01", day: "Saturday", test: "Mock 6 (GS + CSAT)" },
            { date: "2025-03-02", day: "Sunday", test: "Mock 6 Analysis" },
            { date: "2025-03-08", day: "Saturday", test: "Mock 7 (GS + CSAT)" },
            { date: "2025-03-09", day: "Sunday", test: "Mock 7 Analysis" },
            { date: "2025-03-15", day: "Saturday", test: "Mock 8 (GS + CSAT)" },
            { date: "2025-03-16", day: "Sunday", test: "Mock 8 Analysis" },
            { date: "2025-03-22", day: "Saturday", test: "Mock 9 (GS + CSAT)" },
            { date: "2025-03-23", day: "Sunday", test: "Mock 9 Analysis" },
            { date: "2025-03-29", day: "Saturday", test: "Mock 10 (GS + CSAT)" },
            { date: "2025-03-30", day: "Sunday", test: "Mock 10 Analysis" },
            { date: "2025-04-05", day: "Saturday", test: "Mock 11 (GS + CSAT)" },
            { date: "2025-04-06", day: "Sunday", test: "Mock 11 Analysis" },
            { date: "2025-04-12", day: "Saturday", test: "Mock 12 (GS + CSAT)" },
            { date: "2025-04-13", day: "Sunday", test: "Mock 12 Analysis" },
            { date: "2025-04-16", day: "Wednesday", test: "Mock 13 (GS + CSAT)" },
            { date: "2025-04-17", day: "Thursday", test: "Mock 13 Analysis" },
            { date: "2025-04-19", day: "Saturday", test: "Mock 14 (GS + CSAT)" },
            { date: "2025-04-20", day: "Sunday", test: "Mock 14 Analysis" },
            { date: "2025-04-26", day: "Saturday", test: "Mock 15 (GS + CSAT)" },
            { date: "2025-04-27", day: "Sunday", test: "Mock 15 Analysis" },
            { date: "2025-05-03", day: "Saturday", test: "Mock 16 (GS + CSAT)" },
            { date: "2025-05-04", day: "Sunday", test: "Mock 16 Analysis" },
            { date: "2025-05-07", day: "Wednesday", test: "Mock 17 (GS + CSAT)" },
            { date: "2025-05-08", day: "Thursday", test: "Mock 17 Analysis" },
            { date: "2025-05-10", day: "Saturday", test: "Mock 18 (GS + CSAT)" },
            { date: "2025-05-11", day: "Sunday", test: "Mock 18 Analysis" },
            { date: "2025-05-14", day: "Wednesday", test: "Mock 19 (GS + CSAT)" },
            { date: "2025-05-15", day: "Thursday", test: "Mock 19 Analysis" },
            { date: "2025-05-17", day: "Saturday", test: "Mock 20 (GS + CSAT)" },
            { date: "2025-05-18", day: "Sunday", test: "Mock 20 Analysis" }
        ]
    }
    return (
    <div>
        <div className="page-wraper">
        <Header upsc="active"/>
        <TestSeriesDetails Headingbanner1={"UPSC"} Headingbanner2={"UPSC CSE 2025 Prelims Test Series Schedule"} foundationimg={"images/upsc-foundation-program.jpg"} Regularimg={"images/upsc-regular-program.jpg"}  path1={'/UPSCFoundation'} path2={'/UPSCRegular'}   test={upscTestSeries} HeadingImage={"images/MAIN-SLIDER-4.jpg"}  />
        <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
            <Footer/>
        </Suspense>
        <ScrollToTop/>
        </div>
        <Popup/>
    </div>
    )
}
export default UPSCTestSeries