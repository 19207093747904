import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header'
import CoursesDetails from '../Components/CoursesDetails'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));

const UPSCFoundation = () => {
  useEffect(() => {
    const link = document.createElement('link');
    const link2 = document.createElement('link');
    const link3 = document.createElement('link');
    const link4 = document.createElement('link');
    const link5 = document.createElement('link');
    const link6 = document.createElement('link');
    const link7 = document.createElement('link');
    const link8 = document.createElement('link');
    link.rel = 'stylesheet';
    link2.rel = 'stylesheet';
    link3.rel = 'stylesheet';
    link4.rel = 'stylesheet';
    link5.rel = 'stylesheet';
    link6.rel = 'stylesheet';
    link7.rel = 'stylesheet';
    link8.rel = 'stylesheet';
    link.href = "css/plugins.min.css"; // Path to your CSS file
    link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
    link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
    link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
    link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
    link6.href = "css/style.min.css"; // Path to your CSS file
    link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
    link8.href = "css/templete.min.css"; // Path to your CSS file
    link.id = 'home-css'; // Set an ID for easy removal
    link2.id = 'home-css'; // Set an ID for easy removal
    link3.id = 'home-css'; // Set an ID for easy removal
    link4.id = 'home-css'; // Set an ID for easy removal
    link5.id = 'home-css'; // Set an ID for easy removal
    link6.id = 'home-css'; // Set an ID for easy removal
    link7.id = 'home-css'; // Set an ID for easy removal
    link8.id = 'home-css'; // Set an ID for easy removal

    document.head.append(link);
    document.head.append(link2);
    document.head.append(link3);
    document.head.append(link4);
    document.head.append(link5);
    document.head.append(link6);
    document.head.append(link7);
    document.head.append(link8);

    return () => {
        document.getElementById('home-css')?.remove(); // Remove when unmounting
    };
}, []);
      const upscCSEFoundationProgram = {
        program: "UPSC CSE Foundation Program",
        years:"(1 year | 2 year | 3 year)",
        overview: "The UPSC CSE Foundation Program is designed for aspirants who are at the beginning of their preparation journey, particularly fresh graduates or students in their final year of study. The program aims to provide a strong foundation for the entire UPSC syllabus, focusing on building conceptual clarity and critical skills required to tackle each stage of the examination: Prelims, Mains, and Interview",
        heading:"Ideal Features of the Program:",
        features: {
          First: {
            heading: "Comprehensive Syllabus Coverage",
            details: [
              "Covers all topics in General Studies (Prelims & Mains) and CSAT from scratch.",
              "Focuses on foundational knowledge in subjects like Polity, History, Geography, Economy, Environment, Science & Technology, Ethics, and Current Affairs.",
              "Includes coaching for Optional Subjects."
            ]
          },
          Second: {
            heading: "NCERT and Standard Books Focus",
            details: [
              "Sessions covering NCERT textbooks as a foundation.",
              "Gradual transition to advanced reference materials."
            ]
          },
          Third: {
            heading: "Gradual Learning Approach",
            details: [
              "Step-by-step progression from basics to advanced topics.",
              "Emphasizes strong command over concepts."
            ]
          },
          Fourth: {
            heading: "Daily Current Affairs & Editorial Analysis",
            details: [
              "Regular sessions on current affairs and editorials.",
              "Special sessions on writing high-quality answers and essays."
            ]
          },
          Fifth: {
            heading: "Answer Writing and Essay Writing Practice",
            details: [
              "Introduction to answer writing techniques for Mains.",
              "Regular essay writing practice with feedback."
            ]
          },
          Sixth: {
            heading: "CSAT Preparation",
            details: [
              "Classes on logical reasoning, analytical ability, data interpretation, and reading comprehension."
            ]
          },
          Seventh: {
            heading: "Test Series and Performance Analysis",
            details: [
              "Regular Prelims and Mains test series.",
              "Detailed performance analysis to track progress."
            ]
          }
        },
        heading2:"Who Can Enroll ?",
        whoCanEnroll:{
          First: {
            heading: "Eligibility:",
            details: [
              "Graduates or students currently pursuing their first or second year of graduation from any recognized university.",
              "Candidates aspiring to appear for the UPSC Civil Services Examination in the next 2-3 years and looking for a foundational start.",
            ]
          },
          Second: {
            heading: "Beginners:",
            details: [
              "Ideal for those who are new to UPSC preparation and need a step-by-step guide to understanding the exam pattern, syllabus, and preparation strategy.",
            ]
          },
          Third: {
            heading: "Undergraduates:",
            details: [
              "Students in their early academic years (First or second or third year) who wish to start preparing for the UPSC exam alongside their college education",
            ]
          },
          Fourth: {
            heading: "Aspirants with Little or No Background in UPSC Syllabus:",
            details: [
              "Those with no prior exposure to subjects like Polity, History, Economics, and Ethics who want to start building their knowledge base from scratch.",
            ]
          },
          Fifth: {
            heading: "Anyone Seeking Long-Term Preparation:",
            details: [
              "Candidates who plan to invest time in a gradual learning curve, mastering concepts over a period of 1.5 to 2 years, with a strong focus on cracking the exam in the first or second attempt.",
            ]
          }
        }
    
      }
  return (
    <div>
      <div className="page-wraper">
      <Header upsc="active"/>
      <CoursesDetails foundationimg={"images/upsc-regular-program.jpg"} Regularimg={"images/upsc-test-series.jpg"} testSeriesimg={"images/upsc-test-series.jpg"} path1={'/UPSCRegular'} path2={'/UPSCTestSeries'} path3={'/UPSCTestSeries'} Headingbanner1={"UPSC"} Headingbanner2={"UPSC CSE Foundation Program"} foundation={upscCSEFoundationProgram} HeadingImage={"images/MAIN-SLIDER-4.jpg"}/>
      <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
        <Footer/>
      </Suspense>
      <ScrollToTop/>
      </div>
      <Popup/>
    </div>
  )
}
export default UPSCFoundation