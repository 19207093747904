import React from 'react'
import QuestionAnswer4 from './QuestionAnswer4'

const IIMBodhgaya = () => {
  return (
    <div>
        <div className="class-info">
            <div className="dlab-post-text">
            <span>In line with the growing need for trained managerial talent, IIM Bodh Gaya launched its five-year Integrated Programme in Management (IPM) from academic session 2021. The programme intends to offer experiential learning, international exposure and a holistic course curriculum. The salient feature of the programme is to prepare candidates from a foundation level to taking up leadership positions in corporates/government organizations.</span><br /><br />
            <span>Candidates successfully completing the course requirement of integrated programme in management would be awarded Bachelor of Business Management and Master of Business Administration by IIM Bodh Gaya. Candidates opting for exit after completing the requirement of first three years, would be awarded Bachelor of Business Management.</span>
            </div>
        </div>
        <QuestionAnswer4/>
    </div>
  )
}

export default IIMBodhgaya
