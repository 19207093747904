import "./addreviews.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useRef, useState } from "react";
import Firebase, { database } from "../../../Firebase";
import { useNavigate } from "react-router-dom";

const AddReviews = ({title}) => {
const[obj,setobj]=useState({})
const[btndisable,setbtndisable]=useState(false)
const[File,setFile]=useState("")
const image=useRef()
const navigate=useNavigate()
const d=new Date()
const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
function set(event){
    setobj({...obj,[event.target.name]:event.target.value,"Date":date})
}
function NameChange(event){
    const name = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    setobj({...obj,"Name":name});
}
const upload=(event)=>{
    const file=event.target.files[0]
    if(!file) return alert("Image is not uploaded yet.")

    const ext=file.type.split("/")
    if(ext[0]!=="image") return alert("Only image is supported")
    
    if(ext[1]==="png" || ext[1]==="jpg" || ext[1]==="jpeg" || ext[1]==="PNG") return setFile(file)
    return alert("Only png,jpeg and jpg image is supported")  
}
async function submit(e){
try {
    e.preventDefault()
    setbtndisable(true)
    if(!obj.Name || !obj.Review) return alert("Field is Empty")
    if(!File) return alert("Upload Student Image first")
    const fileRef=Firebase.child(File.name)
    await fileRef.put(File)
    const path= await fileRef.getDownloadURL()
    const fullpath=fileRef.fullPath
    database.child("Reviews").push({...obj,"Image":path,"Path":fullpath},err=>{
        if(err) return alert("Something went wrong. Try again later.")
        else{
            setobj({})
            setFile("")
            alert("Review Saved Successfully")
            return setTimeout(()=>navigate("/Admin/Reviews"),1500)
        }
    })
} catch (error) {
    return alert("Something went wrong. Try again later.")
} finally{
    setbtndisable(false)
}
}
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                File
                  ? URL.createObjectURL(File)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
            <input type="file" onChange={upload} ref={image} hidden />
            <button onClick={()=>image.current.click()}>Upload Student Image</button>
          </div>
          <div className="right">
            <form>
                <div className="formInput">
                  <label>Student Name:</label>
                  <input type="text" name="Name" onChange={NameChange} value={obj.Name?obj.Name:""} placeholder="Enter the Student Name" />
                </div>
                <div className="formInput">
                  <label>Student Review:</label>
                  <textarea type="text" name="Review" onChange={set} value={obj.Review?obj.Review:""} placeholder="Enter the Student Review" />
                </div>
              <button disabled={btndisable} onClick={submit}>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReviews;