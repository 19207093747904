import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header'
import HomeTestimonials from '../Components/HomeTestimonials'
import AboutSection from '../Components/AboutSection'
import AboutGallery from '../Components/AboutGallery'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));
const Geniusias = () => {
useEffect(()=>{
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        const link3 = document.createElement('link');
        const link4 = document.createElement('link');
        const link5 = document.createElement('link');
        const link6 = document.createElement('link');
        const link7 = document.createElement('link');
        const link8 = document.createElement('link');
        link.rel = 'stylesheet';
        link2.rel = 'stylesheet';
        link3.rel = 'stylesheet';
        link4.rel = 'stylesheet';
        link5.rel = 'stylesheet';
        link6.rel = 'stylesheet';
        link7.rel = 'stylesheet';
        link8.rel = 'stylesheet';
        link.href = "css/plugins.min.css"; // Path to your CSS file
        link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
        link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
        link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
        link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
        link6.href = "css/style.min.css"; // Path to your CSS file
        link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
        link8.href = "css/templete.min.css"; // Path to your CSS file
        link.id = 'home-css'; // Set an ID for easy removal
        link2.id = 'home-css'; // Set an ID for easy removal
        link3.id = 'home-css'; // Set an ID for easy removal
        link4.id = 'home-css'; // Set an ID for easy removal
        link5.id = 'home-css'; // Set an ID for easy removal
        link6.id = 'home-css'; // Set an ID for easy removal
        link7.id = 'home-css'; // Set an ID for easy removal
        link8.id = 'home-css'; // Set an ID for easy removal

        document.head.append(link);
        document.head.append(link2);
        document.head.append(link3);
        document.head.append(link4);
        document.head.append(link5);
        document.head.append(link6);
        document.head.append(link7);
        document.head.append(link8);

        return () => {
            document.getElementById('home-css')?.remove(); // Remove when unmounting
        };
},[]);
const li=[
        {
            "name":"UPSC Regular Program",
            "link":"/UPSCRegular"
        },
        {
            "name":"UPSC Foundation Program",
            "link":"/UPSCFoundation"
        },
        {
            "name":"UPSC Test Series Program",
            "link":"/UPSCTestSeries"
        },        {
            "name":"HCS Regular Program",
            "link":"/HCSRegular"
        },
        {
            "name":"HCS Foundation Program",
            "link":"/HCSFoundation"
        },
        {
            "name":"HCS Test Series Program",
            "link":"/HCSTestSeries"
        }
]
return(
    <div>
        <div className="page-wraper">
        <Header aboutgenius="active"/>
        <div className="page-content">
        <div className="content-block">
        <AboutSection li={li} Heading={"Genius IAS"} MainContent={"Welcome to Genius IAS, Rohtak's leading institute for civil services examination preparation. Established with the mission to provide top-tier coaching and guidance for aspiring civil servants, Genius IAS has rapidly emerged as a beacon of excellence in the field."} imgPath={"/aboutusimage.jpg"} />
        <AboutGallery/>
        <HomeTestimonials/>
        </div>
        </div>
        <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
            <Footer/>
        </Suspense>
        </div>
        <ScrollToTop/>
        <Popup/>
    </div>
    )
}
export default Geniusias