import React from 'react'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Layout from './Adminpanel/Layout'
import Main from './Adminpanel/Main'
import UserRoute from './Pages/UserRoute'

const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/*' element={<UserRoute/>}></Route>
        <Route element={<Layout/>}>
        <Route path="/Admin/*" element={<Main/>}></Route>
        </Route>
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App