import React from 'react'
import { Link } from 'react-router-dom'

const AboutSection = (props) => {
  return (
    <div>
         <div className="section-full bg-white content-inner-2 about-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 m-b15">
                                        <div className="section-head">
                                            <h2 className="title text-secondry">{props.Heading}</h2>
                                            <p className="ext">{props.MainContent}</p>
                                        <div className="about-box customlii">                                        
                                            <ul className="list-check">
                                                {
                                                    props.li.map((obj,index)=>{
                                                            return(
                                            <li key={index}><Link to={obj.link}>{obj.name}</Link></li>

                                                            )
                                                    })
                                                }
                                          
                                        </ul>
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
                                        <div className="split-box">
                                            <div className="about-media">
                                                <img src={props.imgPath} className="radius-sm"  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    </div>
  )
}

export default AboutSection