import React, { useState } from 'react'

const Popup = () => {
    const [popup,setpopup]=useState(false)
  return (
    <div>
        <div style={popup?{height:"360px"}:{height:"0px"}} className='maindiv' >
                <div className='mainheader'><h3>Contact Details</h3></div>
                <div className="mainbottom">
                <div className='sociallinks'>
                <a href="tel:+91 70272 22123"><i className='icons2' ><i style={{color:"green"}} className="fa fa-phone"/></i><span style={{right:"13px"}} >+91 70272 22123</span> </a>
                </div>
                <div className='sociallinks' >
                <a href="https://www.youtube.com/channel/UC2z3jBDqL3z2QKXGaZvjNug"><i className='icons2' > <i style={{color:"red" }} className="fa fa-youtube"/></i><span style={{right:"29px"}} > Genius IAS</span> </a>

                </div>
                <div className='sociallinks' >
                <a href="https://www.instagram.com/geniusias/"> <i className='icons2' ><i  className="fa fa-instagram insta"/></i><span style={{right:"29px"}} >Genius IAS</span> </a>
                </div>
                <div className='sociallinks' >
                <a href="tel:+91 70272 22124"><i className='icons2' > <i style={{color:"green" }} className="fa fa-phone"/></i><span style={{right:"14px"}} >+91 70272 22124</span> </a>
                </div>
                <div className='sociallinks' >
                <a href="https://www.youtube.com/@Schoolofhumanitiesofficial"> <i className='icons2' ><i style={{color:"red" }} className="fa fa-youtube"/></i> <span  >School of Humanities</span> </a>
                </div>
                <div className='sociallinks' >
                <a href="https://www.instagram.com/school_of_humanities_official?igsh=ZGxxbzVtc3B2MXZv"><i className='icons2' ><i className="fa fa-instagram insta"/></i><span>School of Humanities</span> </a>
                </div>
                </div>
            <button onClick={()=>setpopup(!popup)} className={popup? "scroltop fa fa-chevron-up": "scroltop fa fa-phone"} style={{fontSize:"25px",backgroundColor:"rgb(28 157 78)", color:"white"}}></button>
            </div>  
    </div>
  )
}

export default Popup