import React from 'react'

const CUETcomp = () => {
  var arr = [
    {
      "university": "ALIGARH MUSLIM UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "ASSAM UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "BABASAHEB BHIMRAO AMBEDKAR UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "BANARAS HINDU UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "CENTRAL SANSKRIT UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "CENTRAL TRIBAL UNIVERSITY OF ANDHRA PRADESH",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF ANDHRA PRADESH",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF GUJARAT",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF HARYANA",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF HIMACHAL PRADESH",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF JAMMU",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF JHARKHAND",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF KARNATAKA",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF KASHMIR",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF KERALA",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF ODISHA",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF RAJASTHAN",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF SOUTH BIHAR",
      "button": "Click here"
    },
    {
      "university": "CENTRAL UNIVERSITY OF TAMIL NADU",
      "button": "Click here"
    },
    {
      "university": "DR. HARISINGH GOUR VISHWAVIDYALAYA",
      "button": "Click here"
    },
    {
      "university": "GURU GHASIDAS VISHWAVIDYALAYA",
      "button": "Click here"
    },
    {
      "university": "HEMVATI NANDAN BAHUGUNA GARHWAL UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "INDIRA GANDHI NATIONAL TRIBAL UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "JAMIA MILLIA ISLAMIA",
      "button": "Click here"
    },
    {
      "university": "JAWAHARLAL NEHRU UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "MAHATMA GANDHI ANTARRASHTRIYA HINDI VISHWAVIDYALAYA",
      "button": "Click here"
    },
    {
      "university": "MAHATMA GANDHI CENTRAL UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "MANIPUR UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "MAULANA AZAD NATIONAL URDU UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "MIZORAM UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "NAGALAND UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "NATIONAL SANSKRIT UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "NORTH-EASTERN HILL UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "PONDICHERRY UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "RAJIV GANDHI UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "SHRI LAL BAHADUR SHASTRI NATIONAL SANSKRIT UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "SIKKIM UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "TEZPUR UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "THE ENGLISH AND FOREIGN LANGUAGES UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "TRIPURA UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "UNIVERSITY OF ALLAHABAD",
      "button": "Click here"
    },
    {
      "university": "UNIVERSITY OF DELHI",
      "button": "Click here"
    },
    {
      "university": "UNIVERSITY OF HYDERABAD",
      "button": "Click here"
    },
    {
      "university": "VISVA-BHARATI UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "APEX UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "AVINASHILINGAM INSTITUTE FOR HOME SCIENCE AND HIGHER EDUCATION FOR WOMEN",
      "button": "Click here"
    },
    {
      "university": "BENNETT UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "BML MUNJAL UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "DAYALBAGH EDUCATIONAL INSTITUTE",
      "button": "Click here"
    },
    {
      "university": "DEVI AHILYA VISHWAVIDYALAYA",
      "button": "Click here"
    },
    {
      "university": "DR. A.P.J. ABDUL KALAM TECHNICAL UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "DR. B.R. AMBEDKAR SCHOOL OF ECONOMICS UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "DR. B.R. AMBEDKAR UNIVERSITY DELHI",
      "button": "Click here"
    },
    {
      "university": "GALGOTIAS UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "GUJARAT VIDYAPITH",
      "button": "Click here"
    },
    {
      "university": "GURUKULA KANGRI",
      "button": "Click here"
    },
    {
      "university": "IES UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "IIMT UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "JAGAN NATH UNIVERSITY BAHADURGARH HARYANA",
      "button": "Click here"
    },
    {
      "university": "JAGANNATH UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "JAYPEE UNIVERSITY OF INFORMATION TECHNOLOGY",
      "button": "Click here"
    },
    {
      "university": "JIWAJI UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "LAKSHMIBAI NATIONAL INSTITUTE OF PHYSICAL EDUCATION",
      "button": "Click here"
    },
    {
      "university": "MADAN MOHAN MALAVIYA UNIVERSITY OF TECHNOLOGY",
      "button": "Click here"
    },
    {
      "university": "MEWAR UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "NATIONAL RAIL AND TRANSPORTATION INSTITUTE",
      "button": "Click here"
    },
    {
      "university": "NIIT UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "RNB GLOBAL UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "SARDAR PATEL UNIVERSITY OF POLICE SECURITY AND CRIMINAL JUSTICE",
      "button": "Click here"
    },
    {
      "university": "SHOBHIT UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "SRM UNIVERSITY",
      "button": "Click here"
    },
    {
      "university": "TATA INSTITUTE OF SOCIAL SCIENCES (TISS)",
      "button": "Click here"
    },
    {
      "university": "TEERTHANKER MAHAVEER UNIVERSITY",
      "button": "Click here"
    }]
  return (
    <div>
         <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 className="post-title m-t0"><a >About CUET 2024</a></h2>
        </div>
        <div className="dlab-post-text">
          <p>National Testing Agency (NTA) conducts Central Universities Common Entrance Test (CUET) as an all-India examination for admissions into undergraduate and postgraduate courses across multiple central & state universities of India. The test for admission into the UG programs will be called CUET (UG)-2024 (Common University Entrance Test).</p>
          <p>Prestigious Universities like Delhi University and TISS have announced that they will be using CUET scores for admissions into the UG programs of 2024, along with many other Central and State universities.</p>
        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 className="post-title m-t0"><a >Details of CUET (UG)</a></h2>
        </div>
        <div className="dlab-post-text">
          <p>The test will be conducted as a CBT (Computer Based Test).</p>
        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 className="post-title m-t0"><a >Test Pattern</a></h2>
        </div>
        <div className="dlab-post-text">
          <p>CUET (UG) will consist of the following 4 Sections :</p>
          <ul>
            <li>Section IA – 13 Languages</li>
            <li>Section IB – 20 Languages</li>
            <li>Section II – 27 Domain specific Subjects</li>
            <li>Section III – General Test</li>
          </ul>
        </div>
      </div>
      <div className="class-info">
        <div className='table-responsive' >
          <table className='table table-hover table-bordered  custom-colortable' >
            <thead style={{ backgroundColor: "#3e4095" }} >
              <tr>
              <th>Section</th>
              <th>Subjects/ Tests</th>
              <th>Questions to be Attempted</th>
              <th>Question Type</th>
              <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Section IA - Languages</td>
                <td>
                  There are 13* different languages. Any of these languages may be chosen.</td>
                <td>40 questions to be attempted out of 50 in each language</td>
                <td>Language to be tested through Reading Comprehension (based on different types of passages–Factual, Literary and Narrative, [Literary Aptitude and Vocabulary]</td>
                <td>45 Minutes for each language</td>
              </tr>
              <tr>
                <td>
                  Section IA - Languages</td>
                <td>
                  There are 13* different languages. Any of these languages may be chosen.</td>
                <td>40 questions to be attempted out of 50 in each language</td>
                <td>Language to be tested through Reading Comprehension (based on different types of passages–Factual, Literary and Narrative, [Literary Aptitude and Vocabulary]</td>
                <td>45 Minutes for each language</td>
              </tr>
              <tr>
                <td>
                  Section IA - Languages</td>
                <td>
                  There are 13* different languages. Any of these languages may be chosen.</td>
                <td>40 questions to be attempted out of 50 in each language</td>
                <td>Language to be tested through Reading Comprehension (based on different types of passages–Factual, Literary and Narrative, [Literary Aptitude and Vocabulary]</td>
                <td>45 Minutes for each language</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-text">
          <p style={{ color: "black" }} >* Languages (13): Tamil, Telugu, Kannada, Malayalam, Marathi, Gujarati, Odiya, Bengali, Assamese, Punjabi, English, Hindi and Urdu</p>
          <p style={{ color: "black" }} >**Languages (20): Arabic, Bodo, Chinese, Dogri, French, German, Italian, Japanese, Kashmiri, Konkani, Maithili, Manipuri, Nepali, Persian, Russian, Santhali, Sindhi, Spanish, Tibetan, Sanskrit</p>
          <p style={{ color: "black" }} >***Domain Specific Subjects (27): Accountancy/BookKeeping, Agriculture, Anthropology, Biology/ BiologicalStudies/ Biotechnology/ Biochemistry, BusinessStudies, Chemistry, EnvironmentalStudies, ComputerScience/ InformaticsPractices, Economics/ BusinessEconomics, EngineeringGraphics, Entrepreneurship, FineArts/ VisualArts(Sculpture/ Painting)/ CommercialArt, Geography/ Geology, History, HomeScience, KnowledgeTradition-PracticesIndia, LegalStudies, MassMedia/ MassCommunication, Mathematics/ AppliedMathematics, PerformingArts, PhysicalEducation/ NationalCadetCorps(NCC)/Yoga, Physics, PoliticalScience, Psychology, Sanskrit, Sociology, TeachingAptitude</p>

          <li>A Candidate can choose a maximum of any 3 languages from Section IA and Section IB taken together. However, the third language chosen needs to be in lieu of 6th domain specific Subject chosen by the candidate - as applicable (so the maximum number of tests to be taken remains 9 only i.e. 2 Languages+6 Domain Specific Subjects+1 General Test OR 3 Languages+5 Domain Specific Subjects+1 General Test: flexibility being provided to help a candidate apply for many Universities depending on their eligibility conditions).</li>
          <li>From the above subjects / languages, the candidate can choose a maximum of 10 subjects from all three Sections.</li>
          <li>Examination will be conducted on multiple days in three shifts, depending on the number of Candidates and Subject choices.</li>

        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 className="post-title m-t0"><a >Syllabus:</a></h2>
        </div>
        <div className="dlab-post-text">
          <ul style={{ lineHeight: "1.9" }} >
            <li> Section IA & IB : Language to be tested through Reading Comprehension (based on different types of passages–Factual, Literary and Narrative [Literary Aptitude & Vocabulary]</li>
            <li> Section 2 : As per Class 12 syllabus only. Syllabus available at <a style={{ fontSize: "12px" }} href="https://cuet.samarth.ac.in/index.php/site/syllabus">https://cuet.samarth.ac.in/ index.php/ site/ syllabus</a></li>
            <li> Section 3 : General Test: General Knowledge, Current Affairs, General Mental Ability, Numerical Ability, Quantitative Reasoning (Simple application of basic mathematical concepts arithmetic algebra geometry mensuration statistics), Logical and Analytical Reasoning</li>
          </ul>


        </div>
        <div className="dlab-post-text">
          <p> <span className='cusspan' >Registration: </span>  Registration will be online at https://cuet.samarth.ac.in/ (Last date of registration - March 26, 2024)</p>
        </div>
        <div className="dlab-post-text">
          <p> <span className='cusspan' >Exam Date:</span>   Between 15 May and 31st May 2024</p>
        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 style={{ fontSize: "25px" }} className="post-title m-t0"><a >Level of questions for CUET (UG):</a></h2>
        </div>
        <div className="dlab-post-text">
          <p>All questions in various testing areas will be benchmarked at the level of Class XII only. Students having studied Class XII Board syllabus would be able to do well in CUET (UG)</p>
        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 style={{ fontSize: "25px" }} className="post-title m-t0"><a >Number of attempts:</a></h2>
        </div>
        <div className="dlab-post-text">
          <p>If any University permits students of previous years of class XII to take admission in the current year also, such students would also be eligible to appear in CUET (UG)</p>
        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 style={{ fontSize: "25px" }} className="post-title m-t0"><a >How to choose which sections are applicable and which subjects to choose in those sections</a></h2>
        </div>
        <div className="dlab-post-text">
          <p>Students need to refer to the requirements of the specific programs that they want to get into, as specified by the respective universities.</p>
        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 style={{ fontSize: "25px" }} className="post-title m-t0"><a >Evaluation And Merit Lists</a></h2>
        </div>
        <div className="dlab-post-text">
          <p>Admissions are handled at the level of each of the participating Universities for their respective programs. After the declaration of the CUCET result by NTA, the respective universities will declare the counselling/ admission schedule and merit list based on the weightage on the CUET score and the other criteria of the respective university. There will be online/offline admission counselling for participating universities. The participating universities will share these details separately.</p>
        </div>
      </div>
      <div className="class-info">
        <div className="dlab-post-title ">
          <h2 style={{ fontSize: "25px" }} className="post-title m-t0"><a >CUET - Universitywise eligibility and tests to be taken</a></h2>
        </div>
        <div className="dlab-post-text">
          <div className='table-responsive' >
          <table style={{ width: "50%" }} className='table table-hover table-bordered  custom-colortable' >
            <thead style={{ backgroundColor: "#3e4095" }} >
             <tr>
             <th>University</th>
             <th>Info Link</th>
             </tr>
            </thead>
            <tbody>
              {
                arr.map(function (obj,index) {
                  return (

                    <tr key={index}>
                      <td>{obj.university}</td>
                      <td> <a href="https://cuetug.ntaonline.in/frontend/web/scorecard/index"><button className='btn btn-primary' >{obj.button}</button></a></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default CUETcomp