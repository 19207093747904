import React, { useEffect, useState } from 'react'
import { database } from '../Firebase'
const SearchBar = () => {
const[obj,setobj]=useState({})
const[btndisable,setbtndisable]=useState(false)
const d=new Date()
const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
function set(event){
const phone = event.target.value.trim().replace(/[^\d-]/g, '');
setobj({...obj,"Mobile":phone,"Date":date});
}
function Submit(e){
e.preventDefault()
try {
setbtndisable(true)
if(!obj.Mobile) return alert("Field is Empty")
database.child("Counselling").push(obj,err=>{
    if(err) return alert("Something went wrong.Try again later")
    else return alert("Thank you for Contacting us. We will contact you soon.")
})
setobj({})
} catch (error) {
    return alert("Something went wrong.Try again later")
}finally{
    setbtndisable(false)
}
}
useEffect(()=>{
  const link = document.createElement('link');
  const link2 = document.createElement('link');
  const link3 = document.createElement('link');
  const link4 = document.createElement('link');
  const link5 = document.createElement('link');
  const link6 = document.createElement('link');
  const link7 = document.createElement('link');
  const link8 = document.createElement('link');
  link.rel = 'stylesheet';
  link2.rel = 'stylesheet';
  link3.rel = 'stylesheet';
  link4.rel = 'stylesheet';
  link5.rel = 'stylesheet';
  link6.rel = 'stylesheet';
  link7.rel = 'stylesheet';
  link8.rel = 'stylesheet';
  link.href = "css/plugins.min.css"; // Path to your CSS file
  link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
  link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
  link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
  link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
  link6.href = "css/style.min.css"; // Path to your CSS file
  link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
  link8.href = "css/templete.min.css"; // Path to your CSS file
  link.id = 'home-css'; // Set an ID for easy removal
  link2.id = 'home-css'; // Set an ID for easy removal
  link3.id = 'home-css'; // Set an ID for easy removal
  link4.id = 'home-css'; // Set an ID for easy removal
  link5.id = 'home-css'; // Set an ID for easy removal
  link6.id = 'home-css'; // Set an ID for easy removal
  link7.id = 'home-css'; // Set an ID for easy removal
  link8.id = 'home-css'; // Set an ID for easy removal

  document.head.append(link);
  document.head.append(link2);
  document.head.append(link3);
  document.head.append(link4);
  document.head.append(link5);
  document.head.append(link6);
  document.head.append(link7);
  document.head.append(link8);

  return () => {
      document.getElementById('home-css')?.remove(); // Remove when unmounting
  };
},[]);
return(
    <div style={{ backgroundImage: 'url(images/background/bg1.jpg)' }} className="content-block">
        <div style={{ paddingTop: "0px" }} className="section-full content-inner-2 bg-gray" />
        <div className="container">
            <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
            <div style={{paddingTop:"40px"}} className="col-lg-12 col-md-12 col-sm-12 col-12 " >
                <div className="search-bx style-1">
                    <form>
                    <div className="input-group">
                    <input name="Mobile" onChange={set} value={obj.Mobile?obj.Mobile:""} className="form-control" placeholder="Enter your Mobile number" type="tel" />
                    <span className="input-group-btn">
                    <button disabled={btndisable} type="submit" onClick={Submit}> Get FREE Counselling </button>
                    </span>
                    </div>
                    </form>
                </div>
            </div>
            </div>
            </div>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
)
}

export default SearchBar