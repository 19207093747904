import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import { useContext, useEffect, useState } from "react";
import { database } from "../../../Firebase";
import Context from "../../context/BlogsContext";

const Home = () => {
const[contact,setcontact]=useState(0)
const[data,setdata]=useState(null)
const[monthdata,setmonthdata]=useState(null)
const[blogs,setblogs]=useState(0)
const[notification,setnotification]=useState(0)
const[currentaffairs,setcurrentaffairs]=useState(0)
const {fetchdata}=useContext(Context)
useEffect(()=>{
  const refreshed=JSON.parse(localStorage.getItem("Refreshed"))
    if(!refreshed || !refreshed.status || refreshed.status!=="Okay"){
      localStorage.setItem("Refreshed",JSON.stringify({"status":"Okay"}))
      window.location.reload()
    }
},[])
useEffect(()=>{
  database.child("Contacts").on("value",function(snap){
    if(snap.val()){
      setdata(snap.val())
      return setcontact(Object.keys(snap.val()).length)
    }
    else{
      setdata(null)
      return setcontact(0)
    }
  })
  database.child("Notifications").on("value",function(snap){
    if(snap.val()) return setnotification(Object.keys(snap.val()).length)
    else return setnotification(0)
  })
  database.child("CurrentAffairs").on("value",function(snap){
    if(snap.val()) return setcurrentaffairs(Object.keys(snap.val()).length)
    else return setcurrentaffairs(0)
  })
},[])
useEffect(()=>{
if(fetchdata) return setblogs(Object.keys(fetchdata).length)
else return setblogs(0)
},[fetchdata])
useEffect(()=>{
  if(data){
    const allMonths = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    const mydata=[]
    const currentDate = new Date();
    // Loop backwards for six months
    for (let i = 0; i < 6; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      mydata.push(`${date.getMonth()+1}/${date.getFullYear()}`);
    }

    const allkeys=Object.keys(data)
    const dateobj={}

    for(let i=0;i<mydata.length;i++){
     const mydate=mydata[i].split("/")
     const myarray=[]
      for(let j=0;j<allkeys.length;j++){
        const yourdate=data[allkeys[j]].Date.split("/")
        if(mydate[1]===yourdate[2]){
          if(mydate[0]===yourdate[1]){
            myarray.push(data[allkeys[j]])
          }
        }
        dateobj[mydata[i]]=myarray.length
      }      
    }

    const monthsdata=[];
    const datekeys=Object.keys(dateobj)
    for(let i=0;i<datekeys.length;i++){
      const myobject={name:allMonths[datekeys[i].split("/")[0]-1]+" "+datekeys[i].split("/")[1],Total:dateobj[datekeys[i]]}
      monthsdata.push(myobject)
    }
    setmonthdata(monthsdata.reverse());
  }
},[data])
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div style={{height:"115px"}} className="widgets">
          <Widget type="user" value={contact} />
          <Widget type="order" value={blogs}/>
          <Widget type="earning" value={notification} />
          <Widget type="balance" value={currentaffairs}/>
        </div>
        <div className="charts">
          <Featured contacts={data} />
          <Chart data={monthdata} title="Last 6 Months" aspect={2 / 1} />
        </div>
        {/* <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div> */}
      </div>
    </div>
  );
};

export default Home;