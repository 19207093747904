import React from 'react'

const NPAT = () => {
    const examDetails = [
        {
          section: "Quantitative & Numerical ability",
          questions: 40,
          marks: 40
        },
        {
          section: "Reasoning & General Intelligence",
          questions: 40,
          marks: 40
        },
        {
          section: "Proficiency in English Language",
          questions: 40,
          marks: 40
        },
        {
          section: "Total",
          questions: 120,
          marks: 120
        }
      ];
      
  return (
    <div>
           <div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <p>NMIMS every year conducts the UG Entrance examination for class XII passed out / appearing candidates to be eligible to their UG Programs across campuses.</p>
                    <p>The Entrance Examination is a computer based online examination to be held at the test centers.</p>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Programs under : NPAT</a></h2>
                </div>
                <div className="dlab-post-text">
                    <ul>
                        <li>Commerce – BBA/B.Com/B.Sc. Finance/BBA (FinTech)</li>
                        <li>Economics – B.Sc. Economics</li>
                        <li>Liberal Arts – B.A.(Hons.) Liberal Arts</li>
                        <li>Branding & Advertising – BBA Branding & Advertising</li>
                        <li>International Studies – BBA (International Business) in collaboration with Kingston university</li>
                        <li>Management – Bachelor in Business Management & Marketing</li>
                    </ul>
                </div>
            </div>

            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Pattern of the Exam</a></h2>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Sections</th>
                                <th>Test</th>
                                <th>No. of Questions</th>
                                <th>Total Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    examDetails.map(function (obj,index) {
                                        return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{obj.section}</td>
                                                <td>{obj.questions}</td>
                                                <td>{obj.marks}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Exam Pattern:</a></h2>
                    <p>Time in minutes: 100</p>
                    <p>Type of questions and difficulty level of Tests will be as per SAT Exam.</p>
                </div>
               
            </div>
           
        </div>
    </div>
  )
}

export default NPAT