import React from 'react'
import { Link } from 'react-router-dom'
const CoursesDetails = (props) => {
    return (
        
        <div>
            <div className="page-content">
                    {/* inner page banner */}
                    <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: `url(${props.HeadingImage})`}}>
                    <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{props.Headingbanner1}</h1>
                        {/* <h1 className="text-white">(Common Law Admission Test)</h1> */}
                        {/* Breadcrumb row */}
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li>{props.Headingbanner2}</li>
                            </ul>
                        </div>
                        {/* Breadcrumb row END */}
                    </div>
                    </div>
                    </div>
                    {/* inner page banner END */}
                    {/* inner page banner END */}
                    <div className="content-block">
                        <div className="section-full bg-white content-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                                        <div className="classes-details">
                                            <div className="class-info">
                                                <div className="dlab-post-title ">
                                                    <h2 className="post-title m-t0"><a><span style={{fontSize:"40px",color:"red",fontWeight:"600"}}>{props.foundation.program}</span> <span style={{color:"rgb(62,64,149),"}}>{props.foundation.years?props.foundation.years:""}</span></a></h2>
                                                </div>
                                                <div className="dlab-post-text">
                                                    <p>{props.foundation.overview}</p>
                                                </div>
                                            </div>
                                            <div className="class-info">
                                                        <div className="dlab-post-title ">
                                                            <h2 style={{color:"orange"}} className="post-title m-t0"><a>{props.foundation.heading}-</a></h2>
                                                        </div>
                                                        </div>
                                        </div>
                                        <div className="classes-details">
                                    
                                            {
                                                Object.keys(props.foundation.features).map(function(key,index){
                                                    return(
                                                        <div key={index} className="class-info">
                                                        <div className="dlab-post-title ">
                                                            <h2 style={{fontSize:"25px"}} className="post-title m-t0"><a >{props.foundation.features[key].heading}</a></h2>
                                                        </div>
                                                        <div className="dlab-post-text">
                                                            <ul>
                                                            {
                                                                props.foundation.features[key].details.map(function(detail,i){
                                                                    return(
                                                                        <li key={i}> {detail}</li>
                                                                    )
                                                                })
                                                            }
                                                            </ul>
                                                        </div>
                                                    
                                                    </div>
                                                    )
                                                }) 
                                            }
                                           
                                        </div>
                                        <div className="class-info">
                                                        <div className="dlab-post-title ">
                                                            <h2 style={{color:"orange"}} className="post-title m-t0"><a >{props.foundation.heading2}</a></h2>
                                                        </div>
                                                        </div>
                                        <div className="classes-details">
                                    
                                            {
                                                Object.keys(props.foundation.whoCanEnroll).map(function(key,index){
                                                    return(
                                                        <div key={index} className="class-info">
                                                        <div className="dlab-post-title ">
                                                            <h2 style={{fontSize:"25px"}} className="post-title m-t0"><a >{props.foundation.whoCanEnroll[key].heading}</a></h2>
                                                        </div>
                                                        <div className="dlab-post-text">
                                                           <ul>
                                                           {
                                                                props.foundation.whoCanEnroll[key].details.map(function(detail,i){
                                                                    return(
                                                                        <li key={i}>{detail}</li>
                                                                    )
                                                                })
                                                            }
                                                           </ul>
                                                        </div>
                                                    
                                                    </div>
                                                    )
                                                }) 
                                            }
                                           
                                        </div>
                                      
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                      <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="cusborderddiv m-b10">
                          <div className='cusdiv'>
                            <Link to={props.path1}><img src={props.foundationimg}  /></Link>
                          </div>
                        </div>
                        
                        
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="cusborderddiv m-b30">
                          <div className='cusdiv'>
                            <Link to={props.path2}><img src={props.Regularimg}  /></Link>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>
        
    )
}

export default CoursesDetails