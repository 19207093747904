import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { database } from "../../../Firebase";

const ContactCounselling = () => {
const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "Mobile",
      headerName: "Mobile Number",
      width: 500,
    },
    {
      field: "Date",
      headerName: "Date",
      width: 350,
    },
];
  const [data, setData] = useState([]);
  useEffect(function(){
    database.child("Counselling").on("value",function(snap){
      if(snap.val()){
        const result= Object.keys(snap.val()).map((key,index)=>{return {...snap.val()[key],id:index+1,key:key}})
        return setData(result)
      }
      else return setData([])
    })
  },[])

  const handleDelete = (id) => {
    database.child(`Counselling/${id}`).remove()
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      display:"flex",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to="/users/test" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.key)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Mobile Numbers
        {/* <Link to="/Admin/users/new" className="link">
          Add New
        </Link> */}
      </div>
      {
        data.length!==0 ?<DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />:<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70%'}}><p>No Record Found</p></div>
      }
    </div>
  );
};

export default ContactCounselling;