import React from 'react'
import { Link } from 'react-router-dom'

const TestSeriesDetails = (props) => {
  return (
    <div className="page-content">
    {/* inner page banner */}
    
    <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: `url(${props.HeadingImage})`}}>
                    <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{props.Headingbanner1}</h1>
                        {/* <h1 className="text-white">(Common Law Admission Test)</h1> */}
                        {/* Breadcrumb row */}
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li>({props.Headingbanner2})</li>
                            </ul>
                        </div>
                        {/* Breadcrumb row END */}
                    </div>
                    </div>
                    </div>
    {/* inner page banner END */}
    {/* inner page banner END */}
    <div className="content-block">
        <div className="section-full bg-white content-inner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                        <div className="classes-details">
                            <div className="class-info">
                                <div className="dlab-post-title ">
                                    <h2 style={{ textDecoration:"underline"}} className="customtitle m-t0"><a>{props.test.program}</a></h2>
                                    <h2  className=" customtitle m-t0"><a>{props.test.fees}</a></h2>
                                    <h2 style={{ textDecoration:"underline"}} className=" customtitle m-t0"><a>Total Mocks:64</a></h2>
                                </div>
                            </div>
                         
                        </div>
                        <div className="classes-details">
                        <div className="class-info">
                                        <div className="dlab-post-title ">
                                            <h2  className="customtitle m-t0"><a>{props.test.heading}</a></h2>
                                            <h2  className="customtitlegreen m-t0"><a>(Dec 2024 to Mid-Jan 2025)</a></h2>
                                            <h2  className="customtitlegreen m-t0"><a>Mock Timing (10:00 – 12:00 am)</a></h2>
                                            <h2  className="customtitlegreen m-t0"><a>Analysis Timing (01:00 – 5 PM)</a></h2>
                                        </div>
                                        <div className="dlab-post-text">
                                        <div className='table-responsive' >
                                            <table  className='table table-hover table-bordered  custom-colortable' >
                                                <thead  style={{backgroundColor:"#3e4095"}} >
                                                    <tr>
                                                    <th>Date</th>
                                                    <th>Day</th>
                                                    <th>Test</th>
                                                    <th>Download</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                props.test.Table1.map(function(obj,index){
                                                return(
                                                    <tr key={index}>
                                                        <td>{obj.date}</td>
                                                        <td>{obj.day}</td>
                                                        <td>{obj.test}</td>
                                                        <td><button className='btn btn-success' >Download</button></td>
                                                    </tr>
                                                )
                                                }) 
                                                }
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        </div>
                       
                           
                           
                        </div>
                        <div className="classes-details">
                        <div className="class-info">
                                        <div className="dlab-post-title ">
                                            <h2  className="customtitle m-t0"><a>{props.test.heading2}</a></h2>
                                            <h2  className="customtitlegreen m-t0"><a>Mock Timing GS (10:00 – 12:00 am) & CSAT (02:00 – 04:00 pm)</a></h2>
                                            <h2  className="customtitlegreen m-t0"><a>Analysis Timing (12:00 – 5 PM)</a></h2>
                                        </div>
                                        <div className="dlab-post-text">
                                        <div className='table-responsive' >
                                            <table  className='table table-hover table-bordered  custom-colortable' >
                                                <thead  style={{backgroundColor:"#3e4095"}} >
                                                   <tr>
                                                   <th>Date</th>
                                                    <th>Day</th>
                                                    <th>Test</th>
                                                    <th>Downlaod</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                props.test.Table2.map(function(obj,index){
                                                return(
                                                    <tr key={index}>
                                                        <td>{obj.date}</td>
                                                        <td>{obj.day}</td>
                                                        <td>{obj.test}</td>
                                                        <td><button className='btn btn-success' >Download</button></td>
                                                    </tr>
                                                )
                                                }) 
                                                }
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        </div>
                       
                           
                           
                        </div>
                      
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 m-b15" >
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="cusborderddiv m-b10">
                          <div className='cusdiv'>
                            <Link to={props.path1}><img src={props.foundationimg} /></Link>
                          </div>
                        </div>
                        
                        
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="cusborderddiv m-b30">
                          <div className='cusdiv'>
                            <Link to={props.path2}><img src={props.Regularimg} /></Link>
                          </div>
                        </div>
                      </div>
                      </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default TestSeriesDetails