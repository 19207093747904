import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from '../Firebase';

const Logincomp = () => {
    const[obj,setobj]=useState({})
    const[haserror,sethaserror]=useState(false)
    const navigate=useNavigate()
    function set(event){
        sethaserror(false)
        setobj({...obj,[event.target.name]:event.target.value.trim().toLowerCase()})
    }
    function EmailChange(email){
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email)
    }
   async function Submit(e){
        try {
            e.preventDefault()
            if(!obj.Email || !obj.Password) return toast.warn("Field is Empty")
            
            const response=EmailChange(obj.Email)
            if(!response){
                sethaserror(true)
                return toast.error("Email is not a valid Email")
            }
            if(window.innerWidth <= 1024) return toast.error("You can login on Desktop/Laptop")
            const result= await Auth.signInWithEmailAndPassword(obj.Email,obj.Password)
            localStorage.setItem("Userdetail",JSON.stringify(result.user.uid))
            toast.success("Login Successfully")
            return setTimeout(()=>navigate("/Admin"),2500)
        } catch (error) {
            return toast.error("Invalid Credentials")
        }
    }
  return (
    <div>
        <ToastContainer position='top-center' style={{zIndex:"9999999"}}/>
        <div className="page-content">
            <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: 'url(images/MAIN-SLIDER-2.jpg)' }}>
                <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Login</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/"><i className="fa fa-home" /></Link></li>
                                    <li>Home</li>
                                    <li>Login</li>
                                </ul>
                            </div>
                        </div>
                </div>
            </div>
            <div className="content-block">
                <div id="contact-us" className="section-full bg-white content-inner-2 enquiry-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27942.91992390776!2d76.57173653920695!3d28.902359783149862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d858f9f405e3b%3A0xe7da85a3a54e45ea!2sGenius%20IAS%20-%20Best%20UPSC%20coaching%20in%20Rohtak%20%2F%20Best%20HCS%20coaching%20in%20Rohtak!5e0!3m2!1sen!2sin!4v1727034556664!5m2!1sen!2sin" style={{ height: '100%', width: '100%', border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                        <div className="col-md-6">
                            <div className="enquiry-bx">
                            <div className="head">
                            <h2 className="title">Admin Login</h2>
                            </div>
                            <div className="dzFormMsg" />
                            <form className="dzForm">
                            <input type="hidden" defaultValue="Contact" name="dzToDo" />
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                    <div className="input-group">
                                    <input name="Email" type="email" onChange={set} required className="form-control" placeholder="Enter your Email" />
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                    <div className="input-group">
                                    <input name="Password" type="password" className="form-control" onChange={set} required placeholder="Enter your Password" />
                                    </div>
                                    </div>
                                    <p style={{color:"red"}} className='text-center'>{haserror && "Email Address is not valid"}</p>
                                </div>
                                <div className="col-lg-12 text-center">
                                <button onClick={Submit} name="submit" type="submit" value="Submit" className="btn">
                                <span>Login</span></button>
                                </div>
                            </div>
                            </form>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Logincomp