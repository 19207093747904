import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header'
import CoursesDetails from '../Components/CoursesDetails'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));

const HCSRegular = () => {
  useEffect(() => {
    const link = document.createElement('link');
    const link2 = document.createElement('link');
    const link3 = document.createElement('link');
    const link4 = document.createElement('link');
    const link5 = document.createElement('link');
    const link6 = document.createElement('link');
    const link7 = document.createElement('link');
    const link8 = document.createElement('link');
    link.rel = 'stylesheet';
    link2.rel = 'stylesheet';
    link3.rel = 'stylesheet';
    link4.rel = 'stylesheet';
    link5.rel = 'stylesheet';
    link6.rel = 'stylesheet';
    link7.rel = 'stylesheet';
    link8.rel = 'stylesheet';
    link.href = "css/plugins.min.css"; // Path to your CSS file
    link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
    link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
    link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
    link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
    link6.href = "css/style.min.css"; // Path to your CSS file
    link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
    link8.href = "css/templete.min.css"; // Path to your CSS file
    link.id = 'home-css'; // Set an ID for easy removal
    link2.id = 'home-css'; // Set an ID for easy removal
    link3.id = 'home-css'; // Set an ID for easy removal
    link4.id = 'home-css'; // Set an ID for easy removal
    link5.id = 'home-css'; // Set an ID for easy removal
    link6.id = 'home-css'; // Set an ID for easy removal
    link7.id = 'home-css'; // Set an ID for easy removal
    link8.id = 'home-css'; // Set an ID for easy removal

    document.head.append(link);
    document.head.append(link2);
    document.head.append(link3);
    document.head.append(link4);
    document.head.append(link5);
    document.head.append(link6);
    document.head.append(link7);
    document.head.append(link8);

    return () => {
        document.getElementById('home-css')?.remove(); // Remove when unmounting
    };
}, []);
  const Qna= [
    {
      "question": "What is the HCS Regular Course?",
      "answer": "The HCS Regular Course at Genius IAS is a detailed program focused on preparing students for the Haryana Civil Services Examination. It involves an in-depth study of key subjects like Haryana GK, Indian Polity, History, Geography, and Current Affairs, aimed at helping aspirants achieve success."
    },
    {
      "question": "Who is eligible for the HCS Regular Course?",
      "answer": "Any aspirant looking to clear the Haryana Civil Services (HCS) exam conducted by the Haryana Public Service Commission (HPSC) can enroll in the Regular Course. Graduates or final-year students from any discipline are eligible to apply."
    },
    {
      "question": "What is the difference between the HCS Foundation and Regular Course?",
      "answer": "The HCS Foundation Course focuses on building basic concepts for beginners, while the HCS Regular Course is more advanced and offers in-depth preparation, including detailed subject analysis and advanced practice for both prelims and mains."
    },
    {
      "question": "How long is the HCS Regular Course?",
      "answer": "The duration of the HCS Regular Course ranges from 9 to 12 months, depending on the student’s pace and preparation needs. Regular classes, test series, and revision modules are part of the course."
    },
    {
      "question": "Do you provide specific study materials for the HCS Regular Course?",
      "answer": "Yes, Genius IAS provides thoroughly researched and up-to-date study materials including books, detailed notes, current affairs updates, and previous year’s papers, tailored specifically to the HCS exam."
    },
    {
      "question": "Are mock tests included in the HCS Regular Course?",
      "answer": "Absolutely! The HCS Regular Course includes regular mock tests for both Prelims and Mains, practice sessions, and comprehensive discussions on previous year’s papers, helping students improve their performance and exam strategies."
    }
  ]
  const hcsOneYearRegularProgram = {
    program: "HCS Regular Program",
    years:"(1 year)",
    overview: "The HPSC Haryana Civil Services (HCS) Examination is one of the most challenging competitive exams in India, requiring a well-structured preparation strategy. This one-year regular program is designed to provide a comprehensive, guided learning experience, combining subject knowledge, current affairs, and exam-specific skills. It ensures holistic preparation for all stages of the exam—Preliminary, Mains, and Interview.",
    heading: "Ideal Features of the Program:",
    features: {
      First: {
        heading: "Comprehensive Coverage",
        details: [
          "Syllabus-Centric Classes: The entire HCS syllabus for both the Preliminary and Mains examinations is covered in a systematic manner, with subject experts delivering lectures on all major topics (General Studies, Optional Subjects, CSAT).",
          "Foundation Focus: A focus on building a strong foundation for beginners while enhancing the knowledge of those already familiar with the syllabus."
        ]
      },
      Second: {
        heading: "Experienced Faculty",
        details: [
          "Faculty members are seasoned educators, subject matter experts, and professionals with deep insights into the demands of the HCS exam."
        ]
      },
      Third: {
        heading: "Test Series and Mock Exams",
        details: [
          "Regular Prelims and Mains test series with detailed feedback and analysis to help candidates understand their strengths and weaknesses.",
          "Mock Interviews to simulate the actual interview process and prepare aspirants to handle the HCS Personality Test confidently."
        ]
      },
      Fourth: {
        heading: "Current Affairs and Editorial Discussions",
        details: [
          "Daily coverage of current events, news analysis, and in-depth discussions on important topics from national and international newspapers and magazines relevant to the HCS exam."
        ]
      },
      Fifth: {
        heading: "Answer Writing Practice",
        details: [
          "Emphasis on improving answer writing skills for the Mains exam with daily practice, model answers, and expert evaluation."
        ]
      },
      Sixth: {
        heading: "Study Materials",
        details: [
          "Comprehensive, well-researched study materials covering all relevant topics, including NCERT books, reference materials, and handouts."
        ]
      },
      Seventh: {
        heading: "Doubt Clearing Sessions",
        details: [
          "Dedicated sessions to clarify doubts, promote active learning, and ensure no gaps in understanding."
        ]
      },
      Eighth: {
        heading: "Optional Subject Coaching",
        details: [
          "In-depth classes for optional subjects, with customized resources and mentorship to ensure candidates choose the right subject and excel in it."
        ]
      },
      Ninth: {
        heading: "Mentorship and Guidance",
        details: [
          "Personalized mentorship and counseling to guide aspirants through time management, stress management, and exam strategies tailored to individual learning styles."
        ]
      },
      Tenth: {
        heading: "Peer Learning and Group Discussions",
        details: [
          "Opportunities for peer interaction and group discussions to share insights and diverse perspectives, which is critical for essay writing and interview stages."
        ]
      }
    },
    heading2: "Who Can Enroll?",
    whoCanEnroll: {
      First: {
        heading: "Eligibility:",
        details: [
          "Graduates from any recognized university (final-year students can also apply).",
          "Aspirants with a clear goal of appearing for the Haryana Civil Services Examination in the upcoming year."
        ]
      },
      Second: {
        heading: "Aspirants in Any Stage of Preparation:",
        details: [
          "Beginners who need a structured roadmap.",
          "Intermediate learners who need to strengthen their preparation or focus on specific areas.",
          "Advanced candidates looking for test series, mock exams, and interview practice."
        ]
      },
      Third: {
        heading: "Working Professionals:",
        details: [
          "Flexible weekend or evening batch options may be available for professionals who wish to prepare alongside their job."
        ]
      },
      Fourth: {
        heading: "Repeat Aspirants:",
        details: [
          "Those who have already appeared for the exam but wish to refine their strategy and focus on weak areas."
        ]
      }
    }
  };
return(
  <div>
    <div className="page-wraper">
    <Header hcs="active"/>
    <CoursesDetails foundationimg={"images/HCS-TEST-SERIES.jpg"} Regularimg={"images/HCS-FOUNDATION-PROGRAM.jpg"}  path1={'/HCSTestSeries'} path2={'/HCSFoundation'} path3={''}   Headingbanner1={"HCS"} Headingbanner2={"HCS Regular Program"} foundation={hcsOneYearRegularProgram} HeadingImage={"images/MAIN-SLIDER-4.jpg"} QnA={Qna} Heading={"HCS Regular Course"} Details={"The HCS (Haryana Civil Services) Regular Course is an advanced preparation program aimed at equipping aspirants with all the necessary knowledge and skills to clear the Haryana Civil Services Examination conducted by the Haryana Public Service Commission (HPSC). "} blockquote={"The HCS Regular Course at Genius IAS is meticulously designed to offer comprehensive preparation for the Haryana Civil Services Examination."} Course={"Regular Course"} CourseDetails={"Our aim is to provide students with complete preparation, personalized guidance, and important skills. We help them develop critical thinking and stay updated on current events, so they can succeed in the UPSC exam and become effective leaders in the future."}  CourseImg={"images/Geniusdetail.jpg"} />
    <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
      <Footer/>
    </Suspense>
    </div>
    <ScrollToTop/>
    <Popup/>
    </div>
  )
}

export default HCSRegular