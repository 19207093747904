import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const BlogComp = (props) => {
const navigate=useNavigate()
function Send(key){
localStorage.setItem("Current-Blog",JSON.stringify(key))
navigate("/BlogDetails")
}
    return (
        <div>
            <div className="page-content">
                {/* inner page banner */}
                <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: 'url(images/bg10.jpg)' }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Latest Blogs</h1>
                            {/* Breadcrumb row */}
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/"><i className="fa fa-home" /></Link></li>
                                    <li>Home</li>
                                    <li>Blogs</li>
                                </ul>
                            </div>
                            {/* Breadcrumb row END */}
                        </div>
                    </div>
                </div>
                {/* inner page banner END */}
                <div className="content-block">
                    {/* blog grid */}
                    <div className="section-full content-inner bg-gray">
                        <div className="container">
                            <div className="dlab-blog-grid-3 row" id="masonry">
                                {
                                    props?.data && Object.keys(props.data).map((key,index)=>{
                                        return(
                                            <div key={index} className="card-container col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="blog-post blog-grid style-2">
                                        <div className="dlab-post-media">
                                            <a onClick={()=>Send(key)}><img style={{height:"250px",backgroundSize:"contain"}} src={props.data[key]?.Heading_Image?.url && props.data[key]?.Heading_Image?.url} loading='lazy' /></a>
                                        </div>
                                        <div className="dlab-info">
                                            <div className="dlab-post-title ">
                                                <h5 className="post-title"><a onClick={()=>Send(key)}>{props.data[key]?.Title && props.data[key]?.Title}</a></h5>
                                            </div>
                                            <div className="dlab-post-meta">
                                                <ul className="align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar" /><strong>{props.data[key]?.Date && props.data[key]?.Date}</strong> </li>
                                                    <li className="post-author"><i className="fa fa-user" />By <a href="blog-details.html">{props.data[key]?.Author && props.data[key]?.Author}</a></li>
                                                </ul>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>{props.data[key]?.Description && props.data[key]?.Description.slice(0,250) +"..." }</p>
                                            </div>
                                            <div className="dlab-post-readmore blog-share">
                                                <a onClick={()=>Send(key)} style={{color:"white"}} title="READ MORE" rel="bookmark" className="btn">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        )
                                    })
                                }
                                {/* <div className="card-container col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div className="blog-post blog-grid style-2">
                                        <div className="dlab-post-media">
                                            <a href="blog-details.html"><img src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt /></a>
                                        </div>
                                        <div className="dlab-info">
                                            <div className="dlab-post-title ">
                                                <h5 className="post-title"><a href="blog-details.html">The Shocking Revelation of Education.</a></h5>
                                            </div>
                                            <div className="dlab-post-meta">
                                                <ul className="align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar" /><strong>10 Aug</strong> <span>2020</span></li>
                                                    <li className="post-author"><i className="fa fa-user" />By <a href="blog-details.html">Jone</a></li>
                                                </ul>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks.</p>
                                            </div>
                                            <div className="dlab-post-readmore blog-share">
                                                <a href="blog-details.html" title="READ MORE" rel="bookmark" className="btn">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid style-2">
                                        <div className="dlab-post-media">
                                            <a href="blog-details.html"><img src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt /></a>
                                        </div>
                                        <div className="dlab-info">
                                            <div className="dlab-post-title ">
                                                <h5 className="post-title"><a href="blog-details.html">Five Things Nobody Told You About </a></h5>
                                            </div>
                                            <div className="dlab-post-meta">
                                                <ul className="align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar" /><strong>10 Aug</strong> <span>2020</span></li>
                                                    <li className="post-author"><i className="fa fa-user" />By <a href="blog-details.html">Jone</a></li>
                                                </ul>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks.</p>
                                            </div>
                                            <div className="dlab-post-readmore blog-share">
                                                <a href="blog-details.html" title="READ MORE" rel="bookmark" className="btn">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="blog-post blog-grid style-2">
                                        <div className="dlab-post-media">
                                            <a href="blog-details.html"><img src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt /></a>
                                        </div>
                                        <div className="dlab-info">
                                            <div className="dlab-post-title ">
                                                <h5 className="post-title"><a href="blog-details.html">Here's What People Are Saying About </a></h5>
                                            </div>
                                            <div className="dlab-post-meta">
                                                <ul className="align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar" /><strong>10 Aug</strong> <span>2020</span></li>
                                                    <li className="post-author"><i className="fa fa-user" />By <a href="blog-details.html">Jone</a></li>
                                                </ul>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks.</p>
                                            </div>
                                            <div className="dlab-post-readmore blog-share">
                                                <a href="blog-details.html" title="READ MORE" rel="bookmark" className="btn">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div className="blog-post blog-grid style-2">
                                        <div className="dlab-post-media">
                                            <a href="blog-details.html"><img src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt /></a>
                                        </div>
                                        <div className="dlab-info">
                                            <div className="dlab-post-title ">
                                                <h5 className="post-title"><a href="blog-details.html">Seven Things You Probably Didn't Know About </a></h5>
                                            </div>
                                            <div className="dlab-post-meta">
                                                <ul className="align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar" /><strong>10 Aug</strong> <span>2020</span></li>
                                                    <li className="post-author"><i className="fa fa-user" />By <a href="blog-details.html">Jone</a></li>
                                                </ul>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks.</p>
                                            </div>
                                            <div className="dlab-post-readmore blog-share">
                                                <a href="blog-details.html" title="READ MORE" rel="bookmark" className="btn">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid style-2">
                                        <div className="dlab-post-media">
                                            <a href="blog-details.html"><img src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt /></a>
                                        </div>
                                        <div className="dlab-info">
                                            <div className="dlab-post-title ">
                                                <h5 className="post-title"><a href="blog-details.html">Why is Early Education Essential?</a></h5>
                                            </div>
                                            <div className="dlab-post-meta">
                                                <ul className="align-items-center">
                                                    <li className="post-date"><i className="fa fa-calendar" /><strong>10 Aug</strong> <span>2020</span></li>
                                                    <li className="post-author"><i className="fa fa-user" />By <a href="blog-details.html">Jone</a></li>
                                                </ul>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks.</p>
                                            </div>
                                            <div className="dlab-post-readmore blog-share">
                                                <a href="blog-details.html" title="READ MORE" rel="bookmark" className="btn">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* blog grid END */}
                </div>
                {/* contact area END */}
            </div>
            {props.loader?<div className='preloader'>
        <div className="loader"></div>
        </div>:""}
        </div>
    )
}

export default BlogComp