import React, { useState } from 'react'

const QuestionAnswer4 = () => {
const [openIndex, setOpenIndex] = useState(null)
const toggleCollapse = (index) => {
setOpenIndex(openIndex === index ? null : index);
};
  return (
    <div className="content-block">
        <div className="section-full bg-white content-inner" style={{ backgroundImage: 'url(error-404.html)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <div className="row">   
                <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="accordion faq-box" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id={`heading${1}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(1); }} className={openIndex === 1 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${1}`} aria-expanded={openIndex === 1} aria-controls={`collapse${1}`}>Eligibility</a>
                            </div>
                                    <div id={`collapse${1}`} className={`collapse ${openIndex === 1 ? 'show' : ''}`} aria-labelledby={`heading${1}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                        <ul>
                                            <li>Qualifying Exam: Candidates should have passed 10+2/XII/HSC examination in arts/commerce/science stream or equivalent with 60% (55% for candidates from SC/ST/PwD/Transgender) or more in the year 2023 or appearing in 2024. Candidates who are likely to complete standard XII/ HSC or equivalent by 31 July 2024 can also apply. The candidate must have passed class 10th examination with 60% (55% for candidates from SC/ST/PwD/Transgender) or more in the year not before 2021.</li>
                                            <li>The percentage of marks obtained by the candidate in Class X and Class XII would be calculated based on the Board’s regulation. If there is no such regulation regarding aggregate marks from the concerned board, then the aggregate marks of all the subjects that appear in the grade sheet should be considered for calculating the passing percentage.</li>
                                            <li>The application of the candidates, who are appearing in Class XII examination, will be provisional till their Class XII result. The candidates who do not score the requisite score as above will be considered to be out of the application/admission process.</li>
                                            <li>Admission to the programme is open to Indian citizens only.</li>
                                        </ul>
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${2}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(2); }} className={openIndex === 2 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${2}`} aria-expanded={openIndex === 2} aria-controls={`collapse${2}`}>Intake</a>
                            </div>
                                    <div id={`collapse${2}`} className={`collapse ${openIndex === 2 ? 'show' : ''}`} aria-labelledby={`heading${2}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                       <span>Intake-120 seats (with standard GOI reservation policy)</span>
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${3}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(3); }} className={openIndex === 3 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${3}`} aria-expanded={openIndex === 3} aria-controls={`collapse${3}`}>Admission Process</a>
                            </div>
                                    <div id={`collapse${3}`} className={`collapse ${openIndex === 3 ? 'show' : ''}`} aria-labelledby={`heading${3}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <span><span style={{fontWeight:500}}>JIPMAT:</span> The admission to IPM would be through a Joint IPM Admission Test (JIPMAT 2024). The JIPMAT 2024 will be conducted by National Testing Agency (NTA) on behalf of IIM Bodh Gaya and IIM Jammu. IIM Bodh Gaya may explore the option of joint examination with other IIMs offering IPM.</span>
                                      <br />
                                      <span><span style={{fontWeight:500}}>Merit List:</span> The marks obtained in the JIPMAT 2024 will be used for preparing the merit list in the following manner.</span>
                                      <br />
                                      <span><span style={{fontWeight:500}}>Merit List:</span> The candidates, who scored* more than or equal to category-wise sectional percentile 30, will be eligible for the Merit List.</span>
                                      <br />
                                      <ol>
                                        <li>Government of Indian Rules for Reservation in admission will be applicable for SC, ST, NCOBC, EWS and PwD</li>
                                        <li>70% of seats are Gender-Neutral. If an optimal number of girls, in various reservation categories, are there in this pool of Gender-Neutral Seats, then the remaining 30% of seats will beGender-Neutral. Otherwise, the remaining 30% of seats will be offered as a combination of Gender-Neutral and Supernumerary Girls (SNG) seats. This provision of supernumerary seats is to promote a healthy gender ratio in the campus while keeping merit as the primary consideration.</li>
                                      </ol>
                                      <span>* Sectional raw scores will be subjected to minimum qualifying requirements also (the same will be decided after the result based on the available pool of the candidates).</span>
                                      <br />
                                      <span>For more details: Visit <a href="pdf/IPM-Admission-Policy.pdf">here</a></span>                                  
                                      </div>
                                    </div>
                        </div>
                        {/* <div className="card">
                            <div className="card-header" id={`heading${4}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(4); }} className={openIndex === 4 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${4}`} aria-expanded={openIndex === 4} aria-controls={`collapse${4}`}>Important Dates</a>
                            </div>
                                    <div id={`collapse${4}`} className={`collapse ${openIndex === 4 ? 'show' : ''}`} aria-labelledby={`heading${4}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Component</th>
                <th>Percentage</th>
                </tr>
                </thead>
               <tbody>
                <tr>
                    <td>GENDER</td>
                    <td>MARKS</td>
                </tr>
                <tr>
                    <td>Male</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>Female</td>
                    <td>10</td>
                </tr>
               </tbody>
                </table>
                                      </div> <br />
                                      <span>For any queries related to IPM admissions, please write to <a href="mailto:ipm.admissions@iimj.ac.in">ipm.admissions@iimj.ac.in</a></span>  
                                      </div>
                                    </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}
export default QuestionAnswer4