import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header'
import CoursesDetails from '../Components/CoursesDetails'
import ScrollToTop from '../Components/ScrollToTop'
import Popup from '../Components/Popup'
const Footer = React.lazy(() => import('../Components/Footer'));

const HCSFoundation = () => {
  useEffect(() => {
    const link = document.createElement('link');
    const link2 = document.createElement('link');
    const link3 = document.createElement('link');
    const link4 = document.createElement('link');
    const link5 = document.createElement('link');
    const link6 = document.createElement('link');
    const link7 = document.createElement('link');
    const link8 = document.createElement('link');
    link.rel = 'stylesheet';
    link2.rel = 'stylesheet';
    link3.rel = 'stylesheet';
    link4.rel = 'stylesheet';
    link5.rel = 'stylesheet';
    link6.rel = 'stylesheet';
    link7.rel = 'stylesheet';
    link8.rel = 'stylesheet';
    link.href = "css/plugins.min.css"; // Path to your CSS file
    link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
    link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
    link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
    link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
    link6.href = "css/style.min.css"; // Path to your CSS file
    link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
    link8.href = "css/templete.min.css"; // Path to your CSS file
    link.id = 'home-css'; // Set an ID for easy removal
    link2.id = 'home-css'; // Set an ID for easy removal
    link3.id = 'home-css'; // Set an ID for easy removal
    link4.id = 'home-css'; // Set an ID for easy removal
    link5.id = 'home-css'; // Set an ID for easy removal
    link6.id = 'home-css'; // Set an ID for easy removal
    link7.id = 'home-css'; // Set an ID for easy removal
    link8.id = 'home-css'; // Set an ID for easy removal

    document.head.append(link);
    document.head.append(link2);
    document.head.append(link3);
    document.head.append(link4);
    document.head.append(link5);
    document.head.append(link6);
    document.head.append(link7);
    document.head.append(link8);

    return () => {
        document.getElementById('home-css')?.remove(); // Remove when unmounting
    };
}, []);
  const Qna=[
    {
      "question": "What is the HCS Foundation Course?",
      "answer": "The HCS Foundation Course at Genius IAS is a comprehensive program designed to prepare students for the Haryana Civil Services Examination. It focuses on building a strong foundation in key subjects such as Haryana GK, Indian Polity, History, Geography, and Current Affairs."
    },
    {
      "question": "Who can enroll in the HCS Foundation Course?",
      "answer": "Any aspirant aiming to clear the Haryana Civil Services (HCS) exam conducted by the Haryana Public Service Commission (HPSC) can enroll in the Foundation Course. Graduates or final-year students from any discipline are eligible."
    },
    {
      "question": "What subjects are covered in the HCS Foundation Course?",
      "answer": "The course covers all key subjects needed for the HCS exam, including Haryana General Knowledge, Indian Polity, History, Geography, Economics, Environment, and Current Affairs. Special emphasis is placed on topics related to Haryana state."
    },
    {
      "question": "How long is the HCS Foundation Course?",
      "answer": "The duration of the HCS Foundation Course typically ranges from 6 to 12 months, depending on the batch and individual preparation needs. The course includes both regular classroom sessions and revision modules."
    },
    {
      "question": "Do you provide study material for the HCS Foundation Course?",
      "answer": "Yes, at Genius IAS, we provide meticulously curated study material, including comprehensive notes, books, and current affairs updates, to ensure thorough preparation for the HCS exam."
    },
    {
      "question": "Is there a provision for mock tests and practice sessions?",
      "answer": "Absolutely! Our HCS Foundation Course includes regular mock tests, practice sessions, and previous year’s paper discussions to help students gauge their preparation and improve their exam-taking strategies."
    }
  ]
  const hcsFoundationProgram = {
    program: "HCS Foundation Program",
    years:"(1 year | 2 year | 3 year)",
    overview: "The HPSC HCS Foundation Program is designed for aspirants at the beginning of their preparation journey, particularly fresh graduates or students in their final year of study. The program provides a strong foundation for the entire HCS syllabus, focusing on building conceptual clarity and critical skills required to tackle each stage of the examination: Prelims, Mains, and Interview. The course offers step-by-step guidance on how to approach the vast syllabus systematically, emphasizing both static and dynamic portions of the exam with a long-term study plan that gradually advances to more complex topics, ensuring comprehensive coverage.",
    heading: "Ideal Features of the Program:",
    features: {
      First: {
        heading: "Comprehensive Syllabus Coverage",
        details: [
          "Covers all topics in General Studies (Prelims & Mains) and CSAT from scratch.",
          "Detailed focus on building foundational knowledge in key subjects like Polity, History, Geography, Economy, Environment, Science & Technology, Ethics, and Current Affairs.",
          "Includes coaching for Optional Subjects, allowing students to select subjects based on their interest and aptitude."
        ]
      },
      Second: {
        heading: "NCERT and Standard Books Focus",
        details: [
          "Dedicated sessions to cover NCERT textbooks, which form the foundation of HCS preparation.",
          "Gradual transition to advanced reference materials once the basics are clear."
        ]
      },
      Third: {
        heading: "Gradual Learning Approach",
        details: [
          "Designed to build conceptual clarity through a step-by-step progression, starting from the basics and moving to advanced topics, ensuring strong command over concepts."
        ]
      },
      Fourth: {
        heading: "Daily Current Affairs & Editorial Analysis",
        details: [
          "Regular sessions focused on important current affairs, news analysis, and editorials from major newspapers like The Hindu, Indian Express, and other relevant sources.",
          "Special sessions on writing good-quality answers and essays by connecting current events with HCS topics."
        ]
      },
      Fifth: {
        heading: "Answer Writing and Essay Writing Practice",
        details: [
          "Introduction to answer writing techniques for the Mains exam with detailed feedback.",
          "Special focus on essay writing, with regular practice and evaluation of essays on important and current themes."
        ]
      },
      Sixth: {
        heading: "CSAT Preparation",
        details: [
          "Systematic preparation for Civil Services Aptitude Test (CSAT) through classes focused on logical reasoning, analytical ability, data interpretation, and reading comprehension."
        ]
      },
      Seventh: {
        heading: "Test Series and Performance Analysis",
        details: [
          "Regular Prelims and Mains test series conducted throughout the course to measure progress.",
          "Detailed performance analysis to track the aspirants' strengths and areas needing improvement."
        ]
      },
      Eighth: {
        heading: "Mentorship and Study Plan",
        details: [
          "Personal guidance to help students develop an effective long-term study plan, manage their time, and build discipline.",
          "Customized mentorship to cater to individual learning styles and provide motivation throughout the preparation journey."
        ]
      },
      Ninth: {
        heading: "Interactive Sessions and Group Discussions",
        details: [
          "Emphasis on interactive learning through classroom discussions, peer-learning sessions, and group discussions to develop critical thinking skills, which are essential for Mains and Interview stages."
        ]
      }
    },
    heading2: "Who Can Enroll?",
    whoCanEnroll: {
      First: {
        heading: "Eligibility:",
        details: [
          "Graduates or students currently pursuing their first or second year of graduation from any recognized university.",
          "Candidates aspiring to appear for the Haryana Civil Services Examination in the next 2-3 years and looking for a foundational start."
        ]
      },
      Second: {
        heading: "Beginners:",
        details: [
          "Ideal for those who are new to HCS preparation and need a step-by-step guide to understanding the exam pattern, syllabus, and preparation strategy."
        ]
      },
      Third: {
        heading: "Undergraduates:",
        details: [
          "Students in their early academic years (First or second or third year) who wish to start preparing for the HCS exam alongside their college education."
        ]
      },
      Fourth: {
        heading: "Aspirants with Little or No Background in HCS Syllabus:",
        details: [
          "Those with no prior exposure to subjects like Polity, History, Economics, and Ethics who want to start building their knowledge base from scratch."
        ]
      },
      Fifth: {
        heading: "Anyone Seeking Long-Term Preparation:",
        details: [
          "Candidates who plan to invest time in a gradual learning curve, mastering concepts over a period of 1.5 to 2 years, with a strong focus on cracking the exam in the first or second attempt."
        ]
      }
    }
  };
  return (
    <div>
      <div className="page-wraper">
      <Header hcs="active"/>
      <CoursesDetails foundationimg={"images/HCS-TEST-SERIES.jpg"} Regularimg={"images/HCS-REGULAR-PROGRAM.jpg"}  path1={'/HCSTestSeries'} path2={'/HCSRegular'} path3={''} Headingbanner1={"HCS"} Headingbanner2={"HCS Foundation Program"}  foundation={hcsFoundationProgram} HeadingImage={"images/MAIN-SLIDER-4.jpg"} QnA={Qna} Heading={"HCS Foundation Course"} Details={"The HCS (Haryana Civil Services) Foundation course is designed to prepare aspirants for the Haryana Civil Services Examination conducted by the Haryana Public Service Commission (HPSC). It focuses on building a strong foundation in all the key subjects required to crack the exam with confidence."} blockquote={"HCS Foundation Course at Genius IAS is a carefully crafted program designed to provide aspirants with a solid foundation for the Haryana Civil Services Examination. With an emphasis on core subjects like Haryana GK, Polity, History, and Current Affairs, our course is tailored to help students confidently navigate the exam and achieve success."} Course={"Foundation Course"} CourseDetails={"Our aim is to provide students with complete preparation, personalized guidance, and important skills. We help them develop critical thinking and stay updated on current events, so they can succeed in the UPSC exam and become effective leaders in the future."}  CourseImg={"images/Geniusdetail.jpg"} />
      <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
        <Footer/>
      </Suspense>
      </div>
      <ScrollToTop/>
      <Popup/>
    </div>
  )
}

export default HCSFoundation