import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Header = (props) => {
    const [headerToogle, setHeaderToogle] = useState(false)
    const [subheaderToogle1, setSubheaderToogle1] = useState(false)
    const [subheaderToogle2, setSubheaderToogle2] = useState(false)
    const [subSubheaderToogle1, setSubSubheaderToogle1] = useState(false)
    const [subSubheaderToogle2, setSubSubheaderToogle2] = useState(false)

    return (
        <div>
            <header className="site-header header mo-left">
                {/* main header */}
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar clearfix ">
                        <div className="container clearfix">
                            {/* website logo */}
                            <div style={{ height: 54, width: 224 }} className="logo-header mostion">
                                <Link to="/" className="dez-page"><img src="images/logo wesite.png" alt="" /></Link>
                            </div>
                            {/* nav toggle button */}
                            <button onClick={() => setHeaderToogle(!headerToogle)} className={headerToogle ? "navbar-toggler collapsed navicon justify-content-end open" : "navbar-toggler collapsed navicon justify-content-end"} type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span />
                                <span />
                                <span />
                            </button>
                            {/* main nav */}
                            <div className={headerToogle ? "header-nav navbar-collapse collapse justify-content-end show" : "header-nav navbar-collapse collapse justify-content-end"} id="navbarNavDropdown">
                                <div className="logo-header mostion">
                                    <Link to="/" className="dez-page"><img src="images/geniuslogo.png" alt="" /></Link>
                                </div>
                                <ul className="nav navbar-nav">
                                    <li className={props.home?props.home:""}><Link to={'/'}>Home</Link></li>
                                    <li className={subheaderToogle1 ? "open" : "" || (props.aboutgenius || props.aboutsoh ?"active":"")}>
                                        <Link onClick={() => setSubheaderToogle1(!subheaderToogle1)} >About Us <i className="fa fa-chevron-down" /></Link>
                                        <ul className="sub-menu" style={{ display: subheaderToogle1 ? 'block' : 'none' }}>
                                            <li><Link to={'/GeniusIAS'} >Genius IAS</Link></li>
                                            <li><Link to={'/SchoolofHumanities'} >School of Humanities</Link></li>
                                        </ul>
                                    </li>
                                    <li className={props.upsc || props.hcs || props.clat || props.cuet || props.class || props.ipm ? "active":""}>
                                        <Link onClick={() => setSubheaderToogle2(!subheaderToogle2)} >Courses <i className="fa fa-chevron-down" /></Link>
                                        <ul className="sub-menu" style={{ display: subheaderToogle2 ? 'block' : 'none' }}>
                                            <li onClick={() => setSubSubheaderToogle1(!subSubheaderToogle1)} ><Link  >Genius IAS <i className="fa fa-chevron-down" /></Link>
                                                <ul className="sub-menu2" style={{ display: subSubheaderToogle1 ? 'block' : 'none'}}>
                                                    <li><Link to={'/UPSC'} >UPSC</Link></li>
                                                    <li><Link to={'/HCS'} >HCS</Link></li>
                                                </ul>
                                            </li>
                                            <li onClick={() => setSubSubheaderToogle2(!subSubheaderToogle2  )} ><Link  >School of Humanities <i className="fa fa-chevron-down" /></Link>
                                                <ul className="sub-menu2" style={{ display: subSubheaderToogle2 ? 'block' : 'none' }}>
                                                    <li><Link to={'/CLAT'} >CLAT</Link></li>
                                                    <li><Link to={'/CUET'} >CUET</Link></li>
                                                    <li><Link to={'/IPM'} >IPM</Link></li>
                                                    <li><Link to={'/Class11th'} >Class 11 & 12</Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={props.blog?props.blog:""}><Link to={'/Blog'}>Blogs</Link></li>
                                    {props.home?<li><a href="#contact-us">Contact Us</a></li>:""}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}
export default Header