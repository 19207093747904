import React from 'react'

const CUETSET = () => {
    const institutes = [
        {
            instituteName: "Symbiosis Centre for Management Studies SCMS, Pune",
            programme: "B.B.A. - Honours / Honours with Research"
        },
        {
            instituteName: "Symbiosis Centre for Management Studies-NOIDA SCMS, NOIDA",
            programme: "B.B.A. - Honours / Honours with Research"
        },
        {
            instituteName: "Symbiosis Centre for Management Studies-Nagpur SCMS, Nagpur",
            programme: "B.B.A. - Honours / Honours with Research"
        },
        {
            instituteName: "Symbiosis Institute of Computer Studies and Research SICSR",
            programme: "B.B.A. (Information Technology) - Honours / Honours with Research"
        },
        {
            instituteName: "Symbiosis Centre for Media and Communication SCMC",
            programme: "B.B.A. (Media Management) Honours/ Honours with Research"
        },
        {
            instituteName: "Symbiosis Centre for Management Studies- Bengaluru SCMS, Bengaluru",
            programme: "B.B.A. - Honours / Honours with Research"
        },
        {
            instituteName: "Symbiosis Centre for Management Studies- Bengaluru SCMS, Bengaluru",
            programme: "B.B.A. (Dual Degree, Honours/ Honours with Research)"
        },
        {
            instituteName: "Symbiosis Centre for Management Studies- Hyderabad SCMS, Hyderabad",
            programme: "B.B.A. - Honours / Honours with Research"
        }
    ];
    const testSchedule = [
        {
            particular: "Registration closes on",
            date: "April 12, 2024 (Friday)"
        },
        {
            particular: "Payment closes on",
            date: "April 12, 2024 (Friday)"
        },
        {
            particular: "Admit Card Live on (www.set-test.org)",
            date: "April 25, 2024 (Thursday) for SET Test 01"
        },
        {
            particular: "Admit Card Live on (www.set-test.org)",
            date: "April 30, 2024 (Tuesday) for SET Test 02"
        },
        {
            particular: "Test 01",
            date: "May 05, 2024 (Sunday)",
            time: "SET: 11.30 AM to 12.30 PM"
        },
        {
            particular: "Test 02",
            date: "May 11, 2024 (Saturday)",
            time: "SET: 11.30 AM to 12.30 PM"
        },
        {
            particular: "Announcement of Result",
            date: "May 22, 2024 (Wednesday)"
        }
    ];
    const examSections = [
        {
            section: "General English",
            questions: 16,
            marks: 16
        },
        {
            section: "Quantitative Ability",
            questions: 16,
            marks: 16
        },
        {
            section: "General Awareness",
            questions: 16,
            marks: 16
        },
        {
            section: "Analytical & Logical Reasoning",
            questions: 12,
            marks: 12
        },
        {
            section: "Total",
            questions: 60,
            marks: 60
        }
    ];


    return (
        <div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <p>Symbiosis International University (SIU) is a private co-educational, multi-institutional university located in Pune, Maharashtra.</p>
                    <p>Each Symbiosis institute has its independent selection process. Students are short listed for personal interview (PI) and written aptitude test (WAT) on the basis of their performance in SET General.</p>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Eligibility Criteria:</a></h2>
                </div>
                <div className="dlab-post-text">
                    <p>Standard XII (10+2) pass or an equivalent examination from any recognized Board with a minimum of 50% marks or equivalent grade (45% marks or equivalent grade for Scheduled Caste/Scheduled Tribes).Participating Institutes</p>
                </div>
            </div>
            <div className="class-info">
                <div className='table-responsive' >
                    <table className='table table-hover table-bordered  custom-colortable' >
                        <thead style={{ backgroundColor: "#3e4095" }} >
                            <tr>
                            <th>Sr. No.</th>
                            <th>Name of the Institutea</th>
                            <th>Name of the Programme</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                institutes.map(function (obj, index) {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{obj.instituteName}</td>
                                            <td>{obj.programme}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >SET Important Dates:</a></h2>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Particular</th>
                                <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    testSchedule.map(function (obj,index) {
                                        return (
                                            <tr key={index}>
                                                <td>{obj.particular}</td>
                                                <td>{obj.date}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Exam Pattern:</a></h2>
                    <p>SET is conducted in Computer Based Test (CBT) mode.</p>
                    <p>The duration of each test is 60 minutes.</p>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Sr. No</th>
                                <th>Pattern</th>
                                <th>Number of Questions</th>
                                <th>Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    examSections.map(function (obj, index) {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{obj.section}</td>
                                                <td>{obj.questions}</td>
                                                <td>{obj.marks}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Exam Pattern:</a></h2>
                    <p>SET is conducted in Computer Based Test (CBT) mode.</p>
                    <p>The duration of each test is 60 minutes.</p>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Sr. No</th>
                                <th>Pattern</th>
                                <th>Number of Questions</th>
                                <th>Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    examSections.map(function (obj, index) {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{obj.section}</td>
                                                <td>{obj.questions}</td>
                                                <td>{obj.marks}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CUETSET