import React from 'react'
import { Link } from 'react-router-dom'

const Coursessecond = (props) => {
  return (
    <div>
   
                   <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: `url(${props.imgPath})` }}>
                    <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{props.name}</h1>
                        {/* <h1 className="text-white">(Common Law Admission Test)</h1> */}
                        {/* Breadcrumb row */}
                        
                        {/* Breadcrumb row END */}
                    </div>
                    </div>
                </div>
                   {/* inner page banner END */}
                   {/* inner page banner END */}
                   <div className="content-block">
                       <div className="section-full bg-white content-inner" style={{backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                           <div className="container">
                           <div  className="section-head "><h2 className="customheading text-center">School Integrated program </h2></div>
                               <div className="row">
                                   <div className="section-full bg-white content-inner-2 about-area">
                                       <div className="container">
                                           <div className="row">
                                               <div className="col-lg-6 col-md-12 col-sm-12 m-b15">
                                                   <div className="section-head">
                                                       <h2  className="title text-secondry customname">{props.name}</h2>
                                                       <h3  className="text-secondry protext">Program Features</h3>
                                                       <span className="extcus">
                                                        <ul>
                                                            <li>Subject : History | Geography | Political Science | English | Hindi / IT</li>
                                                            <li>Special Classes for Indian Economics</li>
                                                            <li>Comprehensive coverage of main humanities subjects </li>
                                                            <li>Holistic coverage of NCERTs + Standard books</li>
                                                            <li>Daily English newspaper reading classes</li>
                                                            <li>Regular current affairs sessions for important events</li>
                                                            <li>Mind map practice for self development of students </li>
                                                            <li>Focus on concept building & practical learning</li>
                                                            <li>Interconnected approach for comprehensive learning</li>
                                                            <li>Daily classes for General Aptitude (Quant, Logical Reasoning)</li>
                                                            <li>Regular sessions for Career Guidance</li>
                                                            <li>Special emphasis on writing skills</li>
                                                            <li>Weekly | monthly tests for regular evaluation</li>
                                                            <li>Classes by experts & permanent faculties</li>
                                                            </ul>
                                                       </span>
                                                   </div>
                                                   
                                               </div>
                                               <div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
                                        <div className="split-box">
                                            <div className="about-media">
                                                <img src={'class11-12th.jpg'} className="radius-sm"  />
                                            </div>
                                        </div>
                                    </div>
                                           </div>
                                           <br />
                                           <h4 style={{fontSize:"40px",fontWeight:"600",lineHeight: "1.1" }} className='title text-secondry' >Entrance Exams Preparatrion</h4>
                                                       <p style={{fontSize:"25px"}} className="ext"><a className='ext' style={{color:"#3e4095"}}  href="">UPSC Foundation | </a><a className='ext' style={{color:"#3e4095"}}  href="">CLAT | </a><a className='ext' style={{color:"#3e4095"}}  href="">IPM | </a><a className='ext' style={{color:"#3e4095"}}  href="">CUET | </a><a className='ext' style={{color:"#3e4095"}}  href="">SAT | </a><a className='ext' style={{color:"#3e4095"}}  href="">SET | </a><a className='ext' style={{color:"#3e4095"}}  href="">NPAT | </a><a className='ext' style={{color:"#3e4095"}}  href="">XIMB</a> </p>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="section-full content-inner" />
                   </div>
</div>
  )
}

export default Coursessecond