import React, { useState } from 'react'

const QuestionAnswer3 = () => {
const [openIndex, setOpenIndex] = useState(null)
const toggleCollapse = (index) => {
setOpenIndex(openIndex === index ? null : index);
};
  return (
    <div className="content-block">
        <div className="section-full bg-white content-inner" style={{ backgroundImage: 'url(error-404.html)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <div className="row">   
                <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="accordion faq-box" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id={`heading${1}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(1); }} className={openIndex === 1 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${1}`} aria-expanded={openIndex === 1} aria-controls={`collapse${1}`}>Intake</a>
                            </div>
                                    <div id={`collapse${1}`} className={`collapse ${openIndex === 1 ? 'show' : ''}`} aria-labelledby={`heading${1}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <span>Intake-120 seats (with standard GOI reservation policy)</span><br />
                                      <br />
                                      <span style={{fontWeight:500}}>Reservation Policy</span><br />
                                      <span>IIM Jammu strictly follows the Government of India Reservation Policy as given below:</span>
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Category</th>
                <th>Reservation (%)</th>
                </tr>
                </thead>
               <tbody>
                <tr>
                    <td>Scheduled Caste	</td>
                    <td>15</td>
                </tr>
                <tr>
                <td>Scheduled Tribe	</td>
                <td>7.5</td>
                </tr>
                <tr>
                <td>NC - OBC	</td>
                <td>27</td>
                </tr>
                <tr>
                <td>EWS	</td>
                <td>10</td>
                </tr>
                <tr>
                <td>PwD</td>
                <td>5</td>
                </tr>
               </tbody>
                </table>
                                      </div>        
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${2}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(2); }} className={openIndex === 2 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${2}`} aria-expanded={openIndex === 2} aria-controls={`collapse${2}`}>Eligibility Criteria</a>
                            </div>
                                    <div id={`collapse${2}`} className={`collapse ${openIndex === 2 ? 'show' : ''}`} aria-labelledby={`heading${2}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                       <ul>
                                        <li>Candidates should have passed Standard XII/HSC or equivalent examination in 2022,2023 or appearing in 2024. The candidate must have passed Standard X/ SSC or equivalent examination in the year 2020 or later.</li> <br />
                                        <li>Qualifying Examination-Candidate should have a Minimum 60% in standard X/ SSC and standard XII/ HSC or equivalent examinations (55 % for the candidates from SC/ST/ PwD category). Candidates who are likely to complete standard XII/ HSC or equivalent by the end of July 31st, 2024, can also apply.</li><br />
                                        <li>Applicants pursuing their XII standard from international schools affiliated to foreign boards like IGCSE (International General Certificate for Secondary Education) and IB (International Baccalaureate) can also apply.</li>
                                       </ul>
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${3}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(3); }} className={openIndex === 3 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${3}`} aria-expanded={openIndex === 3} aria-controls={`collapse${3}`}>Admission Process</a>
                            </div>
                                    <div id={`collapse${3}`} className={`collapse ${openIndex === 3 ? 'show' : ''}`} aria-labelledby={`heading${3}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <span style={{fontWeight:500}}>The selection process of IIM Jammu consists of:</span><br />
                                      <ul>
                                        <li>Joint Integrated Programme in Management Admission Test (JIPMAT) to be conducted by National Testing Agency (NTA).</li>
                                        <li>Preparation of Merit list will be based on JIPMAT Score and gender diversity.</li>
                                      </ul>
                                      <span>Criteria for Preparing the Merit List</span>
                                      <span>The criteria for preparing the merit list will be based on the composite score comprising the weightage of JIPMAT score and gender diversity as follows:</span>
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Components</th>
                <th>Percentage (%)</th>
                </tr>
                </thead>
               <tbody>
                <tr>
                    <td>JIPMAT</td>
                    <td>95</td>
                </tr>
                <tr>
                    <td>Gender Diversity</td>
                    <td>5</td>
                </tr>
               </tbody>
                </table>
                                      </div>
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Gender</th>
                <th>Marks</th>
                </tr>
                </thead>
               <tbody>
                <tr>
                    <td>Male</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Female</td>
                    <td>0</td>
                </tr>
               </tbody>
                </table>
                                      </div>                                     
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${4}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(4); }} className={openIndex === 4 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${4}`} aria-expanded={openIndex === 4} aria-controls={`collapse${4}`}>Gender Diversity</a>
                            </div>
                                    <div id={`collapse${4}`} className={`collapse ${openIndex === 4 ? 'show' : ''}`} aria-labelledby={`heading${4}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Component</th>
                <th>Percentage</th>
                </tr>
                </thead>
               <tbody>
                <tr>
                    <td>GENDER</td>
                    <td>MARKS</td>
                </tr>
                <tr>
                    <td>Male</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>Female</td>
                    <td>10</td>
                </tr>
               </tbody>
                </table>
                                      </div> <br />
                                      <span>For any queries related to IPM admissions, please write to <a href="mailto:ipm.admissions@iimj.ac.in">ipm.admissions@iimj.ac.in</a></span>  
                                      </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}
export default QuestionAnswer3