import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { database } from "../../../Firebase";

const Datatable = () => {
  // const userRows = [
  //   {
  //     id: 1,
  //     username: "Snow",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     status: "active",
  //     email: "1snow@gmail.com",
  //     age: 35,
  //   },
  //   {
  //     id: 2,
  //     username: "Jamie Lannister",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "2snow@gmail.com",
  //     status: "passive",
  //     age: 42,
  //   },
  //   {
  //     id: 3,
  //     username: "Lannister",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "3snow@gmail.com",
  //     status: "pending",
  //     age: 45,
  //   },
  //   {
  //     id: 4,
  //     username: "Stark",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "4snow@gmail.com",
  //     status: "active",
  //     age: 16,
  //   },
  //   {
  //     id: 5,
  //     username: "Targaryen",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "5snow@gmail.com",
  //     status: "passive",
  //     age: 22,
  //   },
  //   {
  //     id: 6,
  //     username: "Melisandre",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "6snow@gmail.com",
  //     status: "active",
  //     age: 15,
  //   },
  //   {
  //     id: 7,
  //     username: "Clifford",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "7snow@gmail.com",
  //     status: "passive",
  //     age: 44,
  //   },
  //   {
  //     id: 8,
  //     username: "Frances",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "8snow@gmail.com",
  //     status: "active",
  //     age: 36,
  //   },
  //   {
  //     id: 9,
  //     username: "Roxie",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "snow@gmail.com",
  //     status: "pending",
  //     age: 65,
  //   },
  //   {
  //     id: 10,
  //     username: "Roxie",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     email: "snow@gmail.com",
  //     status: "active",
  //     age: 65,
  //   },
  // ];
  const userColumns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "Name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
            {params.row.Name}
          </div>
        );
      },
    },
    {
      field: "Phone",
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "Course",
      headerName: "Course",
      width: 150,
      display:"flex",

      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.Course==="UPSC" || params.row.Course==="HCS"?"active": params.row.Course==="IPM" || params.row.Course==="CLAT" || params.row.Course==="CUET"?"pending":"passive"}`}>
            {params.row.Course}
          </div>
        );
      },
    },
    {
      field: "Message",
      headerName: "Message",
      width: 350,
    },
  ];
  const [data, setData] = useState([]);
  useEffect(function(){
    database.child("Contacts").on("value",function(snap){
      if(snap.val()){
        const result= Object.keys(snap.val()).map((key,index)=>{return {...snap.val()[key],id:index+1,key:key}})
        return setData(result)
      }
      else return setData([])
    })
  },[])

  const handleDelete = (id) => {
    database.child(`Contacts/${id}`).remove()
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      display:"flex",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to="/users/test" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.key)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Contact Info
        {/* <Link to="/Admin/users/new" className="link">
          Add New
        </Link> */}
      </div>
      {
        data.length!==0 ?<DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />:<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70%'}}><p>No Record Found</p></div>
      }
    </div>
  );
};

export default Datatable;