import "./blog.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../context/BlogsContext";
import Firebase, { database } from "../../../Firebase";
const Blogs = () => {
const navigate=useNavigate()
const {fetchdata}=useContext(Context)
async function Delete(key){
try {
  await database.child(`Blogs/${key}`).remove()
  return alert("Blog Deleted Successfully")
} catch (error) {
  return alert("Something Went Wrong. Try again later") 
}
}
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton" onClick={()=>navigate("/Admin/Blogs/new")}>Add New Blog</div>
            <h1 className="title">Your Blogs</h1>
            {
              fetchdata && Object.keys(fetchdata).map((key,index)=>{
                  return(
                    <div key={index} className="item">
                      <div className="custom" >
              <img
                src={fetchdata[key]?.Heading_Image?.url?fetchdata[key]?.Heading_Image?.url:"https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"}
                alt=""
                loading="lazy"
                className="itemImge"
              />
              <div className="details">
                <h1 className="itemTitle">{fetchdata[key]?.Title} <span style={{float:"right"}}><button onClick={()=>navigate(`/Admin/Blogs/${key}`)}>View</button><button onClick={()=>Delete(key)}>Delete</button></span></h1>
                <div className="detailItem">
                  <span className="itemKey">Author's Name:</span>
                  <span className="itemValue">{fetchdata[key]?.Author}</span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">Heading of the Blog:</span>
                  <span className="itemValue">{fetchdata[key]?.Heading}</span>
                </div> */}
                <div className="detailItem">
                  <span className="itemKey">Category:</span>
                  <span className="itemValue">
                  {fetchdata[key]?.Category}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Description:</span>
                  <span className="itemValue">
                  {fetchdata[key]?.Description.slice(0,125)+"..."}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tags:</span>
                  <span className="itemValue">{fetchdata[key]?.Tags?.split(",").map((value,i)=>{
                    if(fetchdata[key]?.Tags?.split(",").length-1===i){
                      return(
                        <span key={i}>#{value}.</span>
                      )
                    }
                    else{
                      return(
                        <span key={i}>#{value},</span>
                      )
                    }
                  })}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Status:</span>
                  <span className="itemValue">
                  {fetchdata[key]?.Status}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Date:</span>
                  <span className="itemValue">
                  {fetchdata[key]?.Date}
                  </span>
                </div>
              </div>
              </div>
                    </div>
              )
              })
            }
            {/* <div className="item">
              <img
                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                alt=""
                className="itemImge"
              />
              <div className="details">
                <h1 className="itemTitle">Jane Doe</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">janedoe@gmail.com</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">+1 2345 67 89</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">
                    Elton St. 234 Garden Yd. NewYork
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">USA</span>
                </div>
              </div>
            </div>
            <div className="item">
              <img
                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                alt=""
                className="itemImge"
              />
              <div className="details">
                <h1 className="itemTitle">Jane Doe</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">janedoe@gmail.com</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">+1 2345 67 89</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">
                    Elton St. 234 Garden Yd. NewYork
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">USA</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;