import React, { useState } from 'react'
import CUETcomp from './CUETcomp'
import CUETSET from './CUETSET'
import NPAT from './NPAT'
import DUBBA from './DUBBA'
import IPUCET from './IPUCET'
import CHRISTBBA from './CHRISTBBA'
import UGAT from './UGAT'
import IIMSIRMARUR from './IIMSIRMARUR'
const Componentcuet = (props) => {

const [id,setid]   =useState("1")
    return (
        <div>
            <div className="page-content">
                {/* inner page banner */}
                <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: `url(${props.HeadingImage})` }}>
                    <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{props.Headingbanner1}</h1>
                        {/* <h1 className="text-white">(Common Law Admission Test)</h1> */}
                        {/* Breadcrumb row */}
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li>{props.Headingbanner2}</li>
                            </ul>
                        </div>
                        {/* Breadcrumb row END */}
                    </div>
                    </div>
                </div>
                {/* inner page banner END */}
                {/* inner page banner END */}
                <div className="content-block">
                    <div className="section-full bg-white content-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                                    <div className="classes-details">
                                        <div className="class-btns">

                                            <div className="dlab-post-text">
                                                <div className='cusbtns' >
                                                    <button onClick={()=>setid("1")} className='m-t10' >CUET</button>
                                                    <button onClick={()=>setid("2")} className='m-t10' >SET General</button>
                                                    <button onClick={()=>setid("3")} className='m-t10' >NPAT</button>
                                                    <button onClick={()=>setid("4")} className='m-t10' >DU BBA</button>
                                                    <button onClick={()=>setid("5")} className='m-t10' >IPU CET</button>
                                                    <button onClick={()=>setid("6")} className='m-t10' >Christ BBA</button>
                                                    <button onClick={()=>setid("7")} className='m-t10' >UGAT</button>
                                                    <button onClick={()=>setid("8")} className='m-t10' >IIMB BMS</button>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                          id=="1"?<CUETcomp/>:id=="2"?<CUETSET/>:id=="3"?<NPAT/>:id=="4"?<DUBBA/>:id=="5"?<IPUCET/>:id=="6"?<CHRISTBBA/>:id=="7"?<UGAT/>:id=="8"?<IIMSIRMARUR/>:""
                                        }
                                        
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Componentcuet