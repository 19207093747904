import React, { useState } from 'react'
import IIMRanchi from './IIMRanchi'
import IIMRohtak from './IIMRohtak'
import IIMIndore from './IIMIndore'
import IIMJammu from './IIMJammu'
import IIMBodhgaya from './IIMBodhgaya'
import Jipmat from './Jipmat'
import Nalsar from './Nalsar'
import IIFTkakinada from './IIFTkakinada'

const ContentofIPM = () => {
const[state,setstate]=useState("IIMIndore")
const data={
    IIMRanchi:<IIMRanchi/>,
    IIMRohtak:<IIMRohtak/>,
    IIMIndore:<IIMIndore/>,
    IIMJammu:<IIMJammu/>,
    IIMBodhgaya:<IIMBodhgaya/>,
    JIPMAT:<Jipmat/>,
    NALSAR:<Nalsar/>,
    IIFTKakinada:<IIFTkakinada/>
} 
  return (
    <div>
    <div className="page-content">
        {/* inner page banner */}
        <div className="dlab-bnr-inr overlay-black-dark" style={{ backgroundImage: `url(IPM.jpg)` }}>
            <div className="container">
            <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">IPM</h1>
                {/* <h1 className="text-white">(Common Law Admission Test)</h1> */}
                {/* Breadcrumb row */}
                <div className="breadcrumb-row">
                    <ul className="list-inline">
                        <li>Integrated Programme in Management</li>
                    </ul>
                </div>
                {/* Breadcrumb row END */}
            </div>
            </div>
        </div>
        {/* inner page banner END */}
        {/* inner page banner END */}
        <div className="content-block">
            <div className="section-full bg-white content-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                            <div className="classes-details">
                                <div className="class-btns">
                                    <div className="dlab-post-text">
                                        <div className='cusbtns' >
                                            <button onClick={()=>setstate("IIMIndore")} className='m-t10'>IIM Indore</button>
                                            <button onClick={()=>setstate("IIMRohtak")} className='m-t10'>IIM Rohtak</button>
                                            <button onClick={()=>setstate("IIMRanchi")} className='m-t10'>IIM Ranchi</button>
                                            <button onClick={()=>setstate("IIMJammu")} className='m-t10'>IIM Jammu</button>
                                            <button onClick={()=>setstate("IIMBodhgaya")} className='m-t10'>IIM Bodhgaya</button>
                                            <button onClick={()=>setstate("JIPMAT")} className='m-t10'>JIPMAT</button>
                                            <button onClick={()=>setstate("NALSAR")} className='m-t10'>NALSAR</button>
                                            <button onClick={()=>setstate("IIFTKakinada")} className='m-t10'>IIFT Kakinada</button>
                                        </div>
                                    </div>
                                </div>
                                    {/* content */}
                                    {
                                        state && data[state]?data[state]:""
                                    }
                            </div>           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default ContentofIPM
