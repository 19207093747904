import React from 'react'

const CHRISTBBA = () => {
    const sectionQuestions = [
        {
          section: "English Language",
          questions: 25
        },
        {
          section: "General Knowledge",
          questions: 20
        },
        {
          section: "Fundamental Accounting",
          questions: 10
        },
        {
          section: "Quantitative Aptitude",
          questions: 20
        },
        {
          section: "Logical Reasoning",
          questions: 25
        },
        {
          section: "Data Interpretation",
          questions: 20
        },
        {
          section: "Total",
          questions: 120
        }
      ];
      
  return (
    <div>
         <div>
            <div className="class-info">
                <div className="dlab-post-text">
                    <p>The selection process for Christ University begins with the Entrance Test. Based on the scores in Christ University-ET, candidates are called for further stages in selection process i.e. Skill Assessment, Micro Presentation and Personal Interview.</p>
                    <p>Admission to these BBA programs is done through a common entrance test popularly known as IPU CET conducted by the university.</p>
                </div>
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Eligibility</a></h2>
                </div>
                <div className="dlab-post-text">
                  <p>Each program will have its own eligibility criteria therefore the candidates are advised to check the eligibility criteria applicable to the course of their choice. The general eligibility for all programs, especially for BBA program, is as follows:</p>
                  <p>Basic eligibility for BBA program is a class XII qualified student in any stream from any recognised Board in India or abroad.</p>
                  <p>Candidates writing the XII board exams in March-May of the exam year may apply with their class X and class XI mark-sheet.</p>
                  <p>Students pursuing International curriculum must have AIU approval and must obtain a grade of not less than 'C' or its equivalent.</p>
                </div>
            </div>

            <div className="class-info">
                <div className="dlab-post-title ">
                    <h2 className="post-title m-t0"><a >Test Pattern</a></h2>
                    <p>The exam is conducted in a paper & pencil format. The total duration of the entrance test is two hours. Each correct answer earns one mark, while each incorrect answer results in a penalty of 0.25 marks.</p>
                </div>
                
            </div>
            <div className="class-info">
                <div className="dlab-post-title ">
                    <p>Following is the section wise split of the paper:</p>
                </div>
                <div className="dlab-post-text">
                    <div className='table-responsive' >
                        <table className='table table-hover table-bordered  custom-colortable' >
                            <thead style={{ backgroundColor: "#3e4095" }} >
                                <tr>
                                <th>Section	</th>
                                <th>Number of Questions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sectionQuestions.map(function (obj,index) {
                                        return (
                                            <tr key={index}>
                                                <td>{obj.section}</td>
                                                <td>{obj.questions}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
  )
}

export default CHRISTBBA