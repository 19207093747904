import React, { useState } from 'react'

const QuestionAnswer1 = () => {
const [openIndex, setOpenIndex] = useState(null)
const toggleCollapse = (index) => {
setOpenIndex(openIndex === index ? null : index);
};
  return (
    <div className="content-block">
        <div className="section-full bg-white content-inner" style={{ backgroundImage: 'url(error-404.html)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <div className="row">   
                <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="accordion faq-box" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id={`heading${1}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(1); }} className={openIndex === 1 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${1}`} aria-expanded={openIndex === 1} aria-controls={`collapse${1}`}>About Curriculum</a>
                            </div>
                                    <div id={`collapse${1}`} className={`collapse ${openIndex === 1 ? 'show' : ''}`} aria-labelledby={`heading${1}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <ul>
                                        <li>Total number of years – 5                                        </li>
                                        <li>Total number of terms – 15                                        </li>
                                        <li>Number of terms per year – 3, each of 3 months duration.                                        </li>
                                      </ul>
                                      <span>The entire programme is divided into two parts; the first part focuses on the foundation courses, whereas the second part focuses on the management. Students will be undergoing internships at the end of every academic year.</span><br />
                                      <span>The curriculum has 4 core areas: Mathematics, Economics, Statistics, and Humanities. Apart from these, other courses such as Business Communication, Management Information System, Business, and Foreign Languages are also offered. Courses offered at the PG level (4th and 5th Year) will be the same as the PGP curriculum.</span><br />
                                      <span>Intake - 165</span><br />
                                      <span>IIM Rohtak has earmarked a total of 7 Seats for the International applicants under the provision of the Supernumerary Quota (this will be separate from the regular intake of the IPM programme).</span><br />
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${2}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(2); }} className={openIndex === 2 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${2}`} aria-expanded={openIndex === 2} aria-controls={`collapse${2}`}>Important Dates</a>
                            </div>
                                    <div id={`collapse${2}`} className={`collapse ${openIndex === 2 ? 'show' : ''}`} aria-labelledby={`heading${2}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                       <span>Online Application Opens - Feb 06, 2024
                                       </span><br />
                                       <span>Last date for submission of online Application - April 10, 2024 (Tentative)
                                       </span>
                                       <br />
                                       <span>IPM Aptitude Test 2024 - May 18, 2024 (9:00 AM onwards)</span>
                                       </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${3}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(3); }} className={openIndex === 3 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${3}`} aria-expanded={openIndex === 3} aria-controls={`collapse${3}`}>About Online Aptitude Test</a>
                            </div>
                                    <div id={`collapse${3}`} className={`collapse ${openIndex === 3 ? 'show' : ''}`} aria-labelledby={`heading${3}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      IPM Aptitude Test will comprise of following sections: <br />
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Section	</th>
                <th>Number of Questions	</th>
                <th>Time Duration for each section                </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Quantitative Ability	</td>
                    <td>40</td>
                    <td>40 minutes                    </td>
                </tr>
                <tr>
                    <td>Logical Reasoning	</td>
                    <td>40</td>
                    <td>40 minutes                    </td>
                </tr>
                <tr>
                    <td>Verbal Ability	</td>
                    <td>40</td>
                    <td>40 minutes                    </td>
                </tr>
                </tbody>
                </table>
                                      </div>
                                      <span><span style={{fontWeight:500}}>Note:</span>Each question will carry 4 marks. There will be a negative marking of 1 mark for each wrong answer. All the questions will be multiple-choice questions.</span>
                                      
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${4}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(4); }} className={openIndex === 4 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${4}`} aria-expanded={openIndex === 4} aria-controls={`collapse${4}`}>Eligibility Criteria</a>
                            </div>
                                    <div id={`collapse${4}`} className={`collapse ${openIndex === 4 ? 'show' : ''}`} aria-labelledby={`heading${4}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                        <span style={{fontWeight:500}}>To apply for this programme:</span><br />
                                        <span><span style={{fontWeight:500}}>General, NC-OBC and EWS Category:</span>Minimum 60% in standard X/ SSC and standard XII/ HSC or equivalent examinations, maximum 20 years of age as on June 30, 2024</span>
                                        <br />
                                        <span><span style={{fontWeight:500}}>SC, ST and PWD (DA) Category: </span>Minimum 55% in standard X/ SSC and standard XII/HSC or equivalent examinations, maximum 20 years of age as on June 30, 2024</span>
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${5}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(5); }} className={openIndex === 5 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${5}`} aria-expanded={openIndex === 5} aria-controls={`collapse${5}`}>Selection and Admission process</a>
                            </div>
                                    <div id={`collapse${5}`} className={`collapse ${openIndex === 5 ? 'show' : ''}`} aria-labelledby={`heading${5}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      The announcement of Call for Online Personal Interviews (PI) is usually done in the 2nd Week of June. The shortlisting will be based on the IPM AT scores. The candidates during their personal interviews will be judged on the basis of their academics, general awareness, and communication skills. The interviews are ususally proposed to be held in the Fourth Week of June.
                                      <br />
                                      The final Merit List will be generated based on the composite score as mentioned below
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Components</th>
                <th>Weightage (%)</th>
                </tr>
                </thead>
               <tbody>
                <tr>
                    <td>Aptitude Test Score	</td>
                    <td>45</td>
                </tr>
                <tr>
                <td>Personal Interview (PI)	</td>
                <td>15</td>
                </tr>
                <tr>
                <td>Past Academics (10th and 12th)	</td>
                <td>40</td>
                </tr>
               </tbody>
                </table>
                                      </div>                                      
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${6}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(6); }} className={openIndex === 6 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${6}`} aria-expanded={openIndex === 6} aria-controls={`collapse${6}`}>Sample Test Paper</a>
                            </div>
                                    <div id={`collapse${6}`} className={`collapse ${openIndex === 6 ? 'show' : ''}`} aria-labelledby={`heading${6}`} data-parent="#accordionExample">
                                   <div className="card-body">
                                        <button className='btn btn-primary btn-block' style={{backgroundColor:"rgb(62 64 149)"}}>Click here for the Sample Paper</button>
                                   </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}
export default QuestionAnswer1