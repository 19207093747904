import React, { useState } from 'react'

const QuestionAnswer = () => {
const [openIndex, setOpenIndex] = useState(null)
const toggleCollapse = (index) => {
setOpenIndex(openIndex === index ? null : index);
};
  return (
    <div className="content-block">
        <div className="section-full bg-white content-inner" style={{ backgroundImage: 'url(error-404.html)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <div className="row">   
                <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="accordion faq-box" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id={`heading${1}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(1); }} className={openIndex === 1 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${1}`} aria-expanded={openIndex === 1} aria-controls={`collapse${1}`}>What is the Eligibility Criteria?</a>
                            </div>
                                    <div id={`collapse${1}`} className={`collapse ${openIndex === 1 ? 'show' : ''}`} aria-labelledby={`heading${1}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      Candidates satisfying the following criteria are eligible to apply for the Five-Year Integrated Program in Management (IPM) of IIM Indore.
                                      <ul>
                                        <li>Minimum 60% in Std. X and Std. XII - General and NC-OBC Category</li>
                                        <li>Minimum 55% in Std. X and Std. XII - SC,ST and PWD (DA) Category                                        </li>
                                        <li>Maximum age limit for General and NC-OBC Category is 20 years,                                         </li>
                                        <li>Maximum age limit for SC,ST and PWD (DA) category is 25 years,                                         </li>
                                      </ul>
                                      <ol>
                                        <li>The percentage of marks obtained by the candidate in X and XII Standard would be calculated based on the aggregate marks of all subjects that appear in the mark sheet/grade sheet, irrespective of the Board’s regulation.                                        </li>
                                        <li>In case the candidates are awarded letter grades or grades points instead of marks, the conversion of grades to percentage of marks would be based on the procedure certified by the Board/ Competent Authority.                                        </li>
                                        <li>Applicants pursuing their X and/or XII standard from international schools affiliated to foreign Board’s like IGCSE and IB can also apply.                                        </li>
                                        <li>IIM Indore reserves the right to decide the eligibility conditions in terms of equivalence, percentage marks, and the recognition of the qualifying examination, intake etc.                                        </li>
                                      </ol>
                                      </div>
                                    </div>
                        </div>
                         <div className="card">
                            <div className="card-header" id={`heading${2}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(2); }} className={openIndex === 2 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${2}`} aria-expanded={openIndex === 2} aria-controls={`collapse${2}`}>What is the Selection Process?</a>
                            </div>
                                    <div id={`collapse${2}`} className={`collapse ${openIndex === 2 ? 'show' : ''}`} aria-labelledby={`heading${2}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      The candidates will be selected on the basis of overall performance in Aptitude Test (AT) and Personal Assessment (PA). Any candidate who fails to appear either in AT or PA or both shall not be considered for selection. The selection process is as follows: <br />
                                      <span><span style={{fontWeight:500}}>Stage 1:</span> The candidates satisfying the minimum eligibility criteria should submit their application online</span><br />
                                      <span><span style={{fontWeight:500}}>Stage 2:</span> Based on the applications received, candidates satisfying minimum eligibility criteria will be called for the Aptitude Test (AT) to be conducted across various cities in the country</span>
                                      <br />
                                      <span style={{fontWeight:500}}>The pattern of AT will be as follows:</span>
                                      <ol>
                                        <li>There will be three sections in the Aptitude Test namely Quantitative Ability (Multiple Choice Question-MCQ), Quantitative Ability (Short Answer Question-SA) and Verbal Ability (Multiple Choice Question-MCQ).                                        </li>
                                        <li>Each question will carry 4 marks.                                        </li>
                                        <li>There will be a negative marking of 1 mark for each wrong answer except Quantitative Ability (SA) Section (these questions will not carry any negative marking).
                                        </li>
                                        <li>The AT will be structured as under
                                        <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Section	</th>
                <th>Number of Questions	</th>
                <th>Weightage (%)</th>
                <th>Time duration for the section
                </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>QA (MCQ)	</td>
                    <td>30</td>
                    <td>25</td>
                    <td>40 Minutes</td>
                </tr>
                <tr>
                    <td>QA (SA)	</td>
                    <td>15	</td>
                    <td>25</td>
                    <td>40 Minutes</td>
                </tr>
                <tr>
                    <td>VA (MCQ)	</td>
                    <td>45	</td>
                    <td>50</td>
                    <td>40 Minutes</td>
                </tr>
                </tbody>
                </table>
                                        </div>
                                        </li>
                                        <li>Candidate must answer the sections in a pre-specified order and this order will remain same for all the candidates. The above table does not indicate the final order of sections.                                        </li>
                                      </ol>
                                      <span><span style={{fontWeight:500}}>Stage 3:</span> In this stage, the eligible pool for Stage-4 (i.e., Personal Assessment (PA)) is created considering only the candidates having positive raw scores and a minimum mark in each of the three sections of Aptitude Test. The minimum cut off mark in each section will be decided by the Admissions Committee. The minimum cut off mark in each section will be based on the categories of applicants. Individual regret letters will not be sent to applicants who are rejected at this stage.</span><br />
                                      <span><span style={{fontWeight:500}}>Stage 4:</span> Sufficient number of candidates for PA for each category (General, EWS, NC-OBC, SC, ST and PWD/DA) will be shortlisted from the eligible pool using the merit list generated based on Aptitude Test Score (ATS) as mentioned in the Table below:</span><br />
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Component</th>
                <th>Score</th>
                </tr>
                </thead>
                <tbody>
               <tr>
                <td>Score in Quantitative Ability (MCQ) Section	</td>
                <td>25 * (Candidate's Score − Minimum Score)/ (Maximum Score − Minimum Score)</td>
               </tr>
               <tr>
                <td>Score in Quantitative Ability (SA) Section	</td>
               <td>25 * (Candidate's Score − Minimum Score)/ (Maximum Score − Minimum Score)
               </td>
               </tr>
               <tr>
                <td>Score in Verbal Ability (MCQ) Section	</td>
               <td>50 * (Candidate's Score − Minimum Score)/ (Maximum Score − Minimum Score)
               </td>
               </tr>
               <tr>
               <td>Aptitude Test Score (ATS)	</td>
               <td>Sum of above scores
               </td>
               </tr>
                </tbody>
                </table>
                                      </div>
                                      <span><span style={{fontWeight:500}}>Note:</span> Maximum and Minimum scores are the highest and lowest scores obtained in respective sections by the candidates from the eligible pool.</span><br />
                                      <span><span style={{fontWeight:500}}>Final Selection:</span>  Category wise merit list will be generated based on Composite Score (CS) as mentioned in the Table below:</span><br />
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Component</th>
                <th>Weightage (%)</th>
                </tr>
                </thead>
                <tbody>
               <tr>
                <td>Aptitude Test Score (ATS)		</td>
                <td>65</td>
               </tr>
               <tr>
                <td>Personal Assessment (PA)	</td>
               <td>35 
               </td>
               </tr>
               <tr>
                <td>Composite Score (CS)	</td>
               <td>Sum of the above scores</td>
               </tr>
                </tbody>
                </table>
                                      </div>
                                      <span>No intimation will be sent to candidates who are not selected for final offer</span><br />
                                      <span style={{fontWeight:500}}>Reservations</span><br />
                                      <span>As per Government of India requirement,</span>
                                      <ol>
                                        <li>27% of the seats are reserved for Other Backward Classes candidates belonging to the “Non-Creamy” layer (NC-OBC),                                        </li>
                                        <li>Up to 10% for Economically Weaker Sections (EWSs),
                                        </li>
                                        <li>15% for Scheduled Caste (SC),
                                        </li>
                                        <li>7.5% for Scheduled Tribe (ST),
                                        </li>
                                        <li>5% for Persons with Benchmark Disabilities (PwD).
                                        </li>
                                      </ol>
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${3}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(3); }} className={openIndex === 3 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${3}`} aria-expanded={openIndex === 3} aria-controls={`collapse${3}`}>Important Dates</a>
                            </div>
                                    <div id={`collapse${3}`} className={`collapse ${openIndex === 3 ? 'show' : ''}`} aria-labelledby={`heading${3}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                        <span><span style={{fontWeight:500}}>Exam date:</span>23 May 2024</span>
                                        <br />
                                        <span><span style={{fontWeight:500}}>Application deadline: </span>26 March 2024</span>
                                      </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}
export default QuestionAnswer