import React, { Suspense, useContext, useEffect } from 'react'
import Header from '../Components/Header'
import ScrollToTop from '../Components/ScrollToTop';
import Popup from '../Components/Popup';
import BlogComp from '../Components/BlogComp';
import BlogContext from './BlogContext';
const Footer = React.lazy(() => import('../Components/Footer'));

const UserBlog = () => {
const {Fetch,load}=useContext(BlogContext)
useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        const link3 = document.createElement('link');
        const link4 = document.createElement('link');
        const link5 = document.createElement('link');
        const link6 = document.createElement('link');
        const link7 = document.createElement('link');
        const link8 = document.createElement('link');
        link.rel = 'stylesheet';
        link2.rel = 'stylesheet';
        link3.rel = 'stylesheet';
        link4.rel = 'stylesheet';
        link5.rel = 'stylesheet';
        link6.rel = 'stylesheet';
        link7.rel = 'stylesheet';
        link8.rel = 'stylesheet';
        link.href = "css/plugins.min.css"; // Path to your CSS file
        link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
        link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
        link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
        link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
        link6.href = "css/style.min.css"; // Path to your CSS file
        link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
        link8.href = "css/templete.min.css"; // Path to your CSS file
        link.id = 'home-css'; // Set an ID for easy removal
        link2.id = 'home-css'; // Set an ID for easy removal
        link3.id = 'home-css'; // Set an ID for easy removal
        link4.id = 'home-css'; // Set an ID for easy removal
        link5.id = 'home-css'; // Set an ID for easy removal
        link6.id = 'home-css'; // Set an ID for easy removal
        link7.id = 'home-css'; // Set an ID for easy removal
        link8.id = 'home-css'; // Set an ID for easy removal

        document.head.append(link);
        document.head.append(link2);
        document.head.append(link3);
        document.head.append(link4);
        document.head.append(link5);
        document.head.append(link6);
        document.head.append(link7);
        document.head.append(link8);

        return () => {
            document.getElementById('home-css')?.remove(); // Remove when unmounting
        };
}, []);
return (
    <div>
        <Header blog="active"/>
        <BlogComp data={Fetch} loader={load}/>
        <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
            <Footer/>
        </Suspense>
        <ScrollToTop />
        <Popup />
    </div>
)
}

export default UserBlog