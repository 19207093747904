import "./Addnewblog.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useRef, useState } from "react";
import Firebase, { database } from "../../../Firebase";
import { useNavigate } from "react-router-dom";

const Addnewblog = ({title}) => {
const[obj,setobj]=useState({})// object
const[loading,setloading]=useState(false) // loader
const[headingimage,setheadingimage]=useState(null);  // heading image
const[images,setimages]=useState([]);  // multiple images
const[imageserror,setimageserror]=useState(0); // not a image
const[inputs,setinputs]=useState([]) // multiple inputs data
const image=useRef()
const d=new Date()
const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
const navigate=useNavigate()
function Create(){
    if(inputs.length<7) return setinputs([...inputs,{id:inputs.length+1}])
    return alert("You cannot create more sub-headings")
}
function StringValues(event){
    const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    setobj({...obj,[event.target.name]:value,"Date":date});
}
function set(event){
    setobj({...obj,[event.target.name]:event.target.value,"Date":date})
}
function uploadheadingimage(event){
    const file=event.target.files[0]
    if(!file) return;
    const ext= file.type.split("/")
    if(ext[0]!=="image") return alert("Only Image is supported")
    if(ext[1]==="png" ||ext[1]==="PNG" ||ext[1]==="jpg" ||ext[1]==="jpeg") return setheadingimage(file)  
    return alert("Only png,jpeg and jpg images are supported")
}
function uploads(event){
    const file=event.target.files
    if(!file) return alert("No Image is selected")
    if(file.length>10) return alert("You cannot upload more than 10 images")
    
    let status=images
    let count=0
    for(let i=0;i<file.length;i++){
        if(status.length>=10){
            alert("You cannot upload more than 10 images")
            break;
        } 
       const ext =file[i].type.split("/")
       if(ext[0]!=="image"){
        count++
       }
       else{
        if(ext[1]==="png" || ext[1]==="PNG" || ext[1]==="jpg" || ext[1]==="jpeg"){
            status.push(file[i])
        }
        else{
        count++  
        }
       } 
    }
    setimages([...status])
    setimageserror(count)
}
function set1(Obj,event,index){
    inputs[index]={...Obj,[event.target.name]:event.target.value}
    setinputs([...inputs])
}
async function Submit(e){
  e.preventDefault()
  try {
    setloading(true)
    if(!obj.Author || !obj.Category || !obj.Description || !obj.Heading || !obj.Tags || !obj.Title || !obj.Status) return alert("Field is Empty")
    if(!headingimage) return alert("Upload heading image first")
  
    let count=0
    for(let i=0;i<inputs.length;i++){
      if(!inputs[i].Sub_Heading || !inputs[i].Sub_Heading_Description){
        count++
      }
    }
    if(count>0) return alert("Some Field are empty in Sub-Heading Part.")
    
    // saving heading image storage
    const fileRef= Firebase.child(headingimage.name)
    await fileRef.put(headingimage)
    const head_image_url= await fileRef.getDownloadURL()
    const head_image_path=fileRef.fullPath  
    let mydata={...obj,"Data":inputs,"Heading_Image":{path:head_image_path,url:head_image_url}}
  
    if(images.length>0){
      let myarray=[]
      for(let j=0;j<images.length;j++){
        const fileRefs= Firebase.child(images[j].name)
        await fileRefs.put(images[j])
        const url= await fileRefs.getDownloadURL()
        const path=fileRefs.fullPath 
        myarray.push({url,path}) 
      }
      mydata={...mydata,"Images":myarray}
    }
      database.child("Blogs").push(mydata,err=>{
        if(err) return alert("Error Occured in saving")
        else return alert("Blog Uploaded")
      })
  } catch (error) {
    return alert("Something Went Wrong. Try again later")
  } finally{
   setobj({})
   setheadingimage(null)
   setimages([])
   setinputs([])
   setloading(false)
   return setTimeout(() => navigate("/Admin"), 2500);
  }
}
function remove(index){
  images.splice(index,1)
  setimages(images=>[...images])
}
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        {loading && <div className="preloader">
          <div className="loader"></div>
        </div>}
        <div className="bottom">
          <div className="left">
            <img
              src={
                headingimage
                  ? URL.createObjectURL(headingimage)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
            <button disabled={images.length>=10?true:false} onClick={()=>image.current.click()}>Upload More Images</button>
            <input
                  type="file"
                  ref={image}
                  onChange={uploads}
                  style={{ display: "none" }}
                  multiple
                />
                <div className="uploadimages">
                {
                images && images.map((object,index)=>{
                    return(
                        <div key={index} className="myimages">
                          <img
                        src={
                          object
                            ? URL.createObjectURL(object)
                            : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                        }
                        alt=""
                      />
                      <i onClick={()=>remove(index)}>&times;</i>
                        </div>
                    )
                })
            }
            </div>
            {
              imageserror!==0 && <p style={{color:"red",fontWeight:500}}>{imageserror} files are not uploaded as they are not images</p>
            }
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file"> Heading Image: <DriveFolderUploadOutlinedIcon className="icon" /></label>
                <input type="file" id="file" onChange={uploadheadingimage} style={{ display: "none" }} />
              </div>
              <div className="formInput" >
                  <label>Author Name</label>
                  <input type="text" value={obj.Author?obj.Author:""} name="Author" onChange={StringValues} placeholder="Enter Author Name" />
                </div>
              <div className="formInput" >
                  <label>Title</label>
                  <input type="text" value={obj.Title?obj.Title:""} name="Title" onChange={StringValues} placeholder="Enter the Title" />
                </div>
                <div className="formInput" >
                  <label>Heading</label>
                  <input type="text" value={obj.Heading?obj.Heading:""} name="Heading" onChange={StringValues} placeholder="Enter the Heading" />
                </div>
                <div className="formInput" >
                  <label>Description</label>
                  <textarea name="Description" onChange={set} type="text" value={obj.Description?obj.Description:""} placeholder="Enter the Description" />
                </div>
                <div className="formInput" >
                  <label>Tags (separated by commas)</label>
                  <input type="text" name="Tags" value={obj.Tags?obj.Tags:""} onChange={set} placeholder="Enter the Tags" />
                  <label style={{marginTop:"20px"}}>Category</label>
                  <input name="Category" value={obj.Category?obj.Category:""} onChange={StringValues} type="text" placeholder="Enter the Blog Category" />
                </div>
                <div className="formStatus">
                  <span>Status:</span>
                  <input type="radio" className="radios" onChange={set} checked={obj.Status==="Active"?true:false} name="Status" value="Active"/>Active
                  <input type="radio" className="radios" onChange={set} checked={obj.Status==="In-Active"?true:false} name="Status" value="In-Active"/>In Active
                </div>
                <div style={{width:"40%",textAlign:"center",position:"relative",bottom:"16px"}}><button type="button" disabled={inputs.length>=7?true:false} onClick={Create}>Create Sub-Heading</button></div>
                {inputs.map((Obj,index) => (
                    <div className="formInputsss" key={index}>
                    <div className="runtimeinputs">
                    <label>Sub-Heading</label>
                    <input type="text" name='Sub_Heading' onChange={(e)=>set1(Obj,e,index)} placeholder="Enter the Sub-Heading" />
                    </div>
                    <div className="runtimeinputs">
                    <label>Sub-Heading-Description</label>
                    <input type="text" name='Sub_Heading_Description' onChange={(e)=>set1(Obj,e,index)} placeholder="Enter the Sub-Heading-Description" />
                    </div>
                    </div>
                ))}
                <button onClick={Submit}>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addnewblog;