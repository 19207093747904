import React, { useState } from 'react'

const QuestionAnswer2 = () => {
const [openIndex, setOpenIndex] = useState(null)
const toggleCollapse = (index) => {
setOpenIndex(openIndex === index ? null : index);
};
  return (
    <div className="content-block">
        <div className="section-full bg-white content-inner" style={{ backgroundImage: 'url(error-404.html)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <div className="row">   
                <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="accordion faq-box" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id={`heading${1}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(1); }} className={openIndex === 1 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${1}`} aria-expanded={openIndex === 1} aria-controls={`collapse${1}`}>Intake</a>
                            </div>
                                    <div id={`collapse${1}`} className={`collapse ${openIndex === 1 ? 'show' : ''}`} aria-labelledby={`heading${1}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <span>The intake for IPM 2023-28 batch would be 120 (Reservation applicable as per GoI regulations after meeting the minimum eligibility criteria.)</span>
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${2}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(2); }} className={openIndex === 2 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${2}`} aria-expanded={openIndex === 2} aria-controls={`collapse${2}`}>Eligibility Criteria</a>
                            </div>
                                    <div id={`collapse${2}`} className={`collapse ${openIndex === 2 ? 'show' : ''}`} aria-labelledby={`heading${2}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                       <span>Candidates should have passed the 10th standard/Secondary/SSC Examination and the 12th standard/Higher Secondary/HSC Examination (or equivalent) with 60% (55% for candidates from SC/ST/PwD) or more marks. The percentage of marks obtained by the candidate in the 10th and the 12th standard would be calculated based on the Board's regulations. If there is no such regulation regarding aggregate marks from the concerned Board, then the aggregate marks of all the subjects that appear in the grade sheet will be considered for calculating the passing percentage.</span>
                                       <br />
                                       <span style={{fontWeight:500}}>There will not be any age limit for the applicants.
                                       </span>
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${3}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(3); }} className={openIndex === 3 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${3}`} aria-expanded={openIndex === 3} aria-controls={`collapse${3}`}>Admission Process</a>
                            </div>
                                    <div id={`collapse${3}`} className={`collapse ${openIndex === 3 ? 'show' : ''}`} aria-labelledby={`heading${3}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                      <span>Admission to Integrated Programme in management of IIM Ranchi will be based on performance of candidates in the Aptitude Test (IPMAT, conducted by IIM Indore), Personal Interview (PI) and their Academic profile. The PI process will be conducted by IIM Ranchi.</span><br />
                                      <span>All candidates applying for the IPM program of IIM Ranchi would also have to fill an online application form on the IIM Ranchi website, which will be used for further evaluation.</span> <br />
                                      <span>The shortlisting criteria, weightage for different components and the profile scoring method are given below.</span>
                                      <ol>
                                        <li><span style={{fontWeight:500}}>Initial shortlisting for PI Process </span><br />An initial shortlist (from the list of applicants) shall be prepared comprising of candidates who will be eligible for the PI process for admission to the IPM 2024-29 batch of IIM Ranchi. This shortlist will be purely based on performance of the applicants in IPMAT 2024.</li>
                                        <li><span style={{fontWeight:500}}>PI Process </span><br />The PI process will be conducted in physical mode at New Delhi, Hyderabad, Mumbai,Bangalore & Ranchi.</li>
                                        <li><span style={{fontWeight:500}}>Final Admissions 2024-29</span><br />A Consolidated Merit List (CML) would be compiled on the basis of Admission Test Score,PI Score, and Academic Profile Score based on the overall academic performance at thesecondary and the higher secondary levels, as mentioned below:<br />
                                        <span>After the PI, a Consolidated Merit List (CML) would be compiled on the basis of the following components and their corresponding weights:</span></li>
                                      </ol>         
                                      <div className='table-responsive'>
                <table className='table table-hover table-bordered  custom-colortable' >
                <thead style={{ backgroundColor: "#3e4095" }} >
                <tr className='text-center'>
                <th>Components</th>
                <th>Weightage (%)</th>
                </tr>
                </thead>
               <tbody>
                <tr>
                    <td>Admission Test Score</td>
                    <td>50</td>
                </tr>
                <tr>
                <td>PI Score</td>
                <td>30</td>
                </tr>
                <tr>
                <td>Academic Profile</td>
                <td>20</td>
                </tr>
                <tr>
                <td>Gender Diversity (Bonus Points) *</td>
                <td>5</td>
                </tr>
                <tr>
                <td>Total</td>
                <td>100</td>
                </tr>
               </tbody>
                </table>
                                      </div>                                     
                                      </div>
                                    </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id={`heading${4}`}>
                                <a onClick={(e) => { e.preventDefault(); toggleCollapse(4); }} className={openIndex === 4 ? "" : "collapsed"} href="#" data-toggle="collapse" data-target={`#collapse${4}`} aria-expanded={openIndex === 4} aria-controls={`collapse${4}`}>About SAT</a>
                            </div>
                                    <div id={`collapse${4}`} className={`collapse ${openIndex === 4 ? 'show' : ''}`} aria-labelledby={`heading${4}`} data-parent="#accordionExample">
                                      <div className="card-body">
                                        <span>Applicants are required to take the SAT. For more details about the SAT, please refer to <a href="https://collegereadiness.collegeboard.org/sat?navId=gf-sat">https://collegereadiness.collegeboard.org/sat?navId=gf-sat.</a></span><br />
                                        <span>SAT is conducted five times in a calendar year. Traditionally, it is held in March, May, August, October, and December. For test dates and deadlines or to register for an upcoming SAT exam, please visit <a href="https://collegereadiness.collegeboard.org/sat/register/international">https://collegereadiness.collegeboard.org/sat/register/international.</a></span>
                                        <br />
                                        <span>SAT is held in over 70 cities and 73 test centres in India. Students can appear for the SAT multiple times. You may look for your nearest test location at: <a href="https://collegereadiness.collegeboard.org/sat/register/find-test-centers">https://collegereadiness.collegeboard.org/sat/register/find-test-centers</a>. You willneed to choose India in the "Select a Country or Region" option.</span><br />
                                        <span>All applicants will need to send us their official SAT scores through their College Board account. While you take the SAT, you will have the option of directly sending the scores to IIM Ranchi for free. You will have to give the SAT code. The SAT code for the Indian Institute of Management Ranchi is 9109.</span><br />
                                        <span>Please note that the institute will accept only the SAT scores. The SAT Essay is not required. The recommended dates to write the exam are March 13 and May 8.</span><br />
                                        <span>Please note that while there is a SAT scheduled on June 5, it is not available for International locations, including India.</span>
                                      </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}
export default QuestionAnswer2