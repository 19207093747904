import React from 'react'
import QuestionAnswer1 from './QuestionAnswer1'

const IIMRohtak = () => {
  return (
    <div>
        <div className="class-info">
            <div className="dlab-post-text">
            <span>IIM Rohtak has introduced a Five-Year Integrated Programme in Management (IPM) from the year 2019 for students after class XII who aim to pursue their career in Business Management. On successful completion of the five year programme, the student will be awarded Master of Business Administration (MBA) by IIM Rohtak. If any student intends to exit after successfully completing the programme requirement at the end of 3 years, he/she will be awarded with a degree of Bachelor of Business Administration (BBA).</span>
            </div>
        </div>
        <QuestionAnswer1/>
    </div>
  )
}

export default IIMRohtak
