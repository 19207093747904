import React from 'react'
import QuestionAnswer3 from './QuestionAnswer3'

const IIMJammu = () => {
  return (
    <div>
        <div className="class-info">
            <div className="dlab-post-text">
            <span>The Five Year Full-time Residential Integrated Program is strategically designed with a blend of science, social science, and management courses. The program's ultimate aim is to integrate management education with Science, Technology, Engineering, and Mathematics (STEM). The Integrated Programme in Management (IPM) of IIM Jammu is a full-time 5-year blended course of business studies from undergraduate to postgraduate.</span>
            <br /><br />
            <span>The structure and lengths of degree programs have multiple exit options within the first three years. If any student intends to exit after successful completion of the program requirement at the end of the 1st year; a certificate will be awarded; at the end of 2nd year, an advanced certificate will be awarded; and at the end of the three years, she/he will be awarded a degree of Bachelor of Business Administration (BBA). Upon completing the academic rigor of 5 years, the student will be awarded a Master of Business Administration (MBA) of IIM Jammu.</span>
            <div className="row align-items-center about-bx2">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <img src="iimjammu.jpg" className="img" />
                </div>
            </div>
            <span>A minimum CGPA of 6.00 would be required to continue into the MBA program. The fourth and fifth years will be merged with the flagship MBA program of the Institute and students will be required to meet the academic rigor of the MBA program.</span>
            </div>
        </div>
        <QuestionAnswer3/>
    </div>
  )
}

export default IIMJammu
