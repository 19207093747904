import React from 'react'
import { Link } from 'react-router-dom'
const Teachers = () => {
    return (
      
        <>
            <div className="section-full content-inner bg-white" style={{ backgroundImage: 'url(images/background/bg1.jpg)' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="cussection-head text-center">
                                <h2 className="title" style={{ fontWeight: 600 }}>Core Faculty</h2>
                                <div className="dlab-separator bg-primary" />
                            </div>
                        </div>
                        <div style={{marginBottom:"30px"}} className="container">
        <div className="row">
                        <div  className="col-lg-6 col-md-12 col-sm-12 col-12  ">
                                   <div className='Teacherborderddiv' >
                                    <div className="customaction-box2">
                                        <img className='teach' src="teacher1.jpg" alt="" />
                                    </div>
                                    <div  className="customaction-upper teacher-content">
                                    <h2 className="title">Mr. Vineet Singh</h2>
                                        <ul className="customul">
                                            <li> Ph.d Pub Ad (P)</li>
                                            <li>M.A. Pub Ad   </li>
                                            <li>M.A. Political science</li>
                                            <li>M.A Economics </li>
                                            <li>7 times NET JRF</li>
                                            <li>UPSC Interviewee </li>
                                            <li>HCS Interviewee </li>
                                            <li>Ras Interviewee </li>
                                            <li>HPSC Ass. Professor Interviewee </li>
                                            <li>Air force Veteran</li>
                                        </ul>
                                    </div>
                                    
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
                                <div  className='Teacherborderddiv custeacher2' >
                                    <div className="customaction-box2">
                                    <img className='cuteach' src="teacher2.jpg" alt="" />
                                    </div>
                                    <div  className="customaction-upper teacher-content">
                                    <h2 className="title"> Mr. Puneet Narwal</h2>
                                        {/* <span className="teacher-coures">MBA - 3 Year Experience</span> */}
                                        <ul className="customul">

                                            <li>M.A. Sociology</li>
                                            <li>M.A. Pub Ad(E)</li>
                                            <li>M.A Psychology(E)</li>
                                            <li>M.A. Geography(E)</li>
                                            <li>UPSC Interviewee</li>
                                            <li>HCS Interviewee</li>
                                            <li>Ex CL faculty </li>
                                            <li>Ex T.I.M.E. faculty </li>
                                            <li>14 years teaching experience</li>
                                        </ul>
                                    </div>
                                    
                                    
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Teachers