import React, { Suspense, useEffect } from 'react'
import Header from '../Components/Header';
import Ventures from '../Components/Ventures';
import MissionVission from '../Components/MissionVission';
import CurrentAffairs from '../Components/CurrentAffairs';
import Teachers from '../Components/Teachers';
import HomeTestimonials from '../Components/HomeTestimonials';
import Contactus from '../Components/Contactus';
import CarousalSlider from '../Components/CarousalSlider';
import ScrollToTop from '../Components/ScrollToTop';
import SearchBar from './SearchBar';
import Popup from '../Components/Popup';
const Footer = React.lazy(() => import('../Components/Footer'));
const Home = () => {
useEffect(()=>{
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        const link3 = document.createElement('link');
        const link4 = document.createElement('link');
        const link5 = document.createElement('link');
        const link6 = document.createElement('link');
        const link7 = document.createElement('link');
        const link8 = document.createElement('link');
        link.rel = 'stylesheet';
        link2.rel = 'stylesheet';
        link3.rel = 'stylesheet';
        link4.rel = 'stylesheet';
        link5.rel = 'stylesheet';
        link6.rel = 'stylesheet';
        link7.rel = 'stylesheet';
        link8.rel = 'stylesheet';
        link.href = "css/plugins.min.css"; // Path to your CSS file
        link2.href = "plugins/fontawesome/css/font-awesome.min.css"; // Path to your CSS file
        link3.href = "plugins/line-awesome/css/line-awesome.min.css"; // Path to your CSS file
        link4.href = "plugins/flaticon/flaticon.css"; // Path to your CSS file
        link5.href = "plugins/themify/themify-icons.css"; // Path to your CSS file
        link6.href = "css/style.min.css"; // Path to your CSS file
        link7.href = "css/skin/skin-1.min.css"; // Path to your CSS file
        link8.href = "css/templete.min.css"; // Path to your CSS file
        link.id = 'home-css'; // Set an ID for easy removal
        link2.id = 'home-css'; // Set an ID for easy removal
        link3.id = 'home-css'; // Set an ID for easy removal
        link4.id = 'home-css'; // Set an ID for easy removal
        link5.id = 'home-css'; // Set an ID for easy removal
        link6.id = 'home-css'; // Set an ID for easy removal
        link7.id = 'home-css'; // Set an ID for easy removal
        link8.id = 'home-css'; // Set an ID for easy removal

        document.head.append(link);
        document.head.append(link2);
        document.head.append(link3);
        document.head.append(link4);
        document.head.append(link5);
        document.head.append(link6);
        document.head.append(link7);
        document.head.append(link8);

        return () => {
            document.getElementById('home-css')?.remove(); // Remove when unmounting
        };
},[])
useEffect(()=>{
        localStorage.clear()
},[])
return(
    <div>
        <div className="page-wraper">
            <Header home="active"/>
            <div className="text-center custommarque">
                <ul>
                <li><a href="tel:+91 70272 22123"><i className='icons' ><i style={{color:"green"}} className="fa fa-phone"/></i><span>+91 70272 22123</span> </a></li>
                <li><a href="https://www.youtube.com/channel/UC2z3jBDqL3z2QKXGaZvjNug"><i className='icons' > <i style={{color:"red" }} className="fa fa-youtube"/></i><span> Genius IAS</span></a></li>
                <li><a href="https://www.instagram.com/geniusias/"> <i className='icons' ><i  className="fa fa-instagram insta"/></i><span>Genius IAS</span> </a></li>
                <li><a href="tel:+91 70272 22124"><i className='icons' > <i style={{color:"green" }} className="fa fa-phone"/></i><span>+91 70272 22124</span> </a></li>
                <li><a href="https://www.youtube.com/@Schoolofhumanitiesofficial"> <i className='icons' ><i style={{color:"red" }} className="fa fa-youtube"/></i> <span>School of Humanities</span> </a></li>
                <li><a href="https://www.instagram.com/school_of_humanities_official?igsh=ZGxxbzVtc3B2MXZv"><i className='icons' ><i className="fa fa-instagram insta"/></i><span>School of Humanities</span></a></li>
                </ul>
            </div>
            <div className="page-content bg-white">
            <CarousalSlider/>
            <Ventures/>
            <SearchBar/>
            <MissionVission/>
            <CurrentAffairs/>
            <Teachers/>
            <HomeTestimonials/>
            <Contactus/>
            </div>
        </div>    
        <Suspense fallback={<div className='preloader'><div className='loader'></div></div>}>
            <Footer/>
        </Suspense>
        <ScrollToTop/>
        <Popup/>
    </div>
)
}
export default Home