import React from 'react'

const MissionVission = () => {
  return (
    <div>
        <div  className="section-full bg-white content-inner-2" style={{ backgroundImage: 'url(images/pattern/pt1.png)',paddingTop:"13px" }}>
                        <div className="container">
                
                            <div className="row align-items-center about-bx2">
                                <div  className="col-lg-6 col-md-12 col-sm-12 col-12 ">
                                  
                                    <img src="aboutusimage.jpg" className="img" />
                                </div>
                                <div  className="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="about-box">
                                        <h3 className="title text-center" style={{ fontWeight: 600 }}> Mission &amp; Vision </h3>
                                        <p style={{textAlign:'justify'}} className="ext"><span style={{fontWeight:600}}>Genius IAS</span> is a Rohtak-based coaching center. We offer coaching for UPSC IAS & HCS prelims, mains, and interview. We also offer UPSC IAS foundation coaching for school and college students. We offer both online and offline coaching options to suit different learning preferences.</p>
                                        <ul className="list-check">
                                            <li> Best UPSC foundation coaching in Rohtak.</li>
                                            <li>Best UPSC Coaching in Rohtak.</li>
                                            <li>Best HCS coaching in Rohtak.</li>
                                        </ul>
                                        <p style={{textAlign:'justify'}} className="ext"> At,<span style={{fontWeight:600}}>School of Humanities</span> we offer a school integrated program for class 11th & 12th students, where we prepare class 11th & 12th students for various competitive examinations like CLAT, IPM, CUET, TISS, SAT, SET and the UPSC Foundation along with CBSE curriculum of class 11th & 12th.</p>
                                        <ul className="list-check">
                                            <li> Best CLAT coaching in Rohtak.</li>
                                            <li>Best CUET Coaching in Rohtak.</li>
                                            <li>Best IPM coaching in Rohtak.</li>
                                        </ul>
                                         
                                        
                                        {/* <div class="about-mamber">
                      <img src="images/testimonials/pic2.jpg" class="user" alt=""/>
                      <img src="images/sign.png" class="sign" alt=""/>
                  </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    </div>
  )
}

export default MissionVission