import React from 'react'
import { Link } from 'react-router-dom'

const BlogdetailsComp = (props) => {
  return (
    <div>
   <div className="page-content">
  {/* inner page banner */}
  <div className="dlab-bnr-inr overlay-black-dark" style={{backgroundImage: 'url(images/bg10.jpg)'}}>
    <div className="container">
      <div className="dlab-bnr-inr-entry">
        <h1 className="text-white">Latest Blogs</h1>
        {/* Breadcrumb row */}
        <div className="breadcrumb-row">
          <ul className="list-inline">
            <li><Link to="/"><i className="fa fa-home" /></Link></li>
            <li>Home</li>
            <li>Latest Blogs</li>
          </ul>
        </div>
        {/* Breadcrumb row END */}
      </div>
    </div>
  </div>
  {/* inner page banner END */}
  {/* inner page banner END */}
  <div className="content-block">
    <div className="section-full bg-gray content-inner">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="classes-details">
            <div className="class-info">
                <div className="dlab-post-title ">
                  <h1 className="post-title m-t0"><a>{props?.data?.Title && props?.data?.Title}</a></h1>
                </div>
              </div>
              <div className="class-media m-b20">
                <img src={props?.data?.Heading_Image?.url && props?.data?.Heading_Image?.url} loading='lazy'/>
              </div>
              <div className="class-info">
                <div className="dlab-post-title ">
                  <h2 className="post-title m-t0"><a href="blog-classic-grid.html">{props?.data?.Heading && props?.data?.Heading}</a></h2>
                </div>
                <div className="dlab-post-meta m-b20">
                  <ul className="d-flex align-items-center">
                    <li className="post-date"> <i className="la la-calendar-o" /><strong>{props?.data?.Date && props?.data?.Date}</strong> </li>
                    <li className="post-author"><i className="la la-user" />By <a>{props?.data?.Author && props?.data?.Author}</a> </li>
                    <li className="post-comment"><i className="la la-comment" /> <a>{props?.data?.Category && props?.data?.Category}</a> </li>
                  </ul>
                </div>
                <div className="dlab-post-text">
                  <p>{props?.data?.Description && props?.data?.Description}</p>
                  <div className="row">
                    {
                      props?.data?.Images?.map(function(obj,index){
                        return(
                        <div key={index} className="card-container col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                          <div className="blog-post blog-grid style-2">
                            <div className="dlab-post-media">
                              <a><img style={{height:"250px",backgroundSize:"contain"}} src={obj?.url && obj?.url} loading='lazy' /></a>
                            </div>
                          </div>
                        </div>
                        )
                      })
                    }
                  </div>
                  {
                    props?.data?.Data?.map(function(obj,index){
                      return(
                        <div key={index}>
                          <h4>{obj?.Sub_Heading && obj?.Sub_Heading}</h4>
                          <p style={{marginBottom:"15px"}}>{obj?.Sub_Heading_Description && obj?.Sub_Heading_Description}</p>	
                        </div>
                      )
                    })
                  }
                </div>
                <div className="dlab-post-tags clear">
                  <div className="post-tags"> 
                  {props?.data?.Tags && props?.data?.Tags?.split(",").map(function(tag,index){
                    if(index%4===0){
                      return(<a key={index}>#{tag} </a>)
                    }else if(index%4===1){
                      return(<a key={index}>#{tag} </a>)
                    }else if(index%4===2){
                      return(<a key={index}>#{tag} </a>)
                    }else{
                      return(<a key={index}>#{tag} </a>)
                    }
                  })}  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default BlogdetailsComp