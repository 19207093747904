import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const CarousalSlider = () => {
    const sliderRef = useRef(null); // Create a ref to hold the slider instance
    const nextSlide = () => {
        sliderRef.current.slickNext(); // Call the slickNext method on the slider
      };
    
      const prevSlide = () => {
        sliderRef.current.slickPrev(); // Call the slickPrev method on the slider
      };
    const settings = {
        dots: false,        // Hide dots
        infinite: true,     // Loop through slides
        speed: 500,         // Transition speed
        slidesToShow: 1,    // Number of slides to show at a time
        slidesToScroll: 1,  // Number of slides to scroll at a time
        arrows: false, 
      };
      return (
        <div style={{overflow:"hidden",position:"relative"} } className="owl-slider   owl-btn-center-lr " >
          <Slider   ref={sliderRef} {...settings}>
          <div className="item slide-item">
              <div className="slide-item-img">
                <img src="newsliderimages/website-slider-new-soh.jpg" alt="Slide 5" />
              </div>
            </div>
            <div className="item slide-item">
              <div className="slide-item-img">
                <img src="newsliderimages/website-main-slider-new-1.jpg" alt="Slide 1" />
              </div>
            </div>
    
            <div className="item slide-item">
              <div className="slide-item-img">
                <img src="newsliderimages/website-slider-clat.jpg" alt="Slide 2" />
              </div>
            </div>
    
            <div className="item slide-item">
              <div className="slide-item-img">
                <img src="newsliderimages/website-slider-new-2.jpg" alt="Slide 3" />
              </div>
            </div>
    
            <div className="item slide-item">
              <div className="slide-item-img">
                <img src="newsliderimages/website-slider-new-3.jpg" alt="Slide 4" />
              </div>
            </div>
           
          </Slider>
          <div className="owl-nav">
				<div onClick={prevSlide} className="owl-prev"><i className="la la-arrow-left"/></div>
				<div onClick={nextSlide} className="owl-next"><i className="la la-arrow-right" /></div>
			</div>
        </div>
      )
}

export default CarousalSlider