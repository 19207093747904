import React, { useContext, useEffect, useState } from 'react'
import BlogContext from '../Pages/BlogContext'
import { Link, useNavigate } from 'react-router-dom'
import { database } from '../Firebase'

const CurrentAffairs = () => {
const{Fetch}=useContext(BlogContext)
const navigate=useNavigate()
const[data,setdata]=useState(null)
const[data1,setdata1]=useState(null)
const[data2,setdata2]=useState(null)
useEffect(function(){
database.child("Details/Telegram").on("value",function(snap){
    if(snap.val()) return setdata(snap.val())
    else return setdata(null)
})
database.child("CurrentAffairs").on("value",function(snap){
    if(snap.val()) return setdata1(snap.val())
    else return setdata1(null)
})
database.child("Notifications").on("value",function(snap){
    if(snap.val()) return setdata2(snap.val())
    else return setdata2(null)
})
},[])
function GetMonth(month){
    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    return monthNames[month-1].slice(0,3)
}
function Send(key){
    localStorage.setItem("Current-Blog",JSON.stringify(key))
    navigate(`/BlogDetails`)
}
  return (
    <div>
        <div className="section-full content-inner-2 bg-gray">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12 m-b80">
                                    <div className="action-box  ">
                                        <div className="head">
                                            <h4 className="title">Notification</h4>
                                        </div>
                                        <div className="action-area marquee1">
                                            <div>
                                            <ul className='marq'>
                                                {
                                                    data2 && Object.keys(data2).map((key,index)=>{
                                                        return(
                                                        <li key={index}><a href={"//"+data2[key]?.Url}>{data2[key]?.Title && data2[key]?.Title}</a></li>
                                                        )
                                                    })  
                                                }
                                                {/* <li><a href="courses-details.html">RESULT OF SSC CGL 2020 (TIER-3)</a></li>
                                                <li><a href="courses-details.html">SSC CGL 2020 PRE Application Status</a></li>
                                                <li><a href="courses-details.html">SSC CHSL 2020 Result Declared</a></li>
                                                <li><a href="courses-details.html">RESULT OF SSC CGL 2020 (TIER-3)</a></li>
                                                <li><a href="courses-details.html">SSC CGL 2020 PRE Application Status</a></li>
                                                <li><a href="courses-details.html">SSC CHSL 2020 Result Declared</a></li>
                                                <li><a href="courses-details.html">RESULT OF SSC CGL 2020 (TIER-3)</a></li>
                                                <li><a href="courses-details.html">SSC CGL 2020 PRE Application Status</a></li>
                                                <li><a href="courses-details.html">SSC CHSL 2020 Result Declared</a></li> */}
                                            </ul>
                                            </div>
                                            {
                                                data?.Url && <div style={{position:"absolute",bottom:"35px",left:"40%",zIndex:"999"}} className='text-center'>
                                                <a target='_blank' href={data?.Url} style={{color:"white"}} className="btn btn-sm">View All</a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12 m-b80 ">
                                    <div className="action-box">
                                        <div className="head" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h4 className="title">Current Affairs</h4>
                                            {
                                                data?.Url && <h5><a target='_blank' href={data?.Url} className="title" style={{ fontSize: 15, marginTop: 20 }}> <i className="fa fa-telegram" /> Join Now</a></h5>
                                            }
                                        </div>
                                        <div className="action-area">
                                            <ul className="event-bx">
                                                {
                                                    data1 && Object.keys(data1).map((key,index)=>{
                                                        return(
                                                        <li key={index}><a href={"//"+data1[key]?.Url} target='_blank'>{data1[key]?.Title && data1[key]?.Title}</a></li>
                                                        )
                                                    })  
                                                }
                                                {/* <li><a href="event-details.html">New SSC-CGL/10+2 batch starts from "24th of
                                                    June at 7:00 AM" at Tonk Road branch,Jaipur.</a></li>
                                                <li><a href="event-details.html">New SSC-CGL MAINS batch starts from "26th of
                                                    June at 10:00 AM" at Gurjar ki thadi branch,Jaipur.</a></li>
                                                <li><a href="event-details.html">New SSC-CGL/10+2 and Bank-PO/Clerk batch starts
                                                    from "3rd of July at 7:00 AM" at Tonk Road Branch,Jaipur.</a></li> */}
                                            </ul>
                                            {
                                                data?.Url && <div className='text-center'>
                                                <a target='_blank' href={data?.Url} style={{color:"white"}} className="btn btn-sm">View All</a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12 m-b80">
                                    <div className="action-box">
                                        <div className="head">
                                            <h4 className="title">Blogs</h4>
                                        </div>
                                        <div className="action-area">
                                            <ul className="blog-artical">
                                                {
                                                    Fetch && Object.keys(Fetch).map(function(key,index){
                                                        return(
                                                            <li style={{cursor:"pointer"}} key={index}>
                                                    <a onClick={()=>Send(key)}>
                                                        <div className="date">
                                                            <span>{Fetch[key]?.Date?.split("/")[0]}</span>
                                                            {Fetch[key]?.Date && <strong>{GetMonth(Fetch[key]?.Date?.split("/")[1])}</strong>}
                                                        </div>
                                                        <h5 className="title">{Fetch[key]?.Title && Fetch[key]?.Title}</h5>
                                                    </a>
                                                </li>
                                                        )
                                                    })
                                                    
                                                }
                                                {/* <li>
                                                    <a href="blog-details.html">
                                                        <div className="date">
                                                            <span>10</span>
                                                            <strong>Jan</strong>
                                                        </div>
                                                        <h5 className="title">Preparation Strategy for SSC-CGL</h5>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="blog-details.html">
                                                        <div className="date">
                                                            <span>21</span>
                                                            <strong>AUG</strong>
                                                        </div>
                                                        <h5 className="title">New Pattern of SSC-CGL brings more transparency.</h5>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="blog-details.html">
                                                        <div className="date">
                                                            <span>06</span>
                                                            <strong>Mar</strong>
                                                        </div>
                                                        <h5 className="title">How to manage time for SSC examination</h5>
                                                    </a>
                                                </li> */}
                                            </ul>
                                            <div className="text-center">
                                            <Link to="/Blog" style={{color:"white"}} className="btn btn-sm">View All</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
    </div>
  )
}

export default CurrentAffairs