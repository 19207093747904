import "./Notification.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import { database } from "../../../Firebase";
import Notification from "../../components/datatable/Notification";

const Notifications = ({title}) => {
const [data,setdata] = useState({})
function set(event){
  setdata({...data,[event.target.name]:event.target.value})
}
function save(e){
  e.preventDefault()
  if(!data.Url || !data.Title) return alert("Field is Empty")
  database.child("Notifications").push(data,err=>{
    if(err) return alert("Something went wrong. Try again later")
    else return alert("Saved Successfully")
  })
  setdata({})
}
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form>
              <div className="formInput">
                <label>Notification Title:</label>
                <input type="text" name="Title" onChange={set} value={data.Title?data.Title:""} placeholder="Enter the Notification title" />
              </div>
              <div className="formInput">
                <label>Url:</label>
                <input type="url" name="Url" onChange={set} value={data.Url?data.Url:""} placeholder="Enter the url" />
              </div>
              <button onClick={save}>Submit</button>
            </form>
          </div>
        </div>
        <div className="list">
          <div className="listContainer">
           <Notification/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;