import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useEffect, useState } from "react";
import { database } from "../../../Firebase";

const Featured = ({contacts}) => {
const[contact,setcontact]=useState(0)
const[totalcontact,settotalcontact]=useState(0)
const[count,setcount]=useState(0)
const[totalcount,settotalcount]=useState(0)
const d=new Date()
const year=d.getFullYear()
useEffect(()=>{
  if(contacts){
    settotalcontact(Object.keys(contacts).length)
    const result=Object.keys(contacts).filter(key=>contacts[key].Date.split("/")[2]==year)
    setcontact(result.length);
  }
},[contacts])
useEffect(()=>{
  database.child("Counselling").on("value",function(snap){
    if(snap.val()){
      settotalcount(Object.keys(snap.val()).length)
      const result= Object.keys(snap.val()).filter((key)=>snap.val()[key].Date.split("/")[2]==year)
      setcount(result.length);
    }
    else return setcount(0)
  })
},[])
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Queries</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={parseInt(contact/totalcontact*100)} text={parseInt(contact/totalcontact*100)+"%"} strokeWidth={5} />
        </div>
        <p className="title">Total Contacts this year</p>
        <p className="amount">{contact+"/"+totalcontact}</p>
        <p className="desc">
          Total Counselling/Exam Queries this year
        </p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Total</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">{totalcount}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">This Year</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">{count}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Percentage</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">{parseInt(count/totalcount*100)+"%"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;